import React, { FC, useEffect, Dispatch, SetStateAction } from 'react';
import { ApolloClient, NormalizedCacheObject, useApolloClient, useMutation } from '@apollo/client';
import { createSetupIntent, saveResponseAndProcessPayment } from 'api/data/payment';
import * as ResponseQuery from 'graphql/response.graphql';
import { mapToResponseInput } from 'utils';
import { applePayAvailable, mountButton } from '../ApplePay';
import { Response, ResponseData } from 'api/data/response/types';
import { PaymentMethodType, PaymentErrorType } from 'api/data/payment/types';

interface ApplePayProps {
  setErrorMessage: (message: string) => void;
  redirectAfterPayment: (succeeded: boolean, responseId: string) => void;
  paymentProcessing: Dispatch<SetStateAction<boolean>>;
  setWalletType: Dispatch<SetStateAction<string>>;
  response: Response;
  sessionKey?: string;
}

const APPLE_PAY_ELEMENT_ID = 'apple-pay';

const ApplePay: FC<ApplePayProps> = ({
  paymentProcessing,
  redirectAfterPayment,
  setErrorMessage,
  setWalletType,
  response,
  sessionKey,
}) => {
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const [saveResponse] = useMutation<ResponseData>(ResponseQuery.SaveResponse);

  useEffect(() => {
    const performPayment = async (details: ApplePayJS.ApplePayPayment, callback: (succeeded: boolean) => void) => {
      const newResponse = {
        ...response,
        user: {
          email: details.shippingContact?.emailAddress || '',
          zipcode: details.shippingContact?.postalCode || '',
          fullName: `${details.shippingContact?.givenName || ''} ${details.shippingContact?.familyName || ''}`,
        },
      };

      try {
        const stringifiedPaymentDetails = JSON.stringify({ token: details.token });

        await saveResponse({
          variables: {
            response: {
              ...mapToResponseInput(response),
              user: {
                email: details.shippingContact?.emailAddress || '',
                zipcode: details.shippingContact?.postalCode || '',
                fullName: `${details.shippingContact?.givenName || ''} ${details.shippingContact?.familyName || ''}`,
              },
            },
          },
        });

        const { paymentMethodId } = await createSetupIntent(
          {
            email: details.shippingContact?.emailAddress || '',
            responseId: newResponse.id || '',
            token: stringifiedPaymentDetails,
            type: 'APPLE_PAY',
          },
          client,
        );

        const processPaymentData = {
          paymentMethodId,
          paymentMethodType: PaymentMethodType.CARD,
          response: newResponse,
          sessionKey,
        };

        const { succeeded } = await saveResponseAndProcessPayment(client, newResponse, processPaymentData);

        callback(true);
        redirectAfterPayment(succeeded, newResponse.id || '');
      } catch (error) {
        if (typeof error === 'string') setErrorMessage(error);
        if ((error as PaymentErrorType)?.message) setErrorMessage((error as PaymentErrorType).message);
        paymentProcessing(false);
        callback(false);
      }
    };

    const total = {
      label: response.page?.title || '',
      amount: (response.order.totalCents / 100).toFixed(2),
    };

    if (applePayAvailable()) {
      void mountButton(APPLE_PAY_ELEMENT_ID, performPayment, total, 'finix');
    } else {
      setWalletType('google');
    }
  }, [
    response,
    paymentProcessing,
    client,
    redirectAfterPayment,
    setErrorMessage,
    setWalletType,
    saveResponse,
    sessionKey,
  ]);

  return <div className="apple-pay-container" id={APPLE_PAY_ELEMENT_ID}></div>;
};

export default ApplePay;
