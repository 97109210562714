import { Organization } from 'api/data/user/types';

const getSaveOrganizationInput = (changes: Partial<Organization>, organization?: Organization | null) => {
  const newOrganizationInfo = { ...organization, ...changes };

  delete newOrganizationInfo.currentUserRole;
  delete newOrganizationInfo.logoUrl;
  delete newOrganizationInfo.coverPictureUrl;
  delete newOrganizationInfo.fromV1;
  delete newOrganizationInfo.memberships;
  delete newOrganizationInfo.spaces;
  delete newOrganizationInfo.slug;
  delete newOrganizationInfo.provider;
  delete newOrganizationInfo.providerId;
  delete newOrganizationInfo.firstSpace;
  delete newOrganizationInfo.spacesCount;
  delete newOrganizationInfo.payoutSchedule;
  delete newOrganizationInfo.__typename;

  return newOrganizationInfo;
};

export default getSaveOrganizationInput;
