import { Response, OrderItem } from 'api/data/response/types';

export const defaultResponse: Response = {
  pageId: '',
  status: 'PREFILLED',
  answers: [],
  requestId: null,
  totalAmountCents: 0,
  netAmountCents: 0,
  order: {
    id: undefined,
    totalCents: 0,
    subtotalCents: 0,
    tip: {
      amountCents: 0,
      percentage: 0.1,
    },
    orderItems: [],
    metadata: {
      pricing: {
        ach: {
          totalCents: 0,
          processingFeeCents: 0,
        },
        card: {
          totalCents: 0,
          processingFeeCents: 0,
        },
        offline: {
          totalCents: 0,
          processingFeeCents: 0,
        },
      },
    },
  },
  user: undefined,
  metadata: {
    arbitraryTip: false,
  },
  supportMessages: [],
};

export const defaultOrderItem: OrderItem = {
  amount: null,
  unitPriceCents: null,
  recurring: '',
  block: {
    id: '',
  },
  blockId: '',
  blockTitle: '',
  blockType: '',
  providerData: {
    date: '',
    subscriptionId: '',
    priceId: '',
    organizationId: '',
  },
  variant: {
    amount: 0,
    id: '',
    title: '',
    quantity: 1,
  },
};

export const tipOptions = [
  { value: 0.1, label: '10%' },
  { value: 0.15, label: '15%' },
  { value: 0.2, label: '20%' },
];

export const errorCodesRegex = /error_creating_payment_intent|:/gi;
