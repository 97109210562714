import React, { FC, useRef } from 'react';
import clsx from 'clsx';
import { NumericFormat } from 'react-number-format';
import {
  filterDuplicated,
  getRecurringName,
  hasPaymentPlanInstallments,
  isValidPaymentAmount,
  numberCentsFormatter,
} from 'utils';
import {
  findOrderItemByVariant,
  removeOrderItemsByParentBlock,
  removeOrderItemByVariant,
  updateOrderItemPaymentOption,
  updateOrderItemByVariant,
} from 'api/data/response';
import NestedBlocks from 'components/NestedBlock';
import Tag from '~/components/Tag';
import Tooltip from 'components/Tooltip';
import VariantQuantity from 'components/Variant/VariantQuantity';
import { Variant as VariantType, PaymentOption } from '../types';
import './style.scss';

interface VariantProps {
  variant: VariantType;
  readOnly?: boolean;
  blockId: string;
  blockDescription?: string;
  showRadioButton: boolean;
  isRequired: boolean;
  multipleChoice: boolean;
  image?: string;
  parent?: VariantType;
  usedPasscodes?: string[];
  updateUsedPasscodes?: (blockId: string, passcode: string[]) => void;
  pageId?: string;
  hasRequiredChildBlocks?: boolean;
}

const Variant: FC<VariantProps> = ({
  variant,
  readOnly,
  blockId,
  blockDescription,
  showRadioButton,
  isRequired,
  multipleChoice,
  image,
  parent,
  usedPasscodes,
  updateUsedPasscodes,
  pageId,
  hasRequiredChildBlocks,
}) => {
  const paymentOptionsRef = useRef<{
    [key: string]: HTMLInputElement | null;
  }>({});

  const orderItem = findOrderItemByVariant(blockId, variant.id);
  const calculateTotal = (amountCents: string | undefined, installments: number) =>
    numberCentsFormatter(parseFloat(amountCents || '0') * installments);

  const focusPaymentOptionCustomAmount = (paymentOption: PaymentOption) => {
    if (
      paymentOption.allowAnyAmount &&
      paymentOptionsRef?.current &&
      paymentOptionsRef.current[`payment_${paymentOption.id}`]
    ) {
      paymentOptionsRef.current[`payment_${paymentOption.id}`]?.focus();
    }
  };

  const handlePaymentOptionChange = (paymentOption: PaymentOption, amount: number | null | undefined) => {
    if (readOnly || !hasStock) return;
    if (paymentOption.allowAnyAmount && amount !== null && amount !== undefined) {
      amount = parseInt((amount * 100).toFixed());
    }

    updateOrderItemPaymentOption(
      { ...orderItem, image },
      variant,
      paymentOption,
      blockDescription,
      amount,
      multipleChoice,
      parent,
    );

    focusPaymentOptionCustomAmount(paymentOption);
    removeOrderItemsByParentBlock(blockId);
  };

  const handleRadioButtonClick = () => {
    if (readOnly || isRequired) return;
    removeOrderItemByVariant(variant.id);
    removeOrderItemsByParentBlock(blockId);
  };

  const radioButtonPaymentOption = (
    paymentOptionSelected: boolean,
    paymentOption: PaymentOption,
    classname: string,
  ) => {
    return (
      <input
        id={`variant_${variant.id}`}
        type="radio"
        checked={paymentOptionSelected}
        name={`amount-option-${orderItem.variant.id}`}
        className={classname}
        onClick={() => paymentOptionSelected && handleRadioButtonClick()}
        onChange={() => handlePaymentOptionChange(paymentOption, paymentOption.amountCents)}
      />
    );
  };

  const handleAddOrderItem = () => {
    const firstPaymentOption = variant.paymentOptions[0];
    handlePaymentOptionChange(firstPaymentOption, firstPaymentOption.amountCents);
  };

  const hasStock =
    (variant.inventory.availableQuantity && variant.inventory.availableQuantity > 0) || variant.inventory.unlimited;

  const paymentOptionsFiltered = variant.paymentOptions.filter(filterDuplicated);

  const allowAnyAmount = !!variant.paymentOptions.find(
    paymentOption => paymentOption.id === orderItem.variant.paymentOptionId,
  )?.allowAnyAmount;

  return (
    <div className="payment-block-option-container">
      <div className="row space-between">
        <label
          htmlFor={`variant_${variant.id}`}
          id={`payment_variant_text_${variant.id}`}
          className={clsx('simple-variant-label', { 'spacing-label': !!variant.label })}>
          {variant.label}{' '}
          {!variant.inventory.unlimited &&
            (hasStock ? (
              <span>({variant.inventory.availableQuantity} remaining)</span>
            ) : (
              <Tag type="neutral" label="Unavailable" />
            ))}
        </label>
        {multipleChoice &&
          hasStock &&
          (orderItem.variant.paymentOptionId ? (
            <VariantQuantity
              key={orderItem.variant.paymentOptionId}
              variantId={variant.id}
              allowAnyAmount={allowAnyAmount}
              maxQuantity={variant.maxQuantity}
              availableQuantity={variant.inventory.unlimited ? null : variant.inventory.availableQuantity}
              isRequired={isRequired}
              orderItem={orderItem}
            />
          ) : (
            <button className="add-button" onClick={handleAddOrderItem}>
              <span>+</span> Add
            </button>
          ))}
      </div>
      <div>
        {paymentOptionsFiltered.map(paymentOption => {
          const paymentOptionSelected = orderItem.variant.paymentOptionId === paymentOption.id;
          const showTotalAmount =
            paymentOption.amountCents || (paymentOption.allowAnyAmount && orderItem.amount !== null);

          return (
            <div key={paymentOption.id}>
              <div
                className={clsx('payment-option', {
                  'variant-selected': paymentOptionSelected,
                  'any-amount': paymentOption.allowAnyAmount,
                })}>
                <div>
                  {(showRadioButton || paymentOptionsFiltered.length > 1) &&
                    (hasStock ? (
                      radioButtonPaymentOption(paymentOptionSelected, paymentOption, clsx({ disabled: readOnly }))
                    ) : (
                      <Tooltip color="dark" title="This option is unavailable">
                        {radioButtonPaymentOption(
                          paymentOptionSelected,
                          paymentOption,
                          clsx({ disabled: readOnly || !hasStock }, 'without-stock'),
                        )}
                      </Tooltip>
                    ))}
                  <div className={clsx('variant-text-container')}>
                    <div
                      className={clsx({
                        'amount-container': variant.label,
                        'amount-container-input': paymentOption.allowAnyAmount,
                      })}>
                      <span className="simple-variant-amount" id={`payment_variant_${paymentOption.id}`}>
                        $
                        {paymentOption.amountCents !== null && paymentOption.amountCents !== undefined
                          ? numberCentsFormatter(paymentOption.amountCents)
                          : ''}
                      </span>
                      {paymentOption.allowAnyAmount && (
                        <NumericFormat
                          value={
                            paymentOptionSelected && orderItem.amount !== null
                              ? numberCentsFormatter(orderItem.amount)
                              : ''
                          }
                          onValueChange={(target, sourceInfo) => {
                            const amount = target.value.trim() === '' ? null : parseFloat(target.value);

                            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                            if (sourceInfo.source === 'event') handlePaymentOptionChange(paymentOption, amount);
                          }}
                          disabled={readOnly || !hasStock}
                          thousandSeparator
                          decimalScale={2}
                          className="input-amount"
                          placeholder="Amount"
                          isAllowed={isValidPaymentAmount}
                          getInputRef={(element: HTMLInputElement) =>
                            (paymentOptionsRef.current[`payment_${paymentOption.id}`] = element)
                          }
                          onBlur={() => updateOrderItemByVariant(orderItem.blockId, orderItem.variant.id, {})}
                          maxLength={10}
                        />
                      )}
                      {getRecurringName(paymentOption.recurring) !== 'Once' && (
                        <span className="simple-variant-recurring">
                          / {getRecurringName(paymentOption.recurring)}{' '}
                          {hasPaymentPlanInstallments(paymentOption.paymentPlanInstallments) && (
                            <>for {paymentOption.paymentPlanInstallments} payments</>
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  {hasPaymentPlanInstallments(paymentOption.paymentPlanInstallments) &&
                    paymentOptionSelected &&
                    showTotalAmount && (
                      <span className="simple-variant-installments-total">
                        Total: $
                        {calculateTotal(
                          paymentOption.allowAnyAmount && orderItem.amount !== null
                            ? orderItem.amount?.toString()
                            : paymentOption.amountCents?.toString(),
                          paymentOption.paymentPlanInstallments || 0,
                        )}
                      </span>
                    )}
                  {paymentOptionSelected && !multipleChoice && (
                    <VariantQuantity
                      variantId={variant.id}
                      allowAnyAmount={paymentOption.allowAnyAmount}
                      maxQuantity={variant.maxQuantity}
                      availableQuantity={variant.inventory.unlimited ? null : variant.inventory.availableQuantity}
                      isRequired={isRequired}
                      orderItem={orderItem}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {(readOnly || !!orderItem.variant.paymentOptionId) && !!variant.blocks && (
          <NestedBlocks
            blocks={variant.blocks}
            parent={variant}
            usedPasscodes={usedPasscodes}
            updateUsedPasscodes={updateUsedPasscodes}
            pageId={pageId}
            isRequired={hasRequiredChildBlocks}
          />
        )}
      </div>
    </div>
  );
};

export default Variant;
