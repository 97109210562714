import React, { FC } from 'react';
import clsx from 'clsx';
import { defaultPageSize } from 'defaults/pagination';
import IconButton from 'components/Button/IconButton';
import SpinningCircle from 'components/SpinningCircle';
import { PaginationType } from 'api/data/response/types';
import './style.scss';

interface PaginationProps {
  onChange: (offset: number, limit: number) => Promise<void>;
  paginationInfo: PaginationType;
  loading?: boolean;
  showTotal?: boolean;
  pageSize?: number;
}

const MAX_VISIBLE_PAGES = 5;
const ellipsis = '...';

const Pagination: FC<PaginationProps> = ({
  onChange,
  paginationInfo,
  loading = false,
  showTotal = false,
  pageSize = defaultPageSize,
}) => {
  const { currentPage, totalPages, firstRecord, lastRecord, totalCount } = paginationInfo;

  const totalShowed = totalCount <= pageSize ? totalCount : `${firstRecord}-${lastRecord}`;

  const handlePageChange = async (page: number) => {
    const offset = (page - 1) * pageSize;
    await onChange(offset, pageSize);
  };

  const getPageNumbers = () => {
    if (totalPages <= MAX_VISIBLE_PAGES) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    }

    if (currentPage <= 3) {
      return [1, 2, 3, 4, ellipsis, totalPages];
    }

    if (currentPage >= totalPages - 2) {
      return [1, ellipsis, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    }

    return [1, ellipsis, currentPage - 1, currentPage, currentPage + 1, ellipsis, totalPages];
  };

  if (totalPages === 0) {
    return null;
  }

  return (
    <>
      {loading && <SpinningCircle overlay />}
      <div className="pagination row align-center">
        {showTotal && (
          <span className="subtitle-xx-small">
            Showing {totalShowed} of {totalCount} results
          </span>
        )}
        <nav className="row">
          <IconButton icon="chevron_duo_left" onClick={() => void handlePageChange(1)} disabled={currentPage === 1} />
          {getPageNumbers().map((page, index) => {
            const disabled = page === currentPage;

            return page === ellipsis ? (
              <span key={`ellipsis-${index}`} className="subtitle-xx-small align-self-center">
                {ellipsis}
              </span>
            ) : (
              <button
                key={page}
                onClick={() => void handlePageChange(Number(page))}
                disabled={disabled}
                className={clsx('page-number subtitle-xx-small', currentPage === page ? 'primary' : 'tertiary', {
                  disabled: disabled,
                })}>
                {page}
              </button>
            );
          })}
          <IconButton
            icon="chevron_duo_right"
            onClick={() => void handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          />
        </nav>
      </div>
    </>
  );
};

export default Pagination;
