import removeSpaces from './removeSpaces';

const validateEmail = (email: string, ignoreSpaces = true): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return ignoreSpaces ? re.test(removeSpaces(email).toLowerCase()) : re.test(email.toLowerCase());
};

export const emailList = (emails: string) => {
  const emailsReplaced = emails.replace(/,|\n|;/g, ' ');
  return emailsReplaced.split(' ').filter(email => email);
};

const validateEmailsList = (data: string) => {
  const emails = emailList(data);
  const validEmails = new Set();
  const invalidEmails = new Set();

  emails.forEach(element => {
    const email = element.trim();
    if (email) {
      if (validateEmail(email)) validEmails.add(email);
      else invalidEmails.add(email);
    }
  });

  return { validEmails: [...validEmails], invalidEmails: [...invalidEmails] };
};

export const checkEmails = (emails: string) => {
  const { validEmails, invalidEmails } = validateEmailsList(emails);

  return { validEmails: validEmails.join(', '), invalidEmails: invalidEmails.join(', ') };
};

export default validateEmail;
