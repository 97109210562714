import { PointerSensor as LibPointerSensor } from '@dnd-kit/core';
import { PointerEvent } from 'react';

const handler = ({ nativeEvent: event }: PointerEvent) => {
  let cur = event.target as HTMLElement;

  if (event.button !== 0) return false;

  while (cur) {
    // Check if it's an interactive element
    if (['input', 'textarea', 'select', 'a'].includes(cur.tagName.toLowerCase())) {
      return false;
    }
    cur = cur.parentElement as HTMLElement;
  }
  return true;
};

export class PointerSensor extends LibPointerSensor {
  static activators = [{ eventName: 'onPointerDown', handler }] as (typeof LibPointerSensor)['activators'];
}
