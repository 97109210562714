import { useEffect } from 'react';

const useScrollLock = (applyScrollLock: boolean) => {
  useEffect(() => {
    if (applyScrollLock) document.body.classList.add('scroll-lock');
    else document.body.classList.remove('scroll-lock');
  }, [applyScrollLock]);
};

export default useScrollLock;
