import React, { FC, useState } from 'react';
import { findAnswer } from 'api/data/response';
import { validateEmail } from 'utils';
import Email from 'components/Input/Email';
import ValidationWrapper from '../ValidationAnswerWrapper';
import { QuestionBlock } from '../types';
import './style.scss';

export interface EmailProps {
  data: QuestionBlock;
  placeholder: string;
  handleAnswerChange?: (matchedPasscodeIds: string[], value: string) => void;
  readOnly?: boolean;
  isEditing?: boolean;
  onSendReceiptCopyChange?: (value: boolean, label: string) => void;
  usedPasscodes?: string[];
  handleOpenAnswerValidation?: () => void;
}

const EmailBlock: FC<EmailProps> = ({
  data,
  placeholder,
  handleAnswerChange,
  readOnly,
  isEditing,
  onSendReceiptCopyChange,
  ...props
}) => {
  const answer = findAnswer(data.id, 'EMAIL');
  const [typedAnswer, setTypedAnswer] = useState(answer.value);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);

  const onChange = (value: string) => {
    setTypedAnswer(value);
    setIsEmailInvalid(false);
  };

  const answerChanged = (value: string) => {
    if (value && !validateEmail(value)) {
      setIsEmailInvalid(true);
    }

    if (data.hasValidationList) {
      setTypedAnswer(value);
    } else {
      handleAnswerChange?.([], value);
    }
  };

  return (
    <ValidationWrapper
      data={data}
      handleAnswerChange={handleAnswerChange}
      isEditing={isEditing}
      typedAnswer={typedAnswer}
      {...props}>
      <div className="email-block">
        <Email
          name="email"
          label=""
          value={typedAnswer}
          onChange={({ target }) => onChange(target.value)}
          onBlur={({ target }) => {
            answerChanged(target.value);
          }}
          disabled={readOnly}
          placeholder={placeholder}
          aria-label="email"
          rules={{
            hasError: isEmailInvalid && !data.hasValidationList,
            errorMessage: 'Please enter a valid email.',
          }}
          hint={!isEditing && data.sendReceiptCopy ? 'A copy of the receipt will be sent to this email' : ''}
          classNameHint="paragraph-xx-small neutral"
        />
        {isEditing && (
          <>
            <input
              type="checkbox"
              id="sendReceiptCopy"
              checked={data.sendReceiptCopy}
              onChange={({ target }) => onSendReceiptCopyChange?.(target.checked, 'sendReceiptCopy')}
            />
            <label htmlFor="sendReceiptCopy" className="paragraph-x-small">
              Send a copy of the receipt to this email
            </label>
          </>
        )}
      </div>
    </ValidationWrapper>
  );
};

export default EmailBlock;
