import React, { FC } from 'react';
import emailIcon from 'assets/email-solid.svg';
import smsIcon from 'assets/sms.svg';
import xIcon from 'assets/x.svg';
import remindIcon from 'assets/remind.svg';
import './style.scss';

export type ShareType = 'page' | 'payerPage' | 'participantPage' | 'addParticipant';
type SocialMediaType = 'email' | 'sms' | 'x' | 'remind';

type SocialSharingProps = {
  url: string;
  title: string;
  shareType: ShareType;
  message?: string;
  emailBcc?: string;
  p2pReferToMeAs?: string;
};

const buildLinks = (
  type: ShareType,
  socialMedia: SocialMediaType,
  url: string,
  title: string,
  message?: string,
  emailBcc?: string,
  p2pReferToMeAs?: string,
): string => {
  const utmSource = (source: string) => `?utm_source=${source}`;
  const baseParticipantMessage = `Hi there,%0D%0A%0D%0AWe're using Omella to run our team fundraiser! Please click the link below to join (takes 30 seconds).%0D%0A%0D%0A`;

  const links: Record<ShareType, Record<SocialMediaType, string>> = {
    page: {
      remind: `https://www.remind.com/v1/share?url=${url}&referrer=${url}&text=Check%20out%20${title.replace(/ /g, '%20')}%20on%20Omella:`,
      email: `mailto:?subject=${title} on Omella&body=Check out ${title} on Omella:%0D%0A%0D%0A${encodeURIComponent(`${url}${utmSource('mailto')}`)}&bcc=${emailBcc || ''}`,
      //we need to duplicate the body for the sms link to work
      sms: `sms:?body=Check out ${title} on Omella: ${url}${utmSource('sms')}&body=Check out ${title} on Omella:%0D%0A%0D%0A${url}${utmSource('sms')}`,
      x: `https://x.com/intent/post?text=Check out ${title} on @OmellaInc:&url=${url}${utmSource('x')}`,
    },
    payerPage: {
      remind: `https://www.remind.com/v1/share?url=${url}&referrer=${url}&text=Check%20out%20${title.replace(/ /g, '%20')}%20on%20Omella:`,
      email: `mailto:?subject=${title} on Omella&body=Check out ${title} on Omella:%0D%0A%0D%0A${encodeURIComponent(`${url}${utmSource('mailto')}`)}&bcc=${emailBcc || ''}`,
      //we need to duplicate the body for the sms link to work
      sms: `sms:?body=Check out ${title} on Omella: ${url}${utmSource('sms')}&body=Check out ${title} on Omella:%0D%0A%0D%0A${url}${utmSource('sms')}`,
      x: `https://x.com/intent/post?text=Check out ${title} on @OmellaInc:&url=${url}${utmSource('x')}`,
    },
    addParticipant: {
      remind: `https://www.remind.com/v1/share?url=${url}&referrer=${url}&text=Hi%20there,%0D%0A%0D%0APlease%20join%20our%20Omella%20fundraiser:`,
      email: `mailto:?subject=Join ${title} 🎉&body=${baseParticipantMessage}Thanks,%0D%0A%0D%0A${p2pReferToMeAs}%0D%0A%0D%0A${encodeURIComponent(`${url}${utmSource('mailto')}`)}&bcc=${emailBcc || ''}`,
      //we need to duplicate the body for the sms link to work
      sms: `sms:?body=${baseParticipantMessage}${url}${utmSource('sms')}&body=${baseParticipantMessage}${url}${utmSource('sms')}`,
      x: `https://x.com/intent/post?text=${baseParticipantMessage}${url}${utmSource('x')}`,
    },
    // when participantPage, different from the other types, it already send the message. We may need to refactor this
    participantPage: {
      remind: `https://www.remind.com/v1/share?url=${url}&referrer=${url}&text=${message?.replace(/ /g, '%20')}`,
      email: `mailto:?subject=${title}&body=${message}${encodeURIComponent(`${url}${utmSource('mailto')}`)}&bcc=${emailBcc || ''}`,
      //we need to duplicate the body for the sms link to work
      sms: `sms:?body=${message}${encodeURIComponent(`${url}${utmSource('sms')}`)}&body=${message}${encodeURIComponent(`${url}${utmSource('sms')}`)}`,
      x: `https://x.com/intent/post?text=${message}${encodeURIComponent(`${url}${utmSource('x')}`)}`,
    },
  };

  return links[type][socialMedia];
};

const SocialSharing: FC<SocialSharingProps> = ({ url, title, shareType, message, emailBcc, p2pReferToMeAs }) => {
  const getLink = (socialMedia: SocialMediaType) =>
    buildLinks(shareType, socialMedia, url, title, message, emailBcc, p2pReferToMeAs);

  return (
    <div className="row align-center social-media">
      <p className="paragraph-medium">One-click sharing</p>

      {!['participantPage', 'payerPage'].includes(shareType) && (
        <a className="row justify-center button remind" href={getLink('remind')} target="_blank" rel="noreferrer">
          <img src={remindIcon} alt="remind icon" /> Share on Remind
        </a>
      )}
      <a className="row justify-center button email" href={getLink('email')} target="_blank" rel="noreferrer">
        <img src={emailIcon} alt="email icon" /> Email
      </a>
      <a className="row justify-center button sms" href={getLink('sms')} target="_blank" rel="noreferrer">
        <img src={smsIcon} alt="sms icon" /> SMS
      </a>
      <a className="row justify-center button share" href={getLink('x')} target="_blank" rel="noreferrer">
        <img src={xIcon} alt="x icon" /> Share
      </a>
    </div>
  );
};

export default SocialSharing;
