import axios from 'axios';
import { APP_CONFIG } from 'api/config';

type PerformPayment = (details: ApplePayJS.ApplePayPayment, callback: (succeeded: boolean) => void) => Promise<void>;

type ValidateParams = {
  validationURL: string;
  provider: string;
};

const PAYMENT_REQUEST_DEFAULT = {
  countryCode: 'US',
  currencyCode: 'USD',
  merchantCapabilities: ['supports3DS' as ApplePayJS.ApplePayMerchantCapability],
  supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
  requiredShippingContactFields: ['name', 'email', 'postalAddress'] as ApplePayJS.ApplePayContactField[],
  total: {
    type: 'final' as ApplePayJS.ApplePayLineItemType,
  },
};

const APPLE_TAG_ELEMENT = 'apple-pay-button';

const applePayAvailable = () => window.ApplePaySession && ApplePaySession.canMakePayments();

const validateSession = async (
  { validationURL, provider }: ValidateParams,
  callback: (merchantSession: string) => void,
) => {
  await axios
    .post(APP_CONFIG.APPLE_PAY_SESSION_ENDPOINT, { validation_url: validationURL, provider })
    .then(function (response) {
      callback(response.data as string);
    });
};

const handleEvents = (session: ApplePaySession, performPayment: PerformPayment, provider: string) => {
  session.onvalidatemerchant = function ({ validationURL }) {
    void validateSession({ validationURL, provider }, function (merchantSession) {
      session.completeMerchantValidation(merchantSession);
    });
  };

  session.onpaymentauthorized = function (event) {
    void performPayment(event.payment, function (succeeded) {
      if (succeeded) {
        session.completePayment(ApplePaySession.STATUS_SUCCESS);
      } else {
        session.completePayment(ApplePaySession.STATUS_FAILURE);
      }
    });
  };
};

const startApplePaySession = (
  paymentRequest: ApplePayJS.ApplePayPaymentRequest,
  performPayment: PerformPayment,
  provider: string,
) => {
  const applePaySession = new ApplePaySession(6, paymentRequest);
  handleEvents(applePaySession, performPayment, provider);
  applePaySession.begin();
};

const createButtonElement = (parentElement: string) => {
  const applePayDiv = document.getElementById(parentElement);
  const applePayBtn = document.createElement(APPLE_TAG_ELEMENT);
  applePayBtn.setAttribute('buttonstyle', 'black');
  applePayBtn.setAttribute('type', 'pay');
  applePayBtn.setAttribute('data-testid', parentElement);
  applePayDiv?.append(applePayBtn);

  return applePayBtn;
};

const removeButtonElement = (parentElement: string) => {
  const applePayDiv = document.getElementById(parentElement);

  if (applePayDiv) applePayDiv.innerHTML = '';
};

const isButtonAlreadyCreated = () => document.getElementsByTagName(APPLE_TAG_ELEMENT).length > 0;

const handleOnClick = (
  paymentRequest: ApplePayJS.ApplePayPaymentRequest,
  performPayment: PerformPayment,
  provider: string,
) => startApplePaySession(paymentRequest, performPayment, provider);

const setButtonClickListener = (
  button: Element,
  paymentRequest: ApplePayJS.ApplePayPaymentRequest,
  performPayment: PerformPayment,
  provider: string,
) => {
  button.addEventListener('click', () => handleOnClick(paymentRequest, performPayment, provider));
};

const createAndSetEventButton = (
  total: ApplePayJS.ApplePayLineItem,
  parentElementId: string,
  performPayment: PerformPayment,
  provider: string,
) => {
  const paymentRequest = {
    ...PAYMENT_REQUEST_DEFAULT,
    total: {
      ...PAYMENT_REQUEST_DEFAULT.total,
      ...total,
    },
  };
  const appleButton = createButtonElement(parentElementId);
  setButtonClickListener(appleButton, paymentRequest, performPayment, provider);
};

const mountButton = async (
  parentElementId: string,
  performPayment: PerformPayment,
  total: ApplePayJS.ApplePayLineItem,
  provider: string,
) => {
  if (applePayAvailable()) {
    if (isButtonAlreadyCreated()) {
      removeButtonElement(parentElementId);
      createAndSetEventButton(total, parentElementId, performPayment, provider);
    } else {
      await ApplePaySession.canMakePaymentsWithActiveCard(APP_CONFIG.APPLE_PAY_MERCHANT_ID).then(
        function (canMakePayments) {
          if (canMakePayments && !isButtonAlreadyCreated()) {
            createAndSetEventButton(total, parentElementId, performPayment, provider);
          }
        },
      );
    }
  }
};

export { applePayAvailable, mountButton };
