import React, { FC } from 'react';
import { Link } from 'wouter';
import { isProduction } from '~/utils';
import useUserSession from 'hooks/useUserSession';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import './style.scss';

type ErrorType = { code: string; utm_source?: string };

const errorMap: {
  [key: string]: (userSession: boolean, utm_source?: string) => JSX.Element;
} = {
  PAGE_NOT_FOUND: (userSession, utm_source) => {
    const baseURL = isProduction() ? 'omella.com' : 'staging.omella.com';
    const link = utm_source ? `https://${baseURL}/join?utm_source=${utm_source}` : `${baseURL}/join`;

    return (
      <div className="not-found">
        <h1 className="heading-x-large">Oops! This page was not found or is no longer active</h1>
        <p className="subtitle-medium">
          Please double check the URL you typed or email <a href="mailto:help@omella.com">help@omella.com</a>
        </p>
        <hr />
        {!userSession ? (
          <>
            <p className="subtitle-medium">Looking for a team fundraiser?</p>
            <a href={link} className="button">
              Join a team fundraiser
            </a>
          </>
        ) : null}
      </div>
    );
  },
  CANCELED_REQUEST: () => (
    <div className="canceled-request">
      <h1 className="heading-x-large">
        Uh oh{' '}
        <span role="img" aria-label="confused">
          😕
        </span>
      </h1>
      <p className="subtitle-medium">
        It looks like this request has been canceled. No worries! Just reach out to the sender with questions.
      </p>
    </div>
  ),
  DEFAULT: () => (
    <>
      <h1 className="heading-x-large">Oops!</h1>
      <p className="subtitle-medium default">Something went wrong, please try again later.</p>
    </>
  ),
};

const Error: FC<ErrorType> = ({ code, utm_source }: ErrorType) => {
  const { data } = useUserSession();
  const { currentOrg } = useCurrentOrganization();
  const spaceSlug = currentSpaceSlug() || '';
  const error = errorMap[code] || errorMap.DEFAULT;
  const userSession = Boolean(data?.session?.currentUser && currentOrg);

  return (
    <div className="error">
      {error(userSession, utm_source)}
      {userSession ? (
        <Link to={`/${spaceSlug}/pages`} className="button">
          Go to my pages
        </Link>
      ) : null}
    </div>
  );
};

export default Error;
