import React, { FC, Dispatch, SetStateAction } from 'react';
import Modal from '../Modal';

type UnsavedChangesProps = {
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  onConfirmCallback?: () => void;
  onCancelCallback?: () => void;
};

const UnsavedChanges: FC<UnsavedChangesProps> = ({ setShow, show, onConfirmCallback, onCancelCallback }) => {
  return (
    <Modal
      handleOnCancel={() => {
        setShow(false);
        onCancelCallback?.();
      }}
      handleOnClose={() => setShow(false)}
      handleOnConfirm={() => onConfirmCallback && onConfirmCallback()}
      confirmlabel="Save changes"
      denyLabel="Leave without saving"
      visible={show}
      header="Unsaved Changes"
      headerIcon="save">
      <p>Are you sure you want to leave this page and discard your changes?</p>
    </Modal>
  );
};

export default UnsavedChanges;
