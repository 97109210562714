import React, { FC, Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import GooglePayButton from '@google-pay/button-react';
import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import { APP_CONFIG } from 'api/config';
import { createSetupIntent, saveResponseAndProcessPayment } from 'api/data/payment';
import { isProduction } from 'utils';
import { PaymentMethodType, PaymentErrorType } from 'api/data/payment/types';
import { Response } from 'api/data/response/types';
import './style.scss';

type CheckoutToken = {
  token: string;
};

const ENVIRONMENT = isProduction() ? 'PRODUCTION' : 'TEST';
const MERCHANT_INFO = {
  merchantId: APP_CONFIG.GOOGLE_MERCHANT_ID,
  merchantName: 'Omella',
};

const ALLOWED_PAYMENT_METHOD: google.payments.api.PaymentMethodSpecification = {
  type: 'CARD',
  parameters: {
    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    allowedCardNetworks: ['AMEX', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA'],
  },
  tokenizationSpecification: {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'checkoutltd',
      gatewayMerchantId: APP_CONFIG.CHECKOUT,
    },
  },
};

interface GooglePayProps {
  setErrorMessage: (message: string) => void;
  redirectAfterPayment: (succeeded: boolean, responseId: string) => void;
  paymentProcessing: Dispatch<SetStateAction<boolean>>;
  response: Response;
}

const GooglePay: FC<GooglePayProps> = ({ paymentProcessing, redirectAfterPayment, setErrorMessage, response }) => {
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

  const getToken = async (paymentData: google.payments.api.PaymentData, token: string) => {
    paymentProcessing(true);
    setErrorMessage('');

    try {
      const param = {
        type: 'googlepay',
        token_data: JSON.parse(token) as unknown,
      };

      const checkoutResponse = await axios.post<CheckoutToken>(APP_CONFIG.CHECKOUT_ENDPOINT, param, {
        headers: { Authorization: `Bearer ${APP_CONFIG.CHECKOUT}` },
      });

      const newResponse = {
        ...response,
        user: {
          email: paymentData.email || '',
          zipcode: paymentData.shippingAddress?.postalCode || '',
          fullName: paymentData.shippingAddress?.name || '',
        },
      };

      const { paymentMethodId } = await createSetupIntent(
        {
          email: paymentData.email || '',
          responseId: response.id || '',
          token: checkoutResponse.data.token,
        },
        client,
      );

      const processPaymentData = {
        paymentMethodId,
        paymentMethodType: PaymentMethodType.CARD,
        response: newResponse,
      };

      const { succeeded } = await saveResponseAndProcessPayment(client, newResponse, processPaymentData);

      redirectAfterPayment(succeeded, newResponse.id || '');
    } catch (error) {
      if (typeof error === 'string') setErrorMessage(error);
      if ((error as PaymentErrorType)?.message) setErrorMessage((error as PaymentErrorType).message);
      paymentProcessing(false);
    }
  };

  return (
    <GooglePayButton
      environment={ENVIRONMENT}
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        emailRequired: true,
        shippingAddressRequired: true,
        allowedPaymentMethods: [ALLOWED_PAYMENT_METHOD],
        merchantInfo: MERCHANT_INFO,
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: (response.order.totalCents / 100).toFixed(2),
          currencyCode: 'USD',
          countryCode: 'US',
        },
      }}
      buttonType="plain"
      onLoadPaymentData={paymentRequest => {
        void getToken(paymentRequest, paymentRequest.paymentMethodData.tokenizationData.token);
      }}
    />
  );
};

export default GooglePay;
