import React, { FC } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { getIconByExtension, getRecurringLabel, hasPaymentPlanInstallments, numberCentsFormatter } from 'utils';
import EXTENSIONS_ICONS from 'components/Icons/ExtensionsIcons';
import ICONS from 'components/Icons';
import { SubmissionDetail } from 'api/data/response/types';

const mapIconType: {
  [key: string]: string;
} = {
  group: 'group_icon',
  single_choice: 'single_choice_answer',
  multiple_choice: 'multiple_choice_answer',
  short_answer: 'short_answer',
  long_answer: 'long_answer',
  file_upload: 'file_upload',
  signature: 'signature',
  profile: 'profile_outline',
  donation: 'heart_money',
  payment: 'money',
  email: 'email',
};

interface SubmissionDetailsProps {
  respondentFullName: string;
  items: SubmissionDetail[];
  isChild?: boolean;
}

const SubmissionDetails: FC<SubmissionDetailsProps> = ({ respondentFullName, items, isChild = false }) => {
  const downloadAs = (url: string, questionTitle: string) => {
    const extension = url.split('.').pop() || '';
    void axios
      .get(url, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
      })
      .then(response => {
        const aLink = document.createElement('a');
        const url = window.URL.createObjectURL(response.data as Blob);
        aLink.href = url;
        aLink.download = `${respondentFullName}-${questionTitle}.${extension}`;
        aLink.click();
      });
  };

  const fileUploadComponent = (submission: SubmissionDetail) => {
    if (submission.isFile) {
      const extension = submission.answer?.split('.').pop() || '';
      return (
        <button
          className="button-clear"
          onClick={ev => {
            ev.stopPropagation();
            if (submission.answer) downloadAs(submission.answer, submission.title);
          }}>
          <img src={getIconByExtension(extension)} alt={extension} />
          <span className="size-xxxs">{`Download ${extension}`}</span>
        </button>
      );
    }

    const hrefLink =
      submission.answer?.indexOf('http://') === 0 || submission.answer?.indexOf('https://') === 0
        ? submission.answer
        : `http://${submission.answer}`;

    return (
      <a className="button-clear" href={hrefLink} target="_blank" rel="noreferrer" onClick={ev => ev.stopPropagation()}>
        <img src={EXTENSIONS_ICONS['link']} alt="link" />
        {submission.answer}
      </a>
    );
  };

  const handleAnswer = ({ answer, choice }: SubmissionDetail) => {
    return answer || choice;
  };

  return (
    <>
      {items.map((item, index) => {
        const itemIcon = ICONS[mapIconType[item.blockType.toLowerCase()]];
        const hasChildren = item.children && item.children.length > 0;

        return (
          <div key={index} className={clsx('item-row', { child: isChild, 'has-children': hasChildren })}>
            <div
              className={clsx('row align-center justify-center direction-column icon-container', {
                'justify-end': hasChildren,
              })}>
              {item.imageUrl ? <img className="block-image" src={item.imageUrl} alt={item.blockType} /> : itemIcon}
              {hasChildren && <span className="vertical-line"></span>}
            </div>
            <div className="row direction-column item-content">
              <div className="row item-content">
                <div className="row direction-column justify-center answer-wrapper">
                  <p className={clsx('question', { signature: item.blockType === 'signature' })}>
                    {item.title}
                    {!!item.description && <p className="answer">{item.description}</p>}
                  </p>
                  <p className={clsx('answer', { 'cursive-text': item.blockType === 'signature' })}>
                    {item.blockType === 'file_upload' ? fileUploadComponent(item) : handleAnswer(item)}
                  </p>
                  {!!item.quantity && <>Qty {item.quantity}</>}
                  {!!item.amountCents && (
                    <>
                      <br />
                      {item.recurring &&
                        item.recurring.toLowerCase() !== 'once' &&
                        `${getRecurringLabel(item.recurring.toUpperCase())}`}
                      {hasPaymentPlanInstallments(item.paymentPlanInstallments) &&
                        ` for ${item.paymentPlanInstallments} payments`}
                    </>
                  )}
                </div>
                <div className="row align-center">
                  {!!item.amountCents && <p className="subtitle-xx-small">${numberCentsFormatter(item.amountCents)}</p>}
                </div>
              </div>
              {hasChildren && (
                <div className="children-wrapper">
                  <SubmissionDetails items={item.children || []} respondentFullName={respondentFullName} isChild />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SubmissionDetails;
