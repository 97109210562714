import React, { FC, useEffect, useState } from 'react';
import useFinixAuth from 'hooks/useFinixAuth';
import ApplePay from './ApplePay';
import GooglePay from './GooglePay';
import PaymentError from 'components/Error/PaymentError';
import { ProviderProps } from '../types';

const Checkout: FC<ProviderProps> = ({
  paymentMethod,
  paymentProcessing,
  setWalletType,
  redirectAfterPayment,
  children,
  response,
  walletType,
  providerId,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const { sessionKey } = useFinixAuth(providerId);

  useEffect(() => {
    setWalletType('apple');
  }, [setWalletType]);

  return (
    <div className="block wallet-checkout">
      {children}
      {paymentMethod === 'wallet' && (
        <>
          {!!errorMessage && <PaymentError errorMessage={errorMessage} title="Failed to pay" />}
          {walletType === 'google' ? (
            <GooglePay
              paymentProcessing={paymentProcessing}
              redirectAfterPayment={redirectAfterPayment}
              setErrorMessage={setErrorMessage}
              response={response}
              sessionKey={sessionKey}
              {...props}
            />
          ) : (
            <ApplePay
              paymentProcessing={paymentProcessing}
              redirectAfterPayment={redirectAfterPayment}
              setErrorMessage={setErrorMessage}
              setWalletType={setWalletType}
              response={response}
              sessionKey={sessionKey}
              {...props}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Checkout;
