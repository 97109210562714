import { Organization, User } from '~/api/data/user/types';

const atlasInit = (currentUser: User | null | undefined, currentOrg: Organization | null | undefined) => {
  if (currentUser) {
    window.Atlas.call('identify', {
      userId: currentUser?.id,
      name: currentUser?.fullName,
      email: currentUser?.email,
      customFields: { created_at: currentUser?.insertedAt ? Date.parse(currentUser?.insertedAt) / 1000 : undefined },
      account: { name: currentOrg?.name, externalId: currentOrg?.id },
    });
  }
};

export default atlasInit;
