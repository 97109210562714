import React, { FC, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import * as ResponseQuery from 'graphql/response.graphql';
import useDebounce from '~/hooks/useDebounce';
import { ValidatePasscode } from 'api/data/response/types';
import { QuestionBlock } from '../types';

export interface ValidationWrapperProps {
  data: QuestionBlock;
  typedAnswer: string;
  handleAnswerChange?: (matchedPasscodeIds: string[], value: string) => void;
  usedPasscodes?: string[];
  isEditing?: boolean;
  handleOpenAnswerValidation?: () => void;
  children: React.ReactNode;
}

const ValidationWrapper: FC<ValidationWrapperProps> = ({
  data,
  typedAnswer,
  handleAnswerChange,
  handleOpenAnswerValidation,
  usedPasscodes = [],
  isEditing = false,
  children,
}: ValidationWrapperProps) => {
  const debouncedAnswer = useDebounce<string>(typedAnswer);
  const isFirstRender = useRef(true);
  const [showMessage, setShowMessage] = useState(false);

  const { loading } = useQuery<ValidatePasscode>(ResponseQuery.ValidatePasscode, {
    variables: { blockId: data.id, passcode: debouncedAnswer },
    skip: !data.hasValidationList || (isFirstRender.current && !debouncedAnswer),
    onCompleted: result => {
      isFirstRender.current = false;
      if (result && handleAnswerChange) {
        const validCodes = result.validatePasscode.filter(n => !usedPasscodes.includes(n));
        if (validCodes.length > 0) {
          handleAnswerChange(validCodes, typedAnswer);
          setShowMessage(false);
        } else {
          handleAnswerChange([], '');
          setShowMessage(true);
        }
      }
    },
  });

  return (
    <>
      {children}
      {isEditing && data.hasValidationList && (
        <div className="row space-between">
          <button className="button-link xxx-small" onClick={handleOpenAnswerValidation}>
            Answer must be valid
          </button>
        </div>
      )}
      {loading && <span>loading...</span>}
      {!loading && debouncedAnswer.length > 0 && showMessage && (
        <span className="error-message size-xxxs">Please enter a valid answer to continue</span>
      )}
    </>
  );
};

export default ValidationWrapper;
