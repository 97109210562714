import { v4 as uuidv4 } from 'uuid';
import { EditorState } from 'draft-js';
import { BlockTextState } from 'api/data/pages/blocks/types';
import { PageDataDraftBlock } from '../api/data/pages/types';
import { PaymentOption, Variant } from 'components/PaymentBlock/types';
import { Variant as DonationVariant, DonationBlock } from 'components/DonationBlock/types';
import { SupporterFeedBlock } from 'components/SupporterFeedBlock/types';

export type RecurringValue = 'ONCE' | 'MONTHLY' | 'YEARLY' | '';

export interface RecurringOption {
  id: RecurringValue;
  label: string;
  noun: string;
  value: boolean;
}

const recurringOptions: { [key: string]: RecurringOption } = {
  ONCE: { label: 'Once', id: 'ONCE', value: false, noun: 'Once' },
  MONTHLY: { label: 'Monthly', id: 'MONTHLY', value: false, noun: 'Month' },
  YEARLY: { label: 'Yearly', id: 'YEARLY', value: false, noun: 'Year' },
};

const recurringOptionsArray = Object.values(recurringOptions);

const questionChoicesThreshold = 7;

const donationDefaultAmounts = [50000, 25000, 10000, 7500, 3500];

export const defaultPageTitle = 'Untitled';

export const defaultBlocksTitle = {
  DONATION: 'Donation',
  PAYMENT: 'Payment',
  SINGLE_CHOICE: 'Question',
  MULTIPLE_CHOICE: 'Question',
  SHORT_ANSWER: 'Question',
  LONG_ANSWER: 'Question',
  FILE_UPLOAD: 'Upload your file',
  SIGNATURE: 'Your signature',
  SUPPORTER_FEED: 'Top supporters',
  EMAIL: 'Email',
};

export const defaultMaxLengthTitle = 255;

export const emptyPage = (id: string): PageDataDraftBlock => ({
  id,
  title: '',
  blocks: [],
  slug: '',
  organizationId: '',
  tipsEnabled: true,
  processingFeePayer: 'PAYER',
  customBodyReceiptContent: '',
  participant: null,
  startDate: null,
  endDate: null,
  isTemplate: false,
  acceptOfflinePayments: false,
  offlinePaymentInstructions: '',
});

export const defaultPaymentVariant = (type: 'DONATION' | 'PAYMENT'): Variant | DonationVariant => {
  if (type === 'PAYMENT')
    return {
      id: uuidv4(),
      order: 0,
      image: null,
      label: '',
      inventory: {
        unlimited: true,
        availableQuantity: 0,
        id: uuidv4(),
      },
      paymentOptions: [defaultPaymentOption()],
    };
  else
    return {
      id: uuidv4(),
      amountCents: undefined,
      allowAnyAmount: false,
      order: 0,
    };
};

export const defaultDonationVariants = (): DonationVariant[] =>
  donationDefaultAmounts.map(
    (amountCents, index): DonationVariant => ({
      ...(defaultPaymentVariant('DONATION') as DonationVariant),
      order: index,
      amountCents,
    }),
  );

export const defaultDonationBlock = (): DonationBlock => ({
  description: '',
  id: uuidv4(),
  order: 1,
  required: true,
  type: 'DONATION',
  recurring: ['ONCE', 'MONTHLY', 'YEARLY'],
  variants: [
    ...defaultDonationVariants(),
    {
      id: uuidv4(),
      allowAnyAmount: true,
      order: donationDefaultAmounts.length,
    },
  ],
});

export const defaultTextBlock = (data: EditorState): BlockTextState => ({
  id: uuidv4(),
  order: 0,
  type: 'TEXT',
  textBlock: {
    id: uuidv4(),
    data,
  },
});

export const defaultPaymentOption = (): PaymentOption => ({
  amountCents: undefined,
  recurring: 'ONCE',
  allowAnyAmount: true,
  paymentPlanInstallments: -1,
  order: 0,
  id: uuidv4(),
});

export const defaultSupporterFeedBlock = (): SupporterFeedBlock => ({
  title: '',
  id: uuidv4(),
  order: 1,
  required: false,
  type: 'SUPPORTER_FEED',
});

export enum PagesStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export const defaultValidAnswerPlaceholder = (isEmailValidation?: boolean) =>
  `Separate different ${isEmailValidation ? 'emails' : 'answers'} with a comma, space, new line or semicolon
ex:
${
  isEmailValidation
    ? `one@email.com
two@email.com
three@email.com, four@email.com`
    : `student_id_10
student_id_11
student_id_12, student_id_13`
}`;

export { recurringOptions, recurringOptionsArray, questionChoicesThreshold };
