import React, { FC, ChangeEvent } from 'react';
import './style.scss';

type InputLabelFloatingProps = {
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value?: string;
  className?: string;
  placeholder?: string;
  secondLabel?: string;
  innerRef?: (ref: HTMLInputElement | null) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  disabled?: boolean;
};

const InputLabelFloating: FC<InputLabelFloatingProps> = ({
  label,
  secondLabel,
  placeholder,
  onChange,
  value,
  className,
  name,
  innerRef,
  onBlur,
  autoFocus,
  disabled,
}) => {
  return (
    <div className={`input-container-floating ${className || ''}`}>
      <input
        type="text"
        name={`input-label-floating-${name}`}
        id={`input-label-floating-${name}`}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        ref={innerRef}
        onBlur={onBlur}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        disabled={disabled}
        maxLength={1000}
      />
      <label htmlFor={`input-label-floating-${name}`}>
        {label}
        <span className="size-xxs">{secondLabel}</span>
      </label>
    </div>
  );
};

export default InputLabelFloating;
