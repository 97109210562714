import React, { ReactElement } from 'react';
import { RouteProps, DefaultParams, Route, useRoute, Redirect, useLocation } from 'wouter';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import { atlasInit } from '~/utils';
import { setWSToken } from 'api/client';
import useCioUserIdentify from 'hooks/useCioUserIdentify';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import useParticipantLoggedIn from '~/hooks/useParticipantLoggedIn';
import useSpacesFromCurrentOrg from '~/hooks/useSpacesFromCurrentOrg';
import useUserSession from 'hooks/useUserSession';
import { Action, checkUserRole } from '../ProtectedComponent';
import Login from 'pages/Login';
import LoginTokenRequest from 'pages/Login/LoginTokenRequest';
import { Organization, Session, Space } from '~/api/data/user/types';

interface ProtectedRouteI<T extends DefaultParams> extends RouteProps<T> {
  viewAs?: 'admin' | 'payer' | 'participant';
  action?: Action;
}

function ProtectedRoute<T extends DefaultParams>({
  path,
  component,
  children,
  viewAs = 'admin',
  action,
}: ProtectedRouteI<T>): ReactElement {
  const { data, loading, error } = useUserSession();
  const { currentOrg, loading: loadingOrg, error: errorOrg } = useCurrentOrganization();
  const { currentSpace } = useSpacesFromCurrentOrg();

  const [location] = useLocation();

  const [match, params] = useRoute(path || '');

  const { logged: participantLoggedIn, loading: participantLoading } = useParticipantLoggedIn(params);

  atlasInit(data?.session.currentUser, currentOrg);
  useCioUserIdentify();

  if (loading || (loadingOrg && !currentOrg) || participantLoading) return <div>Loading... </div>;

  if (viewAs === 'participant')
    return participantLoggedIn ? <Route path={path} component={component} /> : <Redirect to="/error/page_not_found" />;

  const userPresent = data?.session.currentUser;

  function findSpaceSlugByOrgSlug(slug?: string) {
    return data?.session.managedOrganizations?.find(org => org.slug === slug)?.firstSpace?.slug;
  }

  const spaceSlugByOrg = findSpaceSlugByOrgSlug(params?.currentSpace);

  if (spaceSlugByOrg && params?.currentSpace) {
    const newUrl = location.replace(params?.currentSpace, spaceSlugByOrg);

    return <Redirect to={newUrl} />;
  }

  if (match) currentSpaceSlug(params?.currentSpace);

  // logged in user doesn't have access to the requested org
  // it will be redirected soon by client.ts error handling
  if (userPresent && errorOrg) return <div>Error. </div>;

  const haveAccess = userHaveAccess(viewAs, currentSpace, currentOrg, data?.session, action);
  if (data?.session.wsAuthToken) setWSToken(data?.session.wsAuthToken);

  if (!error && !errorOrg && userPresent && haveAccess)
    if (component) return <Route path={path} component={component} />;
    else return <Route path={path}>{children}</Route>;

  if (viewAs === 'payer') return <LoginTokenRequest />;

  if (action && currentOrg && currentSpace && !haveAccess) return <Redirect to="/error/page_not_found" />;

  return <Login />;
}

function userHaveAccess(
  viewAs: 'admin' | 'payer' | 'participant',
  currentSpace?: Space,
  currentOrg?: Organization,
  session?: Session,
  action?: Action,
) {
  //if there is no action, we will do the default check (just being a member or having a managed org will be enough)
  if (!action) {
    const hasManagedOrgs = session?.managedOrganizations && session?.managedOrganizations.length > 0;
    return currentOrg || hasManagedOrgs || viewAs === 'payer';
  }

  //otherwise we will need to check the if the user has access based on the role and the action
  return checkUserRole(action, currentOrg?.currentUserRole, currentSpace?.currentUserRole);
}

export default ProtectedRoute;
