import React, { FC, SyntheticEvent, useState, useMemo } from 'react';
import { useApolloClient, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { fetchOrProcessSetupIntentData } from 'api/data/payment';
import { validateEmail } from 'utils';
import useSessionPreferences from 'hooks/useSessionPreferences';
import Input from '../Input';
import Email from '../Input/Email';
import PaymentError from '../Error/PaymentError';
import { OfflinePaymentProps } from './types';
import { PaymentErrorType, PaymentMethodType } from 'api/data/payment/types';
import { User } from 'api/data/response/types';
import './style.scss';

const OfflinePayment: FC<OfflinePaymentProps> = ({
  onSubmit,
  setLoading,
  loading,
  response,
  onResponseChanged,
  pageId,
  paymentError,
  disableInputEmail,
}) => {
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const [user, setUser] = useState<User>(response.user ? response.user : { email: '', zipcode: '', fullName: '' });
  const [errorMessage, setErrorMessage] = useState<string>();
  const { acceptInPersonPayments } = useSessionPreferences();

  const handleOnBlur = () => {
    onResponseChanged?.({ ...response, user });
  };

  const handleResponseUpdated = (value: string, field: string) => {
    setUser({ ...user, [field]: value });
  };

  const isEmailInvalid = () => !acceptInPersonPayments && !!user?.email && !validateEmail(user?.email);
  const isFormValid = useMemo(() => {
    if (acceptInPersonPayments) {
      return true;
    }

    const isValidEmail = !!user?.email && validateEmail(user.email);
    const hasFullName = !!user?.fullName && user.fullName.trim() !== '';

    return isValidEmail && hasFullName;
  }, [user?.email, user?.fullName, acceptInPersonPayments]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');

    try {
      const processPaymentData = await fetchOrProcessSetupIntentData(
        PaymentMethodType.OFFLINE,
        null,
        client,
        { ...response, user: acceptInPersonPayments ? undefined : user },
        null,
      );

      await onSubmit(processPaymentData);
    } catch (error) {
      setErrorMessage((error as PaymentErrorType).message);
      setLoading(false);
    }
  };

  const renderUserInfoForm = () => (
    <>
      <Email
        onChange={({ target }) => handleResponseUpdated(target.value, 'email')}
        value={user?.email || ''}
        name="input-mail"
        disabled={disableInputEmail}
        onBlur={({ target }) => {
          handleResponseUpdated(target.value, 'email');
          handleOnBlur();
        }}
        rules={{
          hasError: isEmailInvalid(),
          errorMessage: 'Please enter a valid email.',
        }}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      {validateEmail(user?.email || '') && (
        <Input
          label="Full name"
          placeholder="Full name"
          onChange={({ target }) => handleResponseUpdated(target.value, 'fullName')}
          onBlur={() => handleOnBlur()}
          value={user?.fullName}
          name="full-name"
          type="text"
        />
      )}
    </>
  );

  const isSubmitDisabled =
    loading || (!acceptInPersonPayments && !isFormValid) || (!!pageId && response.order.totalCents === 0);

  return (
    <div className="offline-payment-container">
      {!loading && errorMessage && <PaymentError title={paymentError?.title} errorMessage={errorMessage} />}
      <form onSubmit={event => void handleSubmit(event)} className="payment-method" aria-label="offline payment form">
        {!acceptInPersonPayments && renderUserInfoForm()}
        <button type="submit" disabled={isSubmitDisabled}>
          {loading ? 'Processing...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default OfflinePayment;
