import React, { useState, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import ICONS from 'components/Icons';
import './style.scss';

interface TableColumn<T> {
  header: string;
  accessor: (item: T) => React.ReactNode;
  align?: 'left' | 'right' | 'center';
  showSortIcon?: boolean;
  ellipsis?: boolean;
}

interface TableProps<T> {
  data: T[];
  columns: TableColumn<T>[];
  getRowId: (item: T) => string;
  getChildren?: (item: T) => T[] | undefined;
  enableRowSelection?: boolean;
  isRowSelectable?: (item: T) => boolean;
  selectedItems?: { [id: string]: boolean };
  onSelectionChange?: (newSelection: { [id: string]: boolean }) => void;
}

interface TableHeaderProps<T> {
  enableRowSelection?: boolean;
  columns: TableColumn<T>[];
  getChildren?: (item: T) => T[] | undefined;
  onSelectAll: () => void;
  isSelectAllChecked: boolean;
  isSelectAllIndeterminate: boolean;
}

export type SelectedItems = { [id: string]: boolean };

const TableHeader = <T,>({
  enableRowSelection,
  columns,
  getChildren,
  onSelectAll,
  isSelectAllChecked = false,
  isSelectAllIndeterminate,
}: TableHeaderProps<T>) => (
  <thead>
    <tr>
      {enableRowSelection && (
        <th className="action-column">
          <input
            type="checkbox"
            onChange={onSelectAll}
            checked={isSelectAllChecked}
            className={clsx({ indeterminate: isSelectAllIndeterminate })}
            aria-label="Select all"
          />
        </th>
      )}
      {columns.map((col, index) => (
        <th
          key={index}
          className={clsx('subtitle-xx-small neutral', {
            'row align-center justify-end': col.align === 'right',
          })}
          colSpan={index === 0 && getChildren ? 2 : 1}>
          {col.header}
          {col.showSortIcon && ICONS['arrow_slim_down']}
        </th>
      ))}
    </tr>
  </thead>
);

interface TableRowProps<T> {
  item: T;
  columns: TableColumn<T>[];
  subItems?: T[];
  isExpanded?: boolean;
  isSelectable: boolean;
  enableRowSelection?: boolean;
  isSelected: boolean;
  isIndeterminate: boolean;
  onToggleExpand: () => void;
  onSelect: () => void;
}

const TableRow = <T,>({
  item,
  columns,
  subItems,
  isExpanded,
  isSelectable,
  enableRowSelection,
  isSelected = false,
  isIndeterminate,
  onToggleExpand,
  onSelect,
}: TableRowProps<T>) => {
  const hasChildren = subItems && subItems.length > 0;

  return (
    <tr
      className={clsx('main-item', { 'has-children': hasChildren })}
      onClick={e => {
        const target = e.target as HTMLInputElement;
        if (target.type !== 'checkbox' && hasChildren) {
          onToggleExpand();
        }
      }}>
      {enableRowSelection && (
        <td className="action-column">
          <input
            type="checkbox"
            checked={isSelected}
            className={clsx({ indeterminate: hasChildren && isIndeterminate })}
            onChange={onSelect}
            disabled={!isSelectable}
            aria-label="Select row"
          />
        </td>
      )}
      {hasChildren && <td className="action-column">{isExpanded ? ICONS['arrow_up'] : ICONS['arrow_right']}</td>}
      {columns.map((col, colIndex) => (
        <td
          key={colIndex}
          className={clsx('paragraph-x-small', {
            'text-align-right': col.align === 'right',
            ellipsis: col.ellipsis,
          })}>
          {col.ellipsis ? (
            <span className="paragraph-x-small ellipsis-text">{col.accessor(item)}</span>
          ) : (
            col.accessor(item)
          )}
        </td>
      ))}
    </tr>
  );
};

const TableChildRow = <T,>({
  child,
  columns,
  enableRowSelection,
  isSelected = false,
  isSelectable,
  onSelect,
}: {
  child: T;
  columns: TableColumn<T>[];
  enableRowSelection?: boolean;
  isSelected: boolean;
  isSelectable: boolean;
  onSelect: () => void;
}) => (
  <tr className="sub-items">
    {enableRowSelection && (
      <td className="action-column">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={onSelect}
          disabled={!isSelectable}
          aria-label="Select child row"
        />
      </td>
    )}
    {columns.map((col, colIndex) => (
      <td
        key={colIndex}
        className={clsx('paragraph-x-small', {
          'text-align-right': col.align === 'right',
          ellipsis: col.ellipsis,
        })}
        colSpan={colIndex === 0 ? 2 : 1}>
        {col.ellipsis ? (
          <span className="paragraph-x-small neutral ellipsis-text">{col.accessor(child)}</span>
        ) : (
          col.accessor(child)
        )}
      </td>
    ))}
  </tr>
);

const Table = <T,>({
  data,
  columns,
  getRowId,
  getChildren,
  enableRowSelection,
  isRowSelectable = () => true,
  selectedItems = {},
  onSelectionChange,
}: TableProps<T>) => {
  const allIds = useMemo(() => {
    const ids: string[] = [];
    data.forEach(item => {
      ids.push(getRowId(item));
      const children = getChildren?.(item);
      if (children) {
        children.forEach(child => {
          ids.push(getRowId(child));
        });
      }
    });
    return ids;
  }, [data, getChildren, getRowId]);

  const nonSelectableIds = useMemo(() => {
    const ids = new Set<string>();
    data.forEach(item => {
      const children = getChildren?.(item);
      if (children) {
        children.forEach(child => {
          if (!isRowSelectable(child)) {
            ids.add(getRowId(child));
          }
        });
      }
    });
    return ids;
  }, [data, getChildren, getRowId, isRowSelectable]);

  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});

  const handleSelectAll = useCallback(() => {
    const isAllSelected = allIds.every(id => selectedItems[id]);
    const newSelection = { ...selectedItems };

    if (isAllSelected) {
      allIds.forEach(id => {
        if (!nonSelectableIds.has(id)) {
          delete newSelection[id];
        }
      });
    } else {
      allIds.forEach(id => {
        if (!nonSelectableIds.has(id)) {
          newSelection[id] = true;
        }
      });
    }

    onSelectionChange?.(newSelection);
  }, [allIds, nonSelectableIds, selectedItems, onSelectionChange]);

  const handleGroupSelect = useCallback(
    (groupId: string, groupChildren: T[]) => {
      const selectableChildren = groupChildren.filter(isRowSelectable);
      const childIds = selectableChildren.map(getRowId);
      const allChildrenSelected = childIds.every(id => selectedItems[id]);
      const newSelection = { ...selectedItems };

      if (allChildrenSelected) {
        if (!nonSelectableIds.has(groupId)) {
          delete newSelection[groupId];
        }
        childIds.forEach(id => {
          if (!nonSelectableIds.has(id)) {
            delete newSelection[id];
          }
        });
      } else {
        if (isRowSelectable(groupChildren[0]) && !nonSelectableIds.has(groupId)) {
          newSelection[groupId] = true;
        }
        childIds.forEach(id => {
          if (!nonSelectableIds.has(id)) {
            newSelection[id] = true;
          }
        });
      }

      onSelectionChange?.(newSelection);
    },
    [selectedItems, getRowId, onSelectionChange, isRowSelectable, nonSelectableIds],
  );

  const handleRowSelect = useCallback(
    (id: string, parentId?: string, siblings?: T[]) => {
      const newSelection = { ...selectedItems };

      if (selectedItems[id]) {
        delete newSelection[id];
        if (parentId && !nonSelectableIds.has(parentId)) {
          delete newSelection[parentId];
        }
      } else {
        newSelection[id] = true;
        if (parentId && siblings) {
          const selectableSiblings = siblings.filter(isRowSelectable);
          if (
            selectableSiblings.every(sibling => newSelection[getRowId(sibling)] || getRowId(sibling) === id) &&
            !nonSelectableIds.has(parentId)
          ) {
            newSelection[parentId] = true;
          }
        }
      }

      onSelectionChange?.(newSelection);
    },
    [selectedItems, getRowId, isRowSelectable, nonSelectableIds, onSelectionChange],
  );

  const isGroupIndeterminate = useCallback(
    (children: T[]) => {
      const hasSelectedChildren = children.some(child => selectedItems[getRowId(child)]);
      const allChildrenSelected = children.every(child => selectedItems[getRowId(child)]);
      return hasSelectedChildren && !allChildrenSelected;
    },
    [selectedItems, getRowId],
  );

  const isSelectAllIndeterminate = useCallback(() => {
    const hasSelected = allIds.some(id => selectedItems[id]);
    const allSelected = allIds.every(id => selectedItems[id]);
    return hasSelected && !allSelected;
  }, [allIds, selectedItems]);

  return (
    <div className="table-container">
      <table className="table list">
        <TableHeader
          enableRowSelection={enableRowSelection}
          columns={columns}
          getChildren={getChildren}
          onSelectAll={handleSelectAll}
          isSelectAllChecked={allIds.length > 0 && allIds.every(id => selectedItems[id])}
          isSelectAllIndeterminate={isSelectAllIndeterminate()}
        />
        <tbody>
          {data.map(item => {
            const id = getRowId(item);
            const children = getChildren?.(item);
            const isExpanded = expandedGroups[id];
            const isSelectable = isRowSelectable(item);

            return (
              <React.Fragment key={id}>
                <TableRow
                  item={item}
                  columns={columns}
                  subItems={children}
                  isExpanded={isExpanded}
                  isSelectable={isSelectable}
                  enableRowSelection={enableRowSelection}
                  isSelected={selectedItems[id]}
                  isIndeterminate={children ? isGroupIndeterminate(children) : false}
                  onToggleExpand={() => setExpandedGroups(prev => ({ ...prev, [id]: !prev[id] }))}
                  onSelect={() => {
                    if (children) handleGroupSelect(id, children);
                    else handleRowSelect(id);
                  }}
                />
                {children &&
                  isExpanded &&
                  children.map(child => {
                    const childId = getRowId(child);
                    const childSelectable = isRowSelectable(child);

                    return (
                      <TableChildRow
                        key={childId}
                        child={child}
                        columns={columns}
                        enableRowSelection={enableRowSelection}
                        isSelected={selectedItems[childId]}
                        isSelectable={childSelectable}
                        onSelect={() => handleRowSelect(childId, id, children)}
                      />
                    );
                  })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
