import React, { FC, useState, useEffect } from 'react';
import { useReactiveVar, useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, useLocation } from 'wouter';
import { default as queryString } from 'query-string';
import { isEmbed, pickKeys } from 'utils';
import isURL from 'validator/lib/isURL';
import dayjs from 'dayjs';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import * as AuthQuery from 'graphql/auth.graphql';
import * as PageQuery from 'graphql/page.graphql';
import * as ResponseQuery from 'graphql/response.graphql';
import * as PaymentQuery from 'graphql/payment.graphql';
import { responseVar, updateOrder, updateResponse, updateSupportMessage, updateTip } from 'api/data/response';
import { updateUserCache } from 'api/data/user';
import { hasPageEnded } from 'utils/pageAvailability';
import { mapToResponseInput, numberCentsFormatter, scrollToLocation, validateEmail, generateTracking } from 'utils';
import useParticipantLoggedIn from 'hooks/useParticipantLoggedIn';
import useSessionPreferences from 'hooks/useSessionPreferences';
import { isEmpty } from 'components/RichTextEditor/utils';
import { isPageActive } from 'utils/pageAvailability';
import DonationBlockView from 'components/DonationBlock/PayerView';
import Email from 'components/Input/Email';
import FundraiserParticipant, { FUNDRAISER_BLOCK_ID } from 'components/FundraiserParticipant';
import GoalBar from 'components/GoalBar';
import GroupBlockPayerView from 'components/GroupBlock/GroupPayerView';
import Header from './Header';
import IconButton from 'components/Button/IconButton';
import Input from 'components/Input';
import PageAvailabilityMessage from 'components/Editor/PageAvailabilityMessage';
import ParticipantShareBanner from './ParticipantShareBanner';
import PaymentBlockView from 'components/PaymentBlock/PayerView';
import QuestionBlockView from 'components/QuestionBlock/PayerView';
import RichTextEditor from 'components/RichTextEditor';
import SupporterFeedView from 'components/SupporterFeedBlock/PayerView';
import TipModal from './TipModal';
import TermsPrivacy from 'components/TermsPrivacy';
import { DonationBlock } from 'components/DonationBlock/types';
import { GroupBlock } from 'components/GroupBlock/types';
import { PaymentBlock, Variant } from 'components/PaymentBlock/types';
import { QuestionBlock } from 'components/QuestionBlock/types';
import { SupporterFeedBlock } from 'components/SupporterFeedBlock/types';
import { Participant, PayerPageView, SupporterInvitationData } from 'api/data/pages/types';
import { BlockState, BlockTextState, BlockStateRequired } from 'api/data/pages/blocks/types';
import { ProcessPayment as ProcessPaymentType } from 'api/data/payment/types';
import { RequiredBlock, Response, ResponseData, ResponseInput } from 'api/data/response/types';
import { LoginQuery } from 'api/data/user/types';
import arrowDown from 'assets/arrow_down.svg';
import './style.scss';

export type PageParams = {
  id: string;
  requestId?: string;
  participantSlug?: string;
  previewOnly?: string;
};

const PayerPage: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const [location, setLocation] = useLocation();
  const [showTipModal, setShowTipModal] = useState(false);
  const [getSessionFinished, setGetSessionFinished] = useState(false);
  const [requiredBlocks, setRequiredBlocks] = useState<RequiredBlock>({});
  const [requiredChildBlocks, setRequiredChildBlocks] = useState<RequiredBlock>({});
  const [validBlocks, setValidBlocks] = useState<BlockState[]>([]);
  const [visibleBlocks, setVisibleBlocks] = useState<{ [id: string]: boolean }>({});
  const response = useReactiveVar<Response>(responseVar);

  const currentDate = dayjs();

  const {
    data: pageData,
    error,
    loading,
  } = useQuery<PayerPageView>(PageQuery.GetPayerPage, {
    variables: {
      slug: params.id,
      requestId: params.requestId,
      responseId: response.id,
      participantSlug: params.participantSlug,
    },
    fetchPolicy: 'network-only',
  });
  const [saveResponse, { loading: loadingSaveResponse }] = useMutation<ResponseData>(ResponseQuery.SaveResponse);
  const [processPaymentWithNoCharges, { loading: loadingProcessPaymentWithNoCharges }] =
    useMutation<ProcessPaymentType>(PaymentQuery.ProcessPayment);

  const [userLogin, { data: userLoginData }] = useMutation<LoginQuery>(AuthQuery.Login, { update: updateUserCache });

  const {
    p2pParticipant: pageParticipant,
    participant: participantLoggedIn,
    loading: participantLoading,
  } = useParticipantLoggedIn(params, {
    // we should not try to login before ensuring session was loaded,
    // bc they cause run condition on BE
    onCompleted: () => setGetSessionFinished(true),
    onError: () => setGetSessionFinished(true),
    skip: !!params.previewOnly,
  });

  const { data: supporterInvitationsData, loading: loadingSupporters } = useQuery<SupporterInvitationData>(
    PageQuery.GetSupporterInvitations,
    {
      variables: { pageId: params.id, participantId: participantLoggedIn?.id },
      fetchPolicy: 'network-only',
      skip: !participantLoggedIn?.id,
    },
  );
  const { data: participantsData } = useQuery<{ participants: Participant[] }>(PageQuery.GetParticipants, {
    variables: { pageId: params.id, perspective: 'payer' },
    fetchPolicy: 'network-only',
    skip: !pageData,
  });
  const { acceptInPersonPayments } = useSessionPreferences();

  useEffect(() => {
    if (participantLoggedIn && !participantLoggedIn.inviteClaimedAt) {
      setLocation(
        `/${params.id}/p/${participantLoggedIn.slug}/signup?email=${participantLoggedIn.user?.email}&id=${participantLoggedIn.id}`,
      );
      return;
    }
  }, [participantLoggedIn, participantLoading, params, setLocation]);

  useEffect(() => {
    const token = queryString.parse(window.location.search).t;
    const verifyParticipantEmail = queryString.parse(window.location.search).v;
    if (getSessionFinished && params.participantSlug && token) {
      void userLogin({
        variables: {
          participant: {
            pageSlug: params.id,
            slug: params.participantSlug,
            token: token,
            verifyParticipantEmail: !!verifyParticipantEmail,
          },
        },
      }).then(() => {
        setLocation(location);
      });
    }
  }, [location, setLocation, userLogin, params, getSessionFinished]);

  const page = pageData?.payerPage;
  const mainParticipantPage = page?.p2pEnabled && !page.participant;
  const participants = participantsData?.participants || [];
  const hasParticipants = participants?.length > 0;

  useEffect(() => {
    if (participantLoggedIn && userLoginData && !loadingSupporters) {
      const hasDonations = page?.participant?.goal && page?.participant?.goal.collectedAmount > 0;
      const hasInvites = supporterInvitationsData && supporterInvitationsData?.supporterInvitations.length > 0;

      if (!hasInvites && !hasDonations) {
        setLocation(`${location}/signup/invite-supporters`);
      }
    }
  }, [
    loadingSupporters,
    location,
    page?.participant?.goal,
    participantLoggedIn,
    setLocation,
    supporterInvitationsData,
    userLoginData,
  ]);

  useEffect(() => {
    if (page?.id) {
      updateResponse({
        pageId: page.id,
        tracking: generateTracking(),
        requestId: params.requestId,
      });
      const allBlocks = filterBlocks(page.blocks);

      const blocksReverse = allBlocks.slice().reverse();

      const emptyBlocksIds: string[] = [];
      blocksReverse.some(element => {
        const block = element as BlockTextState;
        if (!isEmpty(block.textBlock?.data, true)) return true;
        emptyBlocksIds.push(element.id);
      });

      const newBlocks = allBlocks.filter(element => !emptyBlocksIds.includes(element.id));

      setValidBlocks(newBlocks);

      if (!page.tipsEnabled || acceptInPersonPayments) {
        updateTip(0);
      }
    }
  }, [page, params.requestId, acceptInPersonPayments]);

  useEffect(() => {
    if (response.supportMessages?.length === 0) {
      const supporterFeedBlocks = page?.blocks.filter(block => block.type === 'SUPPORTER_FEED');
      supporterFeedBlocks?.forEach(block => {
        updateSupportMessage(block.id, { name: '', message: '' });
      });
    }
  }, [page?.blocks, response.supportMessages]);

  useEffect(() => {
    const visible: { [id: string]: boolean } = {};
    for (let i = 0; i < validBlocks.length; i++) {
      const block = validBlocks[i];
      visible[block.id] = true;

      const isPasscode =
        block.type === 'QUESTION' &&
        (block.questionType === 'SHORT_ANSWER' || block.questionType === 'EMAIL') &&
        block.hasValidationList;

      if (isPasscode) {
        const answer = response.answers?.some(answer => answer.question.id === block.id && answer.value.length > 0);
        if (!answer) break;
      }
    }

    setVisibleBlocks(visible);
  }, [validBlocks, response.answers]);

  useEffect(() => {
    if (window.location.hash && validBlocks.length > 0) {
      const hash = window.location.hash.replace(/^#/, '');
      setLocation(location.replace(/#.*$/, ''));
      scrollToLocation(hash);
      const regexBlock = /^block-([a-zA-Z0-9-]+)\/.*$/;
      if (regexBlock.exec(hash)) {
        const blockId = hash.replace(regexBlock, '$1');
        const block = validBlocks.find(block => block.id === blockId);

        if (block?.id && !requiredBlocks[block.id]) {
          setRequiredBlocks({ [block.id]: true });
        }
      }
    }
  }, [requiredBlocks, validBlocks, location, setLocation]);

  const applyRedirect = (applyCustomRedirect: boolean, newLocation: string) => {
    if (applyCustomRedirect) {
      window.location.assign(newLocation);
    } else {
      setLocation(newLocation);
    }
  };

  const validateRequiredChildBlocks = (parentBlock: PaymentBlock): boolean => {
    return parentBlock.paymentBlock.variants.some(variant => {
      if (
        variant.blocks?.length !== 0 &&
        response.order.orderItems.some(orderItem => orderItem.variant.id === variant.id && orderItem.amount !== null)
      ) {
        const result = variant.blocks
          ?.filter(childBlock => {
            return (childBlock as PaymentBlock).paymentBlock?.required || (childBlock as QuestionBlock).required;
          })
          .some(requiredChildBlock => {
            return requiredChildBlock.type === 'QUESTION'
              ? !response.answers?.some(answer => {
                  return answer.question.id === requiredChildBlock.id && answer.value;
                })
              : !response.order.orderItems.some(item => {
                  return item.blockId === requiredChildBlock.id && item.amount !== null;
                });
          });

        setRequiredChildBlocks({ ...requiredChildBlocks, [parentBlock.id]: !!result });

        return result;
      }
    });
  };

  const handleSaveResponse = async (hasAmount: boolean) => {
    let filteredBlocks = validBlocks
      .filter(block => {
        if (block.type === 'GROUP') {
          return block.blocks.some(
            groupBlock =>
              groupBlock.required &&
              !response.answers?.some(answer => answer.question.id === groupBlock.id && answer.value),
          );
        }

        const required = (block as BlockStateRequired).required || (block as PaymentBlock).paymentBlock?.required;
        if (required) {
          return (
            (!response.order.orderItems.some(orderItem => {
              return orderItem.blockId === block.id && orderItem.amount !== null;
            }) &&
              !response.answers?.some(answer => {
                const isFileUploadBlock = block.type === 'QUESTION' && block.questionType === 'FILE_UPLOAD';
                const fileUploadHasValidAnswer = isFileUploadBlock && (answer.file || isURL(answer.value));

                return (
                  answer.question.id === block.id && ((!isFileUploadBlock && answer.value) || fileUploadHasValidAnswer)
                );
              })) ||
            (block.type === 'PAYMENT' && validateRequiredChildBlocks(block))
          );
        }
        if (block.type === 'PAYMENT') {
          return validateRequiredChildBlocks(block);
        }

        return false;
      })
      .reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: true,
        };
      }, {});

    if (mainParticipantPage && hasParticipants && !page.p2pHideParticipantList) {
      filteredBlocks = { ...filteredBlocks, [FUNDRAISER_BLOCK_ID]: true };
    }

    setRequiredBlocks(filteredBlocks);

    if (Object.keys(filteredBlocks).length === 0) {
      const newOrderItems = response.order.orderItems.filter(({ amount }) => amount !== null);

      updateResponse({ ...response, order: { ...response.order, orderItems: newOrderItems } });

      const validResponse: ResponseInput = mapToResponseInput({
        ...response,
        answers: response.answers?.filter(
          answer => answer.value && (!answer.parent || newOrderItems.some(i => i.variant.id === answer.parent?.id)),
        ),
        order: { ...response.order, orderItems: newOrderItems },
      });

      if (hasAmount) {
        validResponse.status = 'PREFILLED';
      } else {
        validResponse.user = response.user && pickKeys(response.user, 'email', 'fullName', 'zipcode');
        validResponse.status = 'SUBMITTED';
      }

      const { data } = await saveResponse({
        variables: { response: { ...validResponse, participantMembershipId: page?.participant?.id } },
      });

      const responseId = data?.saveResponse?.id;

      if (responseId) {
        let newLocation = '';
        let applyCustomRedirect = false;

        if (hasAmount) {
          newLocation = `/${page?.slug || ''}/checkout/${responseId}`;
          if (params.participantSlug) newLocation += `?participant=${params.participantSlug}`;
        } else if (page?.afterSubmissionUrl) {
          newLocation = page.afterSubmissionUrl;
          applyCustomRedirect = true;
        }

        if (!hasAmount) {
          await processPaymentWithNoCharges({
            variables: { input: { responseId, error: {} } },
          });
        }

        updateResponse({
          id: responseId,
          answers: validResponse.answers,
        });

        if (data?.saveResponse?.order) {
          updateOrder(data.saveResponse.order);
        }

        if (!hasAmount && !applyCustomRedirect) {
          newLocation = `/submissions/${data.saveResponse.id}?type=response`;
        }

        applyRedirect(applyCustomRedirect, newLocation);
      }
    } else {
      const newLocation =
        mainParticipantPage && hasParticipants ? FUNDRAISER_BLOCK_ID : `block-${Object.keys(filteredBlocks)[0]}`;

      scrollToLocation(newLocation);
    }
  };

  const handleResponseUser = (value: string, field: string) => {
    updateResponse({
      user: { ...(response.user ? response.user : { email: '', zipcode: '', fullName: '' }), [field]: value },
    });
  };

  const [usedPasscodes, setUsedPasscodes] = useState({});
  const updateUsedPasscodes = (blockId: string, passcodes: string[]) => {
    setUsedPasscodes({ ...usedPasscodes, [blockId]: passcodes });
  };

  if (loading || !response) {
    return <div>Loading...</div>;
  }

  if (error?.message === 'CANCELED_REQUEST') setLocation('/error/canceled_request');

  if (!page || error) {
    return <div data-testid="error-div">Error</div>;
  }

  const blockComponents = (block: BlockState) => ({
    DONATION: <DonationBlockView key={block.id} data={block as DonationBlock} isRequired={requiredBlocks[block.id]} />,
    GROUP: <GroupBlockPayerView key={block.id} data={block as GroupBlock} isRequired={requiredBlocks[block.id]} />,
    PAYMENT: (
      <PaymentBlockView
        key={block.id}
        data={block as PaymentBlock}
        image={(block as PaymentBlock)?.paymentBlock?.image}
        isRequired={requiredBlocks[block.id]}
        pageId={page.id}
        usedPasscodes={Object.values(usedPasscodes).flat() as string[]}
        updateUsedPasscodes={updateUsedPasscodes}
        hasRequiredChildBlocks={!!requiredChildBlocks[block.id]}
      />
    ),
    QUESTION: (
      <QuestionBlockView
        key={block.id}
        data={block as QuestionBlock}
        isRequired={requiredBlocks[block.id]}
        pageId={page.id}
        usedPasscodes={Object.values(usedPasscodes).flat() as string[]}
        updateUsedPasscodes={updateUsedPasscodes}
      />
    ),
    TEXT: (
      <RichTextEditor
        key={block.id}
        editorState={(block as BlockTextState).textBlock?.data}
        onChange={() => null}
        readOnly
      />
    ),
    SUPPORTER_FEED: (
      <SupporterFeedView
        key={block.id}
        data={block as SupporterFeedBlock}
        participantId={page.participant?.id}
        isRequired={requiredBlocks[block.id]}
      />
    ),
  });

  const orderTipPercentage = response.order.tip.percentage * 100;
  const isEmailInvalid = !!response.user?.email && !validateEmail(response.user?.email || '');

  const visibleBlocksCount = Object.keys(visibleBlocks).length;
  const allBlocksVisible = visibleBlocksCount === validBlocks.length || visibleBlocksCount === 0;

  const showContinueButton = () => {
    const { target, collectedAmount } = page.participant?.goal || {};
    const pageActive = isPageActive(currentDate, page);

    const mayCollectBeyondParticipantGoal = page.collectBeyondParticipantGoal;
    const collectedBeyondParticipantGoal = target && target > 0 && collectedAmount && collectedAmount >= target;

    return (
      pageActive &&
      (mayCollectBeyondParticipantGoal || !collectedBeyondParticipantGoal) &&
      !params.previewOnly &&
      allBlocksVisible
    );
  };

  const shouldShowParticipantBanner = !hasPageEnded(currentDate, page) && !!participantLoggedIn && !!page.participant;

  let shareURL = `/${page.slug}/share`;

  if (page.p2pEnabled) {
    shareURL = mainParticipantPage
      ? `/${page.slug}/share-participant`
      : `/${page.slug}/p/${page.participant?.slug}/share-participant`;
  }

  const templateMessage = () =>
    page.isTemplate && <div className="error-message">This is a template and cannot be submitted.</div>;

  const showParticipantDropdown =
    hasParticipants && page.p2pEnabled && (page.participant != null || !page.p2pHideParticipantList);

  return (
    <main className="payer-page">
      <TipModal showModal={showTipModal} setShowModal={setShowTipModal} order={response.order} />
      {!isEmbed() && !params.previewOnly && (
        <Header
          additionalAction={
            <div className="row justify-end">
              {!!pageParticipant && (
                <IconButton
                  className="share-button"
                  onClick={() => setLocation(`/${page.slug}/p/${pageParticipant.slug}/edit`)}
                  icon="profile_outline"
                />
              )}
              {!hasPageEnded(currentDate, page) && (
                <>
                  {!pageParticipant && page.p2pEnabled && (
                    <button className="secondary share-button" onClick={() => setLocation(`/${page.slug}/p/signup`)}>
                      Join
                    </button>
                  )}
                  {!params.requestId && (
                    <button className="primary share-button" onClick={() => setLocation(shareURL)}>
                      Share
                    </button>
                  )}
                </>
              )}
            </div>
          }
        />
      )}
      {(page.startDate || page.endDate) && <PageAvailabilityMessage page={page} currentDate={currentDate} />}
      {shouldShowParticipantBanner && (
        <ParticipantShareBanner
          pageSlug={page.slug}
          slug={participantLoggedIn.slug}
          fullName={page.participant?.fullName || ''}
        />
      )}
      <header className="images-header">
        {page.coverPictureUrl && (
          <img className="cover" src={page.coverPictureUrl} alt="page cover" data-testid="cover-picture" />
        )}
        {page.logoUrl ? (
          <div className="logo" data-testid="logo">
            <img src={page.logoUrl} alt="company logo" />
          </div>
        ) : null}
      </header>
      <section className="content" data-testid="content-section">
        <h1 id="title">{page.title === 'Untitled' ? '' : page.title}</h1>
        {showParticipantDropdown && (
          <FundraiserParticipant
            participant={page.participant}
            showRequiredMessage={requiredBlocks[FUNDRAISER_BLOCK_ID] && mainParticipantPage}
            p2pHideParticipantList={page.p2pHideParticipantList}
            participants={participants}
            previewOnly={!!params.previewOnly}
          />
        )}
        {page.goal && <GoalBar goal={page.goal} />}
        {validBlocks
          .filter(block => visibleBlocks[block.id])
          .map(block => {
            return blockComponents(block)[block.type];
          })}
        {showContinueButton() ? (
          response.order.totalCents > 0 ? (
            <div className="block summary-checkout">
              {page.tipsEnabled && !acceptInPersonPayments && (
                <div className="tip-area">
                  <span>
                    Omella is free for organizations like {page.organization?.name} and relies on the generosity of
                    people like you to operate the service.
                  </span>
                  <span className="row space-between align-center tip-selection">
                    Tip what you think is fair:
                    <span
                      role="presentation"
                      className="row space-between align-center tip-selector"
                      onClick={() => setShowTipModal(true)}>
                      ${(response.order.tip.amountCents / 100).toFixed(2)}
                      <span>
                        {`(${
                          Number.isInteger(orderTipPercentage)
                            ? orderTipPercentage
                            : isFinite(orderTipPercentage)
                              ? orderTipPercentage.toFixed(1)
                              : 0
                        }%)`}
                      </span>
                      <img src={arrowDown} alt="arrow down" />
                    </span>
                  </span>
                </div>
              )}
              <div className="row space-between">
                <span>Subtotal</span>
                <span>${numberCentsFormatter(response.order.subtotalCents + response.order.tip.amountCents)}</span>
              </div>
              <div className="horizontal-line"></div>
              <button
                type="button"
                onClick={() => void handleSaveResponse(true)}
                className="primary"
                disabled={
                  !['VERIFIED', 'RESTRICTED'].includes(page.organization?.status as string) ||
                  loadingSaveResponse ||
                  page.isTemplate
                }>
                {loadingSaveResponse ? 'Loading...' : 'Continue'}
              </button>
              {templateMessage()}
            </div>
          ) : (
            <div id="payer-page-submit-form" className="block response-opt-out">
              <Email
                onChange={({ target }) => handleResponseUser(target.value, 'email')}
                value={response.user?.email || ''}
                name="input-mail"
                onBlur={({ target }) => handleResponseUser(target.value, 'email')}
                rules={{
                  errorMessage: 'Please enter a valid email.',
                  hasError: isEmailInvalid,
                }}
              />
              <Input
                label="Full name"
                placeholder="Full name"
                onChange={({ target }) => handleResponseUser(target.value, 'fullName')}
                value={response.user?.fullName || ''}
                name="input-full-name"
              />
              <button
                disabled={
                  !response.user?.fullName ||
                  isEmailInvalid ||
                  loadingSaveResponse ||
                  loadingProcessPaymentWithNoCharges ||
                  page.isTemplate
                }
                onClick={() => void handleSaveResponse(false)}>
                {loadingSaveResponse || loadingProcessPaymentWithNoCharges ? 'Loading...' : 'Submit'}
              </button>
              {templateMessage()}
              <TermsPrivacy type="SUBMIT" />
            </div>
          )
        ) : null}
      </section>
    </main>
  );
};

const filterBlocks = (blocks: BlockState[]) => {
  return blocks.reduce((result, block) => {
    const validationStrategies = {
      TEXT: () => result.push(block),
      DONATION: () => {
        if ((block as DonationBlock).variants.some(variant => !!variant.amountCents || variant.allowAnyAmount))
          result.push(block);
      },
      PAYMENT: () => {
        const copyBlock = { ...block } as PaymentBlock;
        copyBlock.paymentBlock = { ...copyBlock.paymentBlock };
        let showBlock = false;
        copyBlock.paymentBlock.variants = copyBlock.paymentBlock.variants.map(variant => {
          if (
            variant.paymentOptions.some(
              option => option.amountCents || option.amountCents === 0 || option.allowAnyAmount,
            ) ||
            variant.blocks
          ) {
            showBlock = true;
            return {
              ...variant,
              blocks: filterBlocks(variant.blocks || []) as QuestionBlock[] | PaymentBlock[],
            };
          }
        }) as Variant[];
        if (showBlock) result.push(copyBlock);
      },
      QUESTION: () => {
        if (['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes((block as QuestionBlock).questionType)) {
          if ((block as QuestionBlock).choices.some(choice => !!choice.text) || (block as QuestionBlock).allowOther)
            result.push(block);
        } else {
          result.push(block);
        }
      },
      GROUP: () => {
        const blocksFiltered = (block as GroupBlock).blocks.filter(groupBlock => {
          if (groupBlock.type === 'QUESTION' && groupBlock.questionType === 'SINGLE_CHOICE') {
            return groupBlock.choices.some(choice => !!choice.text) || groupBlock.allowOther;
          }
          return true;
        });
        result.push({ ...(block as GroupBlock), blocks: blocksFiltered });
      },
      SUPPORTER_FEED: () => result.push(block),
    };
    validationStrategies[block.type]();
    return result;
  }, [] as BlockState[]);
};

export default PayerPage;
