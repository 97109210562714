import React, { useRef, useState, Dispatch, SetStateAction, MutableRefObject, useEffect } from 'react';
import { EditorState, DraftHandleValue, ContentState } from 'draft-js';
import createVideoPlugin, { isYoutube } from '@omella/draft-js-plugins-video';
import Editor from '../CustomEditor';
import useClickAway from 'hooks/useClickAway';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import 'components/RichTextEditor/Plugin/style.scss';
import './style.scss';

const videoPlugin = createVideoPlugin();

const removeEmptyBlocks = (editorState: EditorState): EditorState => {
  const contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();

  const nonEmptyOrAtomicBlockMap = blockMap.filter(block => {
    return !!block && (block.getText().trim().length > 0 || block.getType() === 'atomic');
  });

  const newContentState = ContentState.createFromBlockArray(nonEmptyOrAtomicBlockMap.toArray());

  return EditorState.push(editorState, newContentState, 'remove-range');
};

export const useVideo = (currentEditorState: EditorState, onChange?: (editorState: EditorState) => void) => {
  const [showEmbedMenu, setShowEmbedMenu] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [editorState, setEditorState] = useState<EditorState>();

  const resetState = () => {
    setShowEmbedMenu(false);
    setVideoUrl('');
    setEditorState(undefined);
  };
  const handlePastedText = (text: string, _html?: string, currentEditorState?: EditorState): DraftHandleValue => {
    if (isYoutube(text)) {
      setVideoUrl(text);
      setShowEmbedMenu(true);
      setEditorState(currentEditorState);
    }
    return 'not-handled';
  };

  const handleEmbedVideo = () => {
    if (editorState) {
      const editorWithVideo = videoPlugin.addVideo(editorState, { src: videoUrl });
      onChange?.(removeEmptyBlocks(editorWithVideo));
    }
    resetState();
  };

  const hasVideo = () => {
    const contentState = currentEditorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    return blockMap.some(block => {
      return !!block && block.getType() === 'atomic';
    });
  };

  return {
    handlePastedText,
    handleEmbedVideo,
    showEmbedMenu,
    setShowEmbedMenu,
    videoUrl,
    hasVideo,
  };
};

const DROPDOWN_WIDTH = 200;
const URL_HEIGHT = 23;

type AddVideoProps = {
  showEmbedMenu: boolean;
  handleEmbedVideo: () => void;
  setShowEmbedMenu: Dispatch<SetStateAction<boolean>>;
  editorRef: MutableRefObject<Editor | null> | null;
  videoUrl: string;
};

export const VideoDropdown = ({
  showEmbedMenu,
  handleEmbedVideo,
  setShowEmbedMenu,
  editorRef,
  videoUrl,
}: AddVideoProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickAway(dropdownRef, () => setShowEmbedMenu(false));
  const [position, setPosition] = useState<{ top: number; left: number } | null>(null);

  useEffect(() => {
    if (showEmbedMenu && videoUrl && editorRef?.current?.editor?.editor) {
      const selection = window.getSelection();
      const editorRect = editorRef.current.editor.editor.getBoundingClientRect();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        setPosition({
          top: rect.top - editorRect.top + URL_HEIGHT,
          left: rect.left < DROPDOWN_WIDTH ? -editorRect.left : rect.left - editorRect.left - DROPDOWN_WIDTH,
        });
      }
    }
  }, [showEmbedMenu, videoUrl, editorRef]);

  if (showEmbedMenu && position)
    return (
      <div className="dropdown" ref={dropdownRef}>
        <ul style={{ top: position.top || 0, left: position.left || 0 }}>
          <DropDownItem onClick={handleEmbedVideo} icon="video">
            Embed video
          </DropDownItem>
          <DropDownItem onClick={() => setShowEmbedMenu(false)} icon="cancel">
            Dismiss
          </DropDownItem>
        </ul>
      </div>
    );

  return null;
};

type entityData = {
  src: string;
};

export const getVideoUrl = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();

  const blocks = contentState.getBlockMap().toArray();
  for (const block of blocks) {
    let foundVideoUrl = null;
    block.findEntityRanges(
      character => {
        const entityKey = character.getEntity();
        if (entityKey !== null) {
          const entity = contentState.getEntity(entityKey);
          if (entity.getType() === 'draft-js-video-plugin-video') {
            foundVideoUrl = (entity.getData() as entityData).src;
            return true;
          }
        }
        return false;
      },
      () => {},
    );
    if (foundVideoUrl) {
      return foundVideoUrl;
    }
  }

  return '';
};

interface VideoMenuProps {
  handleDelete?: () => void;
  editorState: EditorState;
}

export const VideoMenu = ({ handleDelete, editorState }: VideoMenuProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  let videoUrl = '';
  const prepareLink = () => {
    videoUrl = getVideoUrl(editorState);
  };

  return (
    <DropDown outsideRef={dropdownRef} className="video-menu" onClick={() => prepareLink()}>
      <DropDownItem onClick={() => window.open(videoUrl, '_blank')?.focus()} icon="expand">
        View Original
      </DropDownItem>
      <DropDownItem onClick={handleDelete} icon="delete">
        Delete
      </DropDownItem>
    </DropDown>
  );
};
