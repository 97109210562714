import React, { FC, Ref, DetailedHTMLProps, InputHTMLAttributes } from 'react';
import Wrapper from './Wrapper';
import { WrapperProps } from './Wrapper';
import './style.scss';

export type TextProps = {
  inputRef?: Ref<HTMLInputElement>;
  rawInput?: boolean;
} & WrapperProps &
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Text: FC<TextProps> = ({
  name,
  label,
  className,
  hint,
  classNameHint,
  rules,
  labelTooltip,
  inputRef,
  rawInput = false,
  ...inputProps
}) => {
  const input = <input ref={inputRef} {...inputProps} className="default" type="text" name={name} id={name} />;

  if (rawInput) return input;

  return (
    <Wrapper
      label={label}
      name={name}
      className={className}
      hint={hint}
      classNameHint={classNameHint}
      rules={rules}
      labelTooltip={labelTooltip}>
      {input}
    </Wrapper>
  );
};

export default Text;
