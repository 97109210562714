import React, { FC, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import IconButton from 'components/Button/IconButton';
import Tooltip from 'components/Tooltip';
import SortableItem from '~/components/SortableItem';
import SortableList from '~/components/SortableList';
import { ChoiceBlockProps } from '../types';
import './style.scss';

const ChoicesBlock: FC<ChoiceBlockProps> = ({ addChoice, removeChoice, data, handleOnChange, focusElement }) => {
  const [focusItem, setFocusItem] = useState<number>();

  const elementsRefs = useRef<{
    [key: string]: HTMLInputElement | null;
  }>({});

  useEffect(() => {
    if (focusElement && elementsRefs && elementsRefs.current[focusElement]) {
      elementsRefs?.current[focusElement]?.focus();
    }
  }, [focusElement, elementsRefs]);

  return (
    <div className="choices-container">
      <SortableList items={data.choices} onDragEnd={updatedChoices => handleOnChange(updatedChoices, 'choices')}>
        {data.choices.map((item, i) => {
          const classNamesBtn = clsx({ 'show-item': focusItem === i });
          const classNamesInput = clsx({ 'focus-item': focusItem === i });
          return (
            <SortableItem key={item.id} id={item.id}>
              <div key={item.id} className="choice-container">
                <div className="row space-between choice-box">
                  <div className="choice-input-container">
                    {data.questionType === 'SINGLE_CHOICE' ? (
                      <input type="radio" value={item.text} disabled />
                    ) : (
                      <input type="checkbox" value={item.text} disabled />
                    )}
                    <input
                      type="text"
                      value={item.text || ''}
                      onChange={({ target }) => handleOnChange(target.value.trimStart(), 'text', i)}
                      onFocus={() => setFocusItem(i)}
                      onBlur={() => setFocusItem(undefined)}
                      placeholder="Choice"
                      className={classNamesInput}
                      ref={element => (elementsRefs.current[`question_choice_${item.id}`] = element)}
                      maxLength={1000}
                    />
                  </div>
                  <div>
                    {data.choices.length > 1 && (
                      <Tooltip title="Delete">
                        <IconButton
                          icon="close"
                          className={classNamesBtn}
                          onClick={() => {
                            removeChoice(i);
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            </SortableItem>
          );
        })}
      </SortableList>
      {data.allowOther && (
        <div className="choice-container">
          <div className="row space-between choice-box">
            <div className="choice-input-container">
              {data.questionType === 'SINGLE_CHOICE' ? (
                <input type="radio" disabled />
              ) : (
                <input type="checkbox" disabled />
              )}
              <input type="text" value="Other" disabled />
            </div>
            <div>
              {data.choices.length > 1 && (
                <Tooltip title="Delete">
                  <IconButton
                    icon="close"
                    onClick={() => {
                      handleOnChange(!data.allowOther, 'allowOther');
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="choice-btn-container">
        <button className="button-link underline" onClick={addChoice}>
          Add choice
        </button>
        {!data.allowOther && (
          <>
            {' or '}
            <button
              className="button-link"
              onClick={() => {
                handleOnChange(!data.allowOther, 'allowOther');
              }}>
              {'add "other"'}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ChoicesBlock;
