import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { questionChoicesThreshold } from 'defaults/page';
import { filterDuplicated, formatParentAnswer } from 'utils';
import { findAnswer, removeAnswers, updateAnswer } from 'api/data/response';
import { Answer, Group, Choice as ChoiceAnswer } from 'api/data/response/types';
import { QuestionBlock } from '../types';
import { Variant } from '~/components/PaymentBlock/types';
import '../style.scss';

interface SingleChoiceProps {
  data: QuestionBlock;
  group?: Group;
  readOnly?: boolean;
  groupedAnswer?: Answer;
  saveGroupedAnswer?: (value: string, choice: ChoiceAnswer) => void;
  parent?: Variant;
}

const SingleChoiceView: FC<SingleChoiceProps> = ({
  data,
  readOnly,
  group,
  groupedAnswer,
  saveGroupedAnswer,
  parent,
}: SingleChoiceProps) => {
  const choicesCount = data.choices.filter(choice => !!choice.text).length;
  const answer = groupedAnswer ? groupedAnswer : findAnswer(data.id, 'SINGLE_CHOICE');
  const [otherAnswer, setOtherAnswer] = useState<string>(answer.choice?.title === 'Other' ? answer.value : '');

  useEffect(() => {
    if (!groupedAnswer && !readOnly && data.required && !answer.value) {
      if ((choicesCount === 1 && !data.allowOther) || (choicesCount === 0 && data.allowOther)) {
        const choice = data.choices[0];

        updateAnswer(data.id, {
          value: choice.text,
          choice: {
            id: choice.id,
            title: choice.text,
          },
          question: {
            id: data.id,
            title: data.questionPrompt,
            type: data.questionType,
          },
          parent: formatParentAnswer(parent),
        });
      }
    }
  }, [data, readOnly, answer, choicesCount, groupedAnswer, parent]);

  const handleAnswerChange = (value: string, choice: ChoiceAnswer) => {
    if (group && group.id && saveGroupedAnswer) {
      saveGroupedAnswer(value, choice);
    } else {
      updateAnswer(data.id, {
        value: value,
        choice: {
          id: choice.id,
          title: choice.title,
        },
        question: {
          id: data.id,
          title: data.questionPrompt,
          type: data.questionType,
        },
        parent: formatParentAnswer(parent),
      });
    }
  };

  const selectComponent = () => {
    type OnChangeParams = {
      value: string;
      label: string;
      choice: ChoiceAnswer;
    } | null;

    const props = {
      className: 'react-select',
      classNamePrefix: 'react-select',
      isDisabled: readOnly,
      isSearchable: true,
      isClearable: true,
      name: data.id,
      value: {
        choice: answer.choice || { title: 'Other' },
        label: answer.value || 'Select...',
        value: answer.value || '',
      },
      options: data.choices.map(choice => ({
        value: choice.text,
        label: choice.text,
        choice: { id: choice.id, title: choice.text },
      })),
      onChange: (option: OnChangeParams) =>
        handleAnswerChange(option?.value || '', option?.choice || { title: 'Other' }),
    };

    return data.allowOther ? (
      <CreatableSelect {...props} formatCreateLabel={value => `Other: ${value}`} />
    ) : (
      <Select {...props} />
    );
  };

  return (
    <div className="question-choice-container">
      {choicesCount < questionChoicesThreshold ? (
        <>
          {data.choices.filter(filterDuplicated).map(
            choice =>
              choice.text && (
                <label className="choice" key={choice.id} data-id={`question_choice_${choice.id}`}>
                  <input
                    type="radio"
                    value={choice.text}
                    checked={answer.choice?.id === choice.id}
                    name={data.id}
                    className={clsx({ disabled: readOnly })}
                    onChange={() => {
                      if (!readOnly) handleAnswerChange(choice.text, { id: choice.id, title: choice.text });
                    }}
                  />
                  <span data-id={`question_choice_${choice.id}`}>{choice.text}</span>
                </label>
              ),
          )}
          {data.allowOther && (
            <label className="choice">
              <input
                type="radio"
                value={otherAnswer}
                checked={answer.choice?.title === 'Other'}
                name={data.id}
                className={clsx({ disabled: readOnly })}
                onChange={() => !readOnly && handleAnswerChange(otherAnswer, { title: 'Other' } as ChoiceAnswer)}
              />
              Other:
              <input
                type="text"
                className="focus-item"
                value={otherAnswer}
                onChange={({ target }) => {
                  const value = target.value.trimStart();
                  setOtherAnswer(value);
                  handleAnswerChange(value, { title: 'Other' } as ChoiceAnswer);
                }}
                maxLength={1000}
              />
            </label>
          )}
        </>
      ) : (
        selectComponent()
      )}
      {!groupedAnswer && !data.required && answer.choice?.title && (
        <div className="clear-container">
          <button type="button" className="button-clear" onClick={() => removeAnswers([data.id])}>
            Clear selection
          </button>
        </div>
      )}
    </div>
  );
};

export default SingleChoiceView;
