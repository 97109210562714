import React, { FC, KeyboardEvent, MouseEvent, useRef, useState } from 'react';
import { useLocation } from 'wouter';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import { useMutation } from '@apollo/client';
import { addRequest, deleteRequest, updateRequests } from 'api/data/request';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import * as RequestQuery from 'graphql/request.graphql';
import { validateEmail } from 'utils';
import DropDown from '~/components/Menu/DropDown';
import DropDownItem from '~/components/Menu/DropDownItem';
import ICONS from 'components/Icons';
import IconButton from 'components/Button/IconButton';
import Input from 'components/Input';
import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip';
import { Request, RequestStatus, Requests } from 'api/data/request/types';
import './style.scss';

interface RequestRowProps extends Request {
  index: number;
  isEmailDuplicated: boolean;
  setShowDeleteMessage: (showMessage: boolean) => void;
  onShowPreview: (index: number) => void;
  selected?: boolean;
  showActions: boolean;
  slug: string;
  status?: RequestStatus;
}

const RequestRow: FC<RequestRowProps> = ({
  id,
  fullName,
  email,
  dueDate,
  index,
  isEmailDuplicated,
  slug,
  setShowDeleteMessage,
  onShowPreview,
  selected,
  showActions,
  status,
}) => {
  const [, setLocation] = useLocation();
  const [deleteRequestMutation] = useMutation<Requests>(RequestQuery.DeleteRequest);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const refOutside = useRef<HTMLElement>(null);

  const isEmailInvalid = !!email && !validateEmail(email);

  const spaceSlug = currentSpaceSlug() || '';

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      addRequest();
    }
  };

  const handleDeleteRequest = (index: number) => {
    const requestIsFilled = email || fullName;
    if (requestIsFilled) void deleteRequestMutation({ variables: { requestId: id } });
    deleteRequest(index);
  };

  const selectRequestForPreview = (e: MouseEvent<HTMLElement>) => {
    if (fullName && email) {
      onShowPreview(index);
      // we dont want it to trigger Share page click, that closes preview
      e.stopPropagation();
    }
  };

  return (
    <div
      className={clsx('table-row', { selected })}
      onClick={e => !showActions && selectRequestForPreview(e)}
      role="presentation">
      <Modal
        handleOnCancel={() => setShowDeleteModal(false)}
        handleOnConfirm={() => {
          handleDeleteRequest(index);
          setShowDeleteMessage(true);
          setShowDeleteModal(false);
        }}
        confirmlabel="Delete"
        denyLabel="Cancel"
        header="Delete request"
        headerIcon="warning_circle"
        visible={showDeleteModal}
        className="delete-request-modal">
        Are you sure you want to delete this request?
      </Modal>
      {status !== 'UNSAVED' && <span>{ICONS['check_blue']}</span>}
      <div className="col full-name">
        <Input
          name="full-name"
          placeholder="Full name"
          value={fullName}
          onChange={({ target }) => updateRequests('fullName', target.value, index)}
          onKeyDown={handleOnKeyDown}
          maxLength={255}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </div>
      <div className="col email">
        <Input
          placeholder="email@domain.com"
          name="email"
          value={email}
          maxLength={255}
          onChange={({ target }) => updateRequests('email', target.value, index)}
          onKeyDown={handleOnKeyDown}
          rules={{
            errorMessage: 'Please enter a valid email',
            hasError: isEmailInvalid && !isEmailDuplicated,
            hasWarning: isEmailDuplicated,
            warningMessage: 'Duplicated email',
            useTooltip: true,
          }}
        />
      </div>
      <div className="col due">
        <DatePicker
          placeholderText="Due date"
          onChange={(date: Date) => updateRequests('dueDate', date, index)}
          selected={dueDate}
          className="default"
        />
      </div>

      {showActions && (
        <div className="col actions">
          <DropDown outsideRef={refOutside} openedIcon="menu" closedIcon="menu_open" className="mobile-only">
            <DropDownItem onClick={selectRequestForPreview} icon="eye" disabled={!fullName || !validateEmail(email)}>
              View
            </DropDownItem>
            <DropDownItem
              onClick={() => setLocation(`/${spaceSlug}/editor/${slug}/requests/${id}`)}
              icon="edit"
              disabled={!fullName || !validateEmail(email)}>
              Edit
            </DropDownItem>
            <DropDownItem onClick={() => setShowDeleteModal(true)} icon="delete">
              Delete
            </DropDownItem>
          </DropDown>

          <Tooltip className="desktop-only" title="Preview" color="dark">
            <IconButton
              icon="eye"
              className="preview-button action"
              disabled={!fullName || !validateEmail(email)}
              onClick={selectRequestForPreview}
            />
          </Tooltip>
          <Tooltip className="desktop-only" title="Edit" color="dark">
            <IconButton
              icon="edit"
              className="edit-button action"
              disabled={!fullName || !validateEmail(email)}
              onClick={() => setLocation(`/${spaceSlug}/editor/${slug}/requests/${id}`)}
            />
          </Tooltip>
          <Tooltip className="desktop-only" title="Delete" color="dark">
            <IconButton
              icon="delete"
              className="delete-button action"
              onClick={() => {
                setShowDeleteModal(true);
              }}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default RequestRow;
