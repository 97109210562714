import React, { FC, useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import * as RequestQuery from 'graphql/request.graphql';
import { Link, useLocation } from 'wouter';
import dayjs from 'dayjs';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import translateStatus from 'utils/requestStatusTranslator';
import IconButton from '../Button/IconButton';
import ICONS from 'components/Icons';
import BlankState from '../BlankState';
import DropDown from '../Menu/DropDown';
import DropDownItem from '../Menu/DropDownItem';
import Message from '../Message';
import Modal from '../Modal';
import Tag from '../Tag';
import Tooltip from 'components/Tooltip';
import UserLink from '../UserLink';
import { RequestData } from 'api/data/request/types';
import arrowDownIcon from 'assets/arrow_down_order.svg';
import './style.scss';

type RequestsTableProps = {
  requests: RequestData[];
  isOrgRequests?: boolean;
  pageId?: string;
  loading: boolean;
};

const RequestsTable: FC<RequestsTableProps> = ({ requests, isOrgRequests = false, pageId, loading }) => {
  const [requestToCancel, setRequestToCancel] = useState<string | null>(null);
  const [showCanceledMessage, setShowCanceledMessage] = useState(false);
  const [deleteRequest] = useMutation(RequestQuery.DeleteRequest, {
    refetchQueries: pageId
      ? [{ query: RequestQuery.PageRequests, variables: { pageId: pageId, status: ['SENT', 'FAILED', 'OVERDUE'] } }]
      : [{ query: RequestQuery.OrganizationRequests }],
  });
  const refOutside = useRef<HTMLElement>(null);
  const [, setLocation] = useLocation();

  const spaceSlug = currentSpaceSlug() || '';

  const onRequestDeleted = async (id: string) => {
    await deleteRequest({ variables: { requestId: id } });
    setShowCanceledMessage(true);
  };

  if (loading) return <span>Loading...</span>;

  return (
    <>
      <Message className="page-message" showMessage={showCanceledMessage} setShowMessage={setShowCanceledMessage}>
        Request canceled
      </Message>
      {requests.length === 0 ? (
        <div className="page-requests">
          <BlankState
            title="No Requests"
            message={isOrgRequests ? 'Open requests will appear here.' : 'Click "Share" to send a new request.'}
          />
        </div>
      ) : (
        <div className="table page-requests max-width-desktop">
          <header>
            <div className="col person">Person</div>
            <div className="col status">Status</div>
            {isOrgRequests && <div className="col">Space</div>}
            {isOrgRequests && <div className="col page">Page</div>}
            <div className="col due">
              Due <img src={arrowDownIcon} alt="arrow down" />
            </div>
            <div className="col">Created</div>
            <div className="col actions"></div>
          </header>
          <div className="table-body">
            {requests.map(request => (
              <div key={request.id} className="table-row">
                <div className="col person">
                  <UserLink
                    fullName={request.user?.fullName || ''}
                    email={request.user?.email || ''}
                    userId={request.user?.id || ''}
                    nameTag={request.recentlyAdded && <Tag type="new" label="New" />}
                  />
                  <div className="card-view">
                    {isOrgRequests && `Space: ${request.space?.name}\nPage: ${request.title}\n`}
                    Created at {dayjs(request.insertedAt).format('MMM DD, YY')} • Due to{' '}
                    {dayjs(request.dueDate).format('MMM DD, YY')}
                  </div>
                </div>
                <div className="col status">
                  <Tag
                    type={translateStatus(request) === 'overdue' ? 'negative' : 'pending'}
                    label={translateStatus(request)}
                  />
                </div>
                {isOrgRequests && <div className="col desktop-only">{request.space?.name}</div>}
                {isOrgRequests && <div className="col page desktop-only">{request.title}</div>}
                <div className="col due desktop-only">{dayjs(request.dueDate).format('MMM DD, YY')}</div>
                <div className="col desktop-only">{dayjs(request.insertedAt).format('MMM DD, YY')}</div>
                <div className="col actions">
                  <DropDown outsideRef={refOutside} openedIcon="menu" closedIcon="menu_open" className="mobile-only">
                    <DropDownItem onClick={() => setLocation(`/requests/${request.id}`)} icon="eye">
                      View
                    </DropDownItem>
                    <DropDownItem
                      onClick={() => setLocation(`/${spaceSlug}/editor/${request.pageId}/requests/${request.id}`)}
                      icon="edit">
                      Edit
                    </DropDownItem>
                    <DropDownItem onClick={() => setRequestToCancel(request.id)} icon="delete">
                      Delete
                    </DropDownItem>
                  </DropDown>
                  <Tooltip title="View" color="dark" className="desktop-only">
                    <a href={`/requests/${request.id}`} target="_blank" rel="noreferrer">
                      {ICONS['eye']}
                    </a>
                  </Tooltip>
                  <Tooltip title="Edit" color="dark" className="desktop-only">
                    <Link href={`/${spaceSlug}/editor/${request.pageId}/requests/${request.id}`} target="_blank">
                      {ICONS['edit']}
                    </Link>
                  </Tooltip>
                  <Tooltip title="Cancel request" color="dark" className="desktop-only">
                    <IconButton icon="delete" onClick={() => setRequestToCancel(request.id)} />
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Modal
        handleOnCancel={() => {
          setRequestToCancel(null);
        }}
        handleOnConfirm={() => {
          if (requestToCancel) void onRequestDeleted(requestToCancel);
          setRequestToCancel(null);
        }}
        confirmlabel="Cancel Request"
        denyLabel="Keep Request"
        header="Cancel request"
        headerIcon="warning_circle"
        visible={!!requestToCancel}
        className="cancel-request-modal">
        <p>
          Once you cancel this request, it will no longer be accessible.
          <strong> Are you sure you want to cancel it?</strong>
        </p>
      </Modal>
    </>
  );
};

export default RequestsTable;
