import React, { FC, Dispatch, SetStateAction } from 'react';
import GooglePayButton from '@google-pay/button-react';
import { ApolloClient, NormalizedCacheObject, useApolloClient, useMutation } from '@apollo/client';
import * as ResponseQuery from 'graphql/response.graphql';
import { APP_CONFIG } from 'api/config';
import { createSetupIntent, saveResponseAndProcessPayment } from 'api/data/payment';
import { isProduction, mapToResponseInput } from 'utils';
import { PaymentMethodType, PaymentErrorType } from 'api/data/payment/types';
import { Response, ResponseData } from 'api/data/response/types';

const ENVIRONMENT = isProduction() ? 'PRODUCTION' : 'TEST';
const MERCHANT_INFO = {
  merchantId: APP_CONFIG.GOOGLE_MERCHANT_ID,
  merchantName: 'Omella',
};

const ALLOWED_PAYMENT_METHOD: google.payments.api.PaymentMethodSpecification = {
  type: 'CARD',
  parameters: {
    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    allowedCardNetworks: ['AMEX', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA'],
  },
  tokenizationSpecification: {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'finix',
      gatewayMerchantId: APP_CONFIG.FINIX_MERCHANT_ID,
    },
  },
};

interface GooglePayProps {
  setErrorMessage: (message: string) => void;
  redirectAfterPayment: (succeeded: boolean, responseId: string) => void;
  paymentProcessing: Dispatch<SetStateAction<boolean>>;
  response: Response;
  sessionKey?: string;
}

const GooglePay: FC<GooglePayProps> = ({
  paymentProcessing,
  redirectAfterPayment,
  setErrorMessage,
  response,
  sessionKey,
}) => {
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const [saveResponse] = useMutation<ResponseData>(ResponseQuery.SaveResponse);

  const getToken = async (paymentData: google.payments.api.PaymentData, token: string) => {
    paymentProcessing(true);
    setErrorMessage('');

    try {
      const user = {
        email: paymentData.email || '',
        zipcode: paymentData.shippingAddress?.postalCode || '',
        fullName: paymentData.shippingAddress?.name || '',
      };
      const newResponse = {
        ...response,
        user,
      };

      await saveResponse({
        variables: {
          response: {
            ...mapToResponseInput(response),
            user,
          },
        },
      });

      const { paymentMethodId } = await createSetupIntent(
        {
          email: user.email,
          responseId: newResponse.id || '',
          token: token,
          type: 'GOOGLE_PAY',
        },
        client,
      );

      const processPaymentData = {
        paymentMethodId,
        paymentMethodType: PaymentMethodType.CARD,
        response: newResponse,
        sessionKey,
      };

      const { succeeded } = await saveResponseAndProcessPayment(client, newResponse, processPaymentData);

      redirectAfterPayment(succeeded, newResponse.id || '');
    } catch (error) {
      if (typeof error === 'string') setErrorMessage(error);
      if ((error as PaymentErrorType)?.message) setErrorMessage((error as PaymentErrorType).message);
      paymentProcessing(false);
    }
  };

  return (
    <GooglePayButton
      environment={ENVIRONMENT}
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        emailRequired: true,
        shippingAddressRequired: true,
        allowedPaymentMethods: [ALLOWED_PAYMENT_METHOD],
        merchantInfo: MERCHANT_INFO,
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: (response.order.totalCents / 100).toFixed(2),
          currencyCode: 'USD',
          countryCode: 'US',
        },
      }}
      buttonType="plain"
      onLoadPaymentData={paymentRequest => {
        void getToken(paymentRequest, paymentRequest.paymentMethodData.tokenizationData.token);
      }}
    />
  );
};

export default GooglePay;
