import React, { FC, useEffect, useState } from 'react';
import useDebounce from '~/hooks/useDebounce';
import Input from '../Input';
import ICONS from '../Icons';

export interface SearchFieldProps {
  setSearchTerm: (a: string) => void;
}

const SearchField: FC<SearchFieldProps> = ({ setSearchTerm }) => {
  const [searchTerm, setLocalSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    setSearchTerm(debouncedSearchTerm);
  }, [debouncedSearchTerm, setSearchTerm]);

  return (
    <div className="search-filter">
      {ICONS['search']}
      <Input
        type="text"
        aria-label="search"
        name="search"
        placeholder="Search"
        value={searchTerm}
        onChange={({ target }) => {
          void setLocalSearchTerm(target.value);
        }}
      />
    </div>
  );
};

export default SearchField;
