import React, { ReactElement } from 'react';
import { closestCenter, DndContext, DragEndEvent, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { PointerSensor } from '~/utils';

interface SortableListProps<T extends { id: string | number; order: number }> {
  items: T[];
  children: ReactElement[];
  onDragEnd: (items: T[]) => void;
}

function SortableList<T extends { id: string | number; order: number }>({
  items,
  children,
  onDragEnd,
}: SortableListProps<T>) {
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 8 } }));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const oldIndex = items?.findIndex(item => item.id === active.id);
    const newIndex = items?.findIndex(item => item.id === over.id);

    const updatedItems = arrayMove(items, oldIndex, newIndex).map((item, index) => ({
      ...item,
      order: index + 1,
    }));

    onDragEnd(updatedItems);
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items.map(item => item.id)} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
}

export default SortableList;
