import React, { FC, RefObject } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import IconButton from 'components/Button/IconButton';
import SortableItem from '~/components/SortableItem';
import ToggleSwitch from 'components/ToggleSwitch';
import { generateOrder } from 'utils';
import { GroupBlock } from 'components/GroupBlock/types';
import { QuestionBlock } from 'components/QuestionBlock/types';
import { BlockState } from 'api/data/pages/blocks/types';
import './style.scss';

type SideMenuProps = {
  blocks: BlockState[];
  setBlocks: (blocks: BlockState[]) => void;
  outsideRef: RefObject<HTMLElement>;
  setFocusedElement: (idx: number) => void;
  blockIndex: number;
  groupIndex?: number;
  pageBlocks?: BlockState[];
};

const DraggerMenu: FC<SideMenuProps> = ({
  blocks,
  setBlocks,
  outsideRef,
  setFocusedElement,
  blockIndex,
  groupIndex,
  pageBlocks,
}: SideMenuProps) => {
  const createGroup = () => {
    const newBlocks = [...blocks];

    const groupId = uuidv4();
    const currentBlock = blocks[blockIndex] as QuestionBlock;
    currentBlock.order = 1;
    currentBlock.id = uuidv4();

    if (currentBlock.questionType === 'SINGLE_CHOICE') {
      currentBlock.choices.forEach(choice => (choice.id = uuidv4()));
    }

    const groupCount = blocks.filter(item => item.type === 'GROUP').length;

    const groupBlock: GroupBlock = {
      id: groupId,
      order: generateOrder(blockIndex, newBlocks),
      type: 'GROUP',
      title: 'Multiple answers group ' + String.fromCharCode(65 + groupCount),
      blocks: [currentBlock],
    };

    newBlocks.splice(blockIndex, 1, groupBlock);
    setFocusedElement(blockIndex);
    setBlocks(newBlocks);
  };

  const addToGroup = (groupId: string) => {
    const newBlocks = [...blocks];

    const group = blocks.find(item => item.id === groupId) as GroupBlock;

    if (group && group.blocks) {
      const currentBlock = blocks[blockIndex] as QuestionBlock;
      currentBlock.order = group.blocks.length + 1;
      currentBlock.id = uuidv4();

      if (currentBlock.questionType === 'SINGLE_CHOICE') {
        currentBlock.choices.forEach(choice => (choice.id = uuidv4()));
      }

      group.blocks = [...group.blocks, currentBlock];
    }

    newBlocks.splice(blockIndex, 1);
    setFocusedElement(group.order);
    setBlocks(newBlocks);
  };

  const removeFromGroup = () => {
    if (!groupIndex || !pageBlocks) return;

    const newBlocks = [...pageBlocks];

    const group = newBlocks[groupIndex] as GroupBlock;
    const block = group.blocks[blockIndex];
    block.id = uuidv4();

    if (block.questionType === 'SINGLE_CHOICE') {
      block.choices.forEach(choice => (choice.id = uuidv4()));
    }

    const newGroupBlocks = [...group.blocks];
    newGroupBlocks.splice(blockIndex, 1);
    group.blocks = newGroupBlocks;

    if (newGroupBlocks.length === 0) {
      newBlocks.splice(groupIndex, 1, block);
    } else {
      newBlocks.splice(groupIndex + 1, 0, block);
    }

    setFocusedElement(groupIndex);
    setBlocks(newBlocks);
  };

  const icon = <IconButton icon="dragger" className="dragger dropdown" />;

  if (blocks[blockIndex].type === 'TEXT') {
    return (
      // do not apply any styles to the dragger handle because it was already applied in the ContainerBlock component
      <SortableItem className="dragger" id={blocks[blockIndex].id} style={{}}>
        {icon}
      </SortableItem>
    );
  }

  if (blocks[blockIndex].type !== 'QUESTION') {
    return icon;
  }

  const canGroup =
    blocks[blockIndex].type === 'QUESTION' &&
    !(blocks[blockIndex] as QuestionBlock).hasValidationList &&
    (blocks[blockIndex] as QuestionBlock).questionType !== 'FILE_UPLOAD' &&
    (blocks[blockIndex] as QuestionBlock).questionType !== 'MULTIPLE_CHOICE' &&
    (blocks[blockIndex] as QuestionBlock).questionType !== 'SIGNATURE' &&
    (blocks[blockIndex] as QuestionBlock).questionType !== 'EMAIL';

  return (
    <DropDown closedIcon="dragger" openedIcon="dragger" outsideRef={outsideRef} className="dragger">
      {canGroup && !groupIndex && (
        <DropDownItem icon="new_group" onClick={createGroup}>
          <ToggleSwitch toggleValue={false} label={'Allow multiple answers'} handleOnChange={createGroup} />
        </DropDownItem>
      )}
      {canGroup &&
        !groupIndex &&
        blocks
          .filter(item => item.type === 'GROUP')
          .map((group, i) => (
            <DropDownItem
              key={i}
              icon=""
              onClick={() => {
                addToGroup(group.id);
              }}>
              <ToggleSwitch
                toggleValue={false}
                label={`Add to multiple answers group ${String.fromCharCode(65 + i)}`}
                handleOnChange={() => {
                  addToGroup(group.id);
                }}
              />
            </DropDownItem>
          ))}
      {canGroup && !!groupIndex && (
        <DropDownItem icon="new_group" onClick={removeFromGroup}>
          <ToggleSwitch toggleValue label={'Allow multiple answers'} handleOnChange={removeFromGroup} />
        </DropDownItem>
      )}
    </DropDown>
  );
};

export default DraggerMenu;
