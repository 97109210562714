import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { PageDataDraftBlock } from '~/api/data/pages/types';

dayjs.extend(isSameOrAfter);

export const hasPageStarted = (currentDate: Dayjs, page: PageDataDraftBlock) => {
  if (!page.startDate) return true;

  return dayjs(currentDate.toDate()).isSameOrAfter(dayjs(page.startDate));
};

export const hasPageEnded = (currentDate: Dayjs, page: PageDataDraftBlock) => {
  if (!page.endDate) return false;

  return dayjs(currentDate.toDate()).isAfter(dayjs(page.endDate));
};

export const isPageActive = (currentDate: Dayjs, page: PageDataDraftBlock) => {
  return hasPageStarted(currentDate, page) && !hasPageEnded(currentDate, page);
};

export const daysUntilPageStarts = (currentDate: Dayjs, page: PageDataDraftBlock) => {
  if (!page.startDate) return undefined;

  const formattedCurrentDate = currentDate.toDate();
  const formattedStartDate = dayjs(page.startDate).toDate();

  return Math.floor(dayjs(formattedStartDate).diff(formattedCurrentDate, 'day', true));
};

export const daysUntilPageEnds = (currentDate: Dayjs, page: PageDataDraftBlock) => {
  if (!page.endDate) return undefined;

  const formattedCurrentDate = currentDate.toDate();
  const formattedEndDate = dayjs(page.endDate).toDate();

  return Math.floor(dayjs(formattedEndDate).diff(formattedCurrentDate, 'day', true));
};
