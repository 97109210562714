import React, { FC, ReactNode, RefObject, useState } from 'react';
import { Link } from 'wouter';
import useUserSession from 'hooks/useUserSession';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import useFirstOrganization from 'hooks/useFirstOrganization';
import { isKYCd } from 'utils';
import ErrorMessage from 'components/ErrorMessage';
import IconButton from 'components/Button/IconButton';
import KYCBanner from 'components/KYCBanner';
import Gift from 'components/Animation/Gift';
import LogoLink from './LogoLink';
import OrganizationMenu from './OrganizationMenu';
import ProfileMenu from './ProfileMenu';
import ReferModal from 'components/Refer/Modal';
import SideBar from 'components/SideBar';
import Tooltip from 'components/Tooltip';

interface HeaderProps {
  children?: ReactNode;
  additionalChildren?: ReactNode;
  refOutside: RefObject<HTMLElement>;
  showSideBar?: boolean;
  displayRightElements?: boolean;
  showLoginButton?: boolean;
}

const Header: FC<HeaderProps> = ({
  children,
  additionalChildren,
  refOutside,
  showSideBar = true,
  displayRightElements = true,
  showLoginButton = true,
}) => {
  const { data, loading } = useUserSession();
  const { currentOrg, loading: orgLoading } = useCurrentOrganization();
  const { firstOrganizationData } = useFirstOrganization();
  const [showReferModal, setShowReferModal] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const organization = currentOrg || (!orgLoading && firstOrganizationData);

  const openChat = () => {
    if (window.Atlas && window.Atlas.chat?.openWindow) {
      window.Atlas.chat.openWindow();
    } else {
      setShowErrorMessage(true);
    }
  };

  return (
    <>
      {!!organization && !isKYCd(organization?.status) && <KYCBanner currentOrganization={organization} />}
      {showErrorMessage && <ErrorMessage message="Something went wrong, please reload the page" />}
      <header className="main">
        <div className="row space-between">
          <div className="logo">
            {showSideBar && !!data?.session && <SideBar />}
            <LogoLink />
            {additionalChildren}
          </div>
          {displayRightElements && (
            <div className="row align-center">
              {!!data && !!organization && (
                <div className="menu">
                  <OrganizationMenu data={data} organization={organization} refOutside={refOutside} />
                </div>
              )}
              {data?.session?.currentUser ? (
                <div className="menu">
                  <ProfileMenu data={data} refOutside={refOutside} />
                </div>
              ) : (
                !loading && showLoginButton && <Link href={`/login${window.location.search}`}>Log in</Link>
              )}
              <div className="help">
                <Tooltip title="Help" color="dark" aria-label="help">
                  <IconButton icon="help" onClick={openChat} />
                </Tooltip>
              </div>
              {!!data?.session?.currentUser && (
                <button className="button-link" onClick={() => setShowReferModal(true)} aria-label="gift" type="button">
                  <Gift />
                </button>
              )}
            </div>
          )}
        </div>
        {children}
      </header>
      <ReferModal
        showModal={showReferModal}
        setShowModal={setShowReferModal}
        userEmail={data?.session?.currentUser?.email || ''}
      />
    </>
  );
};

export default Header;
