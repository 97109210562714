import React, { FC, useRef, useState } from 'react';
import ICONS from '../Icons';
import { updateGroupBlock } from '~/api/data/pages/blocks';
import useOutsideClick from 'hooks/useOutsideClick';
import Question from '../QuestionBlock';
import DraggerMenu from '~/components/Editor/Dragger';
import SideMenu from '~/components/Editor/SideMenu';
import SortableList from 'components/SortableList';
import SortableItem from 'components/SortableItem';
import GroupBlockPayerView from './GroupPayerView';
import { BlockState } from 'api/data/pages/blocks/types';
import { QuestionBlock } from '../QuestionBlock/types';
import { GroupBlockProps } from './types';
import './style.scss';

const Group: FC<GroupBlockProps> = ({
  data,
  isFocused,
  groupIndex,
  pageBlocks,
  refOutside,
  setBlocks,
  updateBlock,
  handleDelete,
}: GroupBlockProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(isFocused);
  const [innerFocus, setInnerFocus] = useState<number | undefined>();

  const groupRef = useRef<HTMLElement>(null);

  useOutsideClick(groupRef, null, () => {
    if (isEditing) {
      setIsEditing(false);
    }
  });

  const handleUpdateBlock = (block: QuestionBlock, groupIdx?: number) => {
    const newBlock = updateGroupBlock(data, block, groupIdx) as BlockState;
    updateBlock(newBlock);
  };

  const blockComponents = (blockItem: BlockState, blockIdx: number, groupIdx: number, groupId: string) => ({
    QUESTION: (
      <Question
        key={blockItem.id}
        isFocused={innerFocus === blockIdx}
        data={blockItem as QuestionBlock}
        group={{ id: groupId }}
        refOutside={refOutside}
        updateBlock={block => handleUpdateBlock(block as QuestionBlock, blockIdx)}
        handleDelete={() => handleDelete(blockIdx)}
      />
    ),
  });

  return (
    <section
      className="block-container"
      role="presentation"
      ref={groupRef}
      onClick={ev => {
        ev.stopPropagation();

        if (!isEditing) {
          setIsEditing(true);
          setInnerFocus(data.blocks.length - 1);
        }
      }}>
      {isEditing ? (
        <div className="block group">
          <div className="block-header group-header row space-between">
            <div className="row align-center">
              {ICONS['group_icon']}
              <span className="size-xxs">{data.title || 'Multiple answers group'}</span>
            </div>
          </div>
          <div>
            <SortableList
              items={data.blocks}
              onDragEnd={updatedBlocks => updateBlock({ ...data, blocks: updatedBlocks })}>
              {data.blocks.map((blockItem, blockIndex) => {
                const components = blockComponents(blockItem, blockIndex, groupIndex, data.id);
                return (
                  <SortableItem key={blockItem.id} id={blockItem.id}>
                    <section className={`container ${blockItem.type.toLowerCase()} group-child`}>
                      <SideMenu
                        blocks={pageBlocks}
                        blockIndex={blockIndex}
                        setFocusedElement={setInnerFocus}
                        setBlocks={setBlocks}
                        outsideRef={refOutside}
                        groupIndex={groupIndex}
                      />
                      <DraggerMenu
                        blocks={data.blocks}
                        blockIndex={blockIndex}
                        setFocusedElement={setInnerFocus}
                        setBlocks={setBlocks}
                        outsideRef={refOutside}
                        groupIndex={groupIndex}
                        pageBlocks={pageBlocks}
                      />
                      {components[blockItem.type]}
                    </section>
                  </SortableItem>
                );
              })}
            </SortableList>
          </div>
        </div>
      ) : (
        <GroupBlockPayerView data={data} readOnly />
      )}
    </section>
  );
};
export default Group;
