import { useEffect, useState } from 'react';
import { isProduction } from '~/utils';

export const env = isProduction() ? 'live' : 'sandbox';

function useFinixAuth(providerId?: string): { sessionKey?: string } {
  const [sessionKey, setSessionKey] = useState('');

  useEffect(() => {
    if (providerId) {
      window.Finix?.Auth(env, providerId || '', sessionKey => {
        setSessionKey(sessionKey);
      });
    }
  }, [providerId]);

  return { sessionKey };
}

export default useFinixAuth;
