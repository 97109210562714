import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { convertToRaw } from 'draft-js';
import * as PageQuery from 'graphql/page.graphql';
import useUserSession from 'hooks/useUserSession';
import { mapPage } from 'api/data/pages';
import { defaultBlocksTitle } from 'defaults/page';
import { pageVar } from 'api/data/pages';
import ChooseSpaceModal from 'components/ChooseSpaceModal';
import SavePageModal from './SavePageModal';
import { PageDataDraftBlock, SavePage } from 'api/data/pages/types';
import { BlockState } from 'api/data/pages/blocks/types';

interface AddPageModalProps {
  page: PageDataDraftBlock;
}

export const mapBlockItem = (blocks: BlockState[]) => {
  return blocks.map((item, i) => {
    if (item.type === 'TEXT' && item.textBlock?.data) {
      return {
        id: uuidv4(),
        type: item.type,
        order: i,
        textBlock: {
          id: uuidv4(),
          data: JSON.stringify(convertToRaw(item.textBlock.data.getCurrentContent())),
        },
      };
    }

    if (item.type === 'GROUP') {
      const newBlocks = item.blocks.map(block => {
        const choices = block.choices.map((choice, i) => {
          return {
            text: choice.text,
            id: uuidv4(),
            order: i,
          };
        });

        return block.questionPrompt && block.questionPrompt.trim()
          ? { ...block, id: uuidv4(), choices }
          : { ...block, questionPrompt: defaultBlocksTitle[block.questionType], id: uuidv4(), choices };
      });

      return {
        id: uuidv4(),
        type: item.type,
        order: i,
        blocks: newBlocks,
      };
    }

    if (item.type === 'PAYMENT') {
      return {
        ...item,
        id: uuidv4(),
        order: i,
        paymentBlock: {
          ...item.paymentBlock,
          id: uuidv4(),
          description:
            item.paymentBlock.description && item.paymentBlock.description.trim()
              ? item.paymentBlock.description
              : defaultBlocksTitle[item.type],
          variants: item.paymentBlock.variants.map(variant => {
            return {
              ...variant,
              id: uuidv4(),
              inventory: {
                ...variant.inventory,
                id: uuidv4(),
              },
              paymentOptions: variant.paymentOptions.map(option => ({
                ...option,
                id: uuidv4(),
              })),
            };
          }),
        },
      };
    }

    if (item.type === 'DONATION') {
      return {
        ...item,
        id: uuidv4(),
        order: i,
        description: item.description && item.description.trim() ? item.description : defaultBlocksTitle[item.type],
        variants: item.variants.map(variant => {
          return {
            ...variant,
            id: uuidv4(),
          };
        }),
      };
    }

    if (item.type === 'QUESTION') {
      return {
        ...item,
        id: uuidv4(),
        order: i,
        questionPrompt:
          item.questionPrompt && item.questionPrompt.trim()
            ? item.questionPrompt
            : defaultBlocksTitle[item.questionType],
        description: item.description?.trim(),
        choices: item.choices.map(choice => {
          return {
            ...choice,
            id: uuidv4(),
          };
        }),
      };
    }

    if (item.type === 'SUPPORTER_FEED') {
      return {
        id: uuidv4(),
        type: item.type,
        order: i,
        required: item.required,
        title: item.title.trim() ? item.title : defaultBlocksTitle[item.type],
      };
    }
  });
};

export const useSavePageRedirect = () => {
  const [savePage] = useMutation<SavePage>(PageQuery.SavePage);
  const page = pageVar();

  const savePageAndRedirect = async (spaceSlug: string, pageParam?: PageDataDraftBlock) => {
    const { data } = await savePage({
      variables: {
        page: {
          ...(pageParam || page),
          id: undefined,
        },
        currentSpace: spaceSlug,
      },
    });

    location.assign(`/${spaceSlug}/pages/${data?.savePage.slug}/edit?pageAdded=true`);
  };
  return [savePageAndRedirect];
};

const AddPageModal: FC<AddPageModalProps> = ({ page }) => {
  const { data } = useUserSession();
  const [showModal, setShowModal] = useState(false);
  const [savePageAndRedirect] = useSavePageRedirect();
  const orgs = data?.session.managedOrganizations;

  const handleOnSave = async (spaceSlug: string) => {
    const newPage = {
      ...mapPage(page),
      blocks: mapBlockItem(page.blocks),
      id: '',
      sourcePageId: page.id,
    } as PageDataDraftBlock;

    await savePageAndRedirect(spaceSlug, newPage);
  };

  const onSaveLoggedUser = () => {
    if (orgs?.length === 1 && orgs[0].spacesCount === 1) {
      const spaceSlug = orgs[0].firstSpace?.slug || '';
      void handleOnSave(spaceSlug);
    } else {
      setShowModal(true);
    }
  };

  if ((data?.session.managedOrganizations?.length || 0) > 0)
    return (
      <>
        <ChooseSpaceModal
          onSpaceChosen={spaceId => void handleOnSave(spaceId)}
          showModal={showModal}
          setShowModal={setShowModal}
          header="Add page to..."
          confirmButtonLabel="Add"
          denyLabel="Later"
        />
        <button onClick={onSaveLoggedUser}>Save</button>
      </>
    );

  return <SavePageModal page={page} />;
};

export default AddPageModal;
