import useUserSession from './useUserSession';

const useSessionPreferences = () => {
  const { data: userSession } = useUserSession();
  return (
    userSession?.session.sessionPreferences || {
      acceptInPersonPayments: false,
      acceptCashAndCheckPayments: false,
    }
  );
};

export default useSessionPreferences;
