import React, { FC } from 'react';
import ICONS from '../Icons';
import './style.scss';

export type AlertType = 'informative' | 'negative' | 'warning' | 'new' | 'positive' | 'success' | 'danger' | 'pending';

type AlertProps = {
  type: AlertType;
  icon?: string;
  content: string | JSX.Element;
  title?: JSX.Element;
  footer?: JSX.Element;
};

const Alert: FC<AlertProps> = ({ type, icon, content, title, footer }) => {
  return (
    <span className={`row justify-center direction-column alert ${type}`}>
      {title && <span className="row align-center subtitle-x-small">{title}</span>}
      <span className="row align-center paragraph-xx-small">
        {icon && ICONS[icon]} {content}
      </span>
      {footer && <span className="row align-center subtitle-xx-small">{footer}</span>}
    </span>
  );
};

export default Alert;
