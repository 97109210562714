import { ReactiveVar, makeVar } from '@apollo/client';
import {
  CreatePaymentMethodCardData,
  PaymentMethod,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeCardNumberElement,
  SetupIntentResult,
  StripeElements,
} from '@stripe/stripe-js';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { ApolloClient, NormalizedCacheObject, ApolloError } from '@apollo/client';
import { apolloErrorFormatter } from 'utils';
import { Card, User } from 'api/data/response/types';
import { PaymentMethodType, SetupIntentInput } from 'api/data/payment/types';
import { createSetupIntent } from 'api/data/payment';
import { PaymentMethodDetails, UsBankAccount } from './types';

type StripeCreateToken = {
  card: Card;
};

interface SetupIntentDataType {
  setupIntentResult?: SetupIntentResult;
  paymentMethodDetails: PaymentMethodDetails;
}

const defaultSetupIntentData: SetupIntentDataType = {
  paymentMethodDetails: { clientSecret: '', customerId: '', paymentMethodId: '', financialConnectionsAccountId: '' },
};

const setupIntentDataVar: ReactiveVar<SetupIntentDataType> = makeVar<SetupIntentDataType>(defaultSetupIntentData);

const updateStripeData = (partialStripeData: Partial<SetupIntentDataType>): void => {
  const stripeData = setupIntentDataVar();
  setupIntentDataVar({ ...stripeData, ...partialStripeData });
};

const stripeCreateToken = async (
  stripe: Stripe,
  cardElement: StripeCardNumberElement,
  userFullName = '',
): Promise<StripeCreateToken | Record<string, unknown>> => {
  const { token } = await stripe.createToken(cardElement);

  if (token?.card && token.card.cvc_check === 'fail' && token.card.address_zip_check === 'fail') {
    throw new Error('Please check your card details and zip code or use a different card.');
  }

  if (token?.card && token.card.cvc_check !== 'fail' && token.card.address_zip_check !== 'fail') {
    const { card } = token;
    return {
      card: {
        last4: card.last4,
        brand: card.brand,
        exp_month: card.exp_month,
        exp_year: card.exp_year,
        holder: userFullName,
      },
    };
  }

  return { card: {} };
};

const stripeConfirmCardSetup = async (
  stripe: Stripe,
  clientSecret: string,
  createPaymentMethodCardData: string | Omit<CreatePaymentMethodCardData, 'type'>,
): Promise<SetupIntentResult> => {
  return await stripe.confirmCardSetup(clientSecret, { payment_method: createPaymentMethodCardData });
};

const stripeCollectBankAccountForSetup = async (stripe: Stripe, clientSecret: string, user?: User) => {
  return await stripe.collectBankAccountForSetup({
    clientSecret: clientSecret,
    params: {
      payment_method_type: 'us_bank_account',
      payment_method_data: {
        billing_details: {
          name: user?.fullName,
          email: user?.email,
        },
      },
    },
    expand: ['payment_method'],
  });
};

const stripeConfirmUsBankAccountSetup = async (stripe: Stripe, clientSecret: string) => {
  return await stripe.confirmUsBankAccountSetup(clientSecret);
};

const processACHSetupIntent = async (
  stripe: Stripe | null,
  client: ApolloClient<NormalizedCacheObject>,
  user?: User,
  responseId?: string,
) => {
  if (!stripe) {
    throw new Error('Something did not work, please reload your screen and try again');
  }

  const setupIntentInput = {
    email: user?.email || '',
    responseId: responseId || '',
    card: {},
  };

  try {
    const { customerId, clientSecret } = await createSetupIntent(setupIntentInput, client);

    let setupIntentResult = await stripeCollectBankAccountForSetup(stripe, clientSecret, user);

    if (setupIntentResult?.error) {
      throw new Error(setupIntentResult.error.message);
    }

    const paymentMethod = setupIntentResult.setupIntent?.payment_method as PaymentMethod;
    const bankAccount = paymentMethod?.us_bank_account as UsBankAccount;

    const paymentMethodDetails = {
      clientSecret: clientSecret || '',
      customerId: customerId || '',
      paymentMethodId: paymentMethod?.id || '',
      financialConnectionsAccountId: bankAccount?.financial_connections_account || '',
    };

    if (setupIntentResult.setupIntent?.status === 'requires_confirmation') {
      setupIntentResult = await stripeConfirmUsBankAccountSetup(stripe, clientSecret);
    }

    updateStripeData({
      paymentMethodDetails,
      setupIntentResult,
    });

    return { bankAccount };
  } catch (error) {
    throw new Error(apolloErrorFormatter(error as ApolloError));
  }
};

const prepareSetupIntent = async <T extends PaymentMethodType.CARD | PaymentMethodType.WALLET>(
  method: T,
  stripe: Stripe,
  paymentMethodData: CardOrWallet<T>,
  user?: User,
  responseId?: string,
) => {
  if (method === PaymentMethodType.WALLET) {
    const paymentMethodDataLocal = paymentMethodData as PaymentRequestPaymentMethodEvent;
    const setupIntentInput: SetupIntentInput = {
      email: paymentMethodDataLocal.payerEmail || '',
      responseId: responseId || '',
      card: {
        last4: paymentMethodDataLocal.paymentMethod.card?.last4,
        brand: paymentMethodDataLocal.paymentMethod.card?.brand,
        exp_month: paymentMethodDataLocal.paymentMethod.card?.exp_month,
        exp_year: paymentMethodDataLocal.paymentMethod.card?.exp_year,
        holder: paymentMethodDataLocal.paymentMethod.billing_details.name || undefined,
      },
    };
    return { setupIntentInput, defaultPaymentMethodId: paymentMethodDataLocal.paymentMethod.id };
  }

  const cardElement = (paymentMethodData as StripeElements)?.getElement(CardNumberElement);
  if (!paymentMethodData || !cardElement) {
    throw new Error('Something did not work, please reload your screen and try again');
  }

  const cardInformation = await stripeCreateToken(stripe, cardElement, user?.fullName);
  const setupIntentInput: SetupIntentInput = {
    email: user?.email || '',
    responseId: responseId || '',
    ...cardInformation,
  };
  return {
    setupIntentInput,
    defaultPaymentMethodId: {
      card: cardElement,
      billing_details: {
        name: user?.fullName,
        email: user?.email,
        address: {
          postal_code: user?.zipcode,
        },
      },
    },
  };
};

type CardOrWallet<T extends PaymentMethodType.CARD | PaymentMethodType.WALLET> = T extends PaymentMethodType.WALLET
  ? PaymentRequestPaymentMethodEvent
  : StripeElements | null;

const processCardWalletSetupIntent = async <T extends PaymentMethodType.CARD | PaymentMethodType.WALLET>(
  method: T,
  stripe: Stripe | null,
  client: ApolloClient<NormalizedCacheObject>,
  paymentMethodData: CardOrWallet<T>,
  user?: User,
  responseId?: string,
) => {
  if (!stripe) {
    throw new Error('Something did not work, please reload your screen and try again');
  }

  const { setupIntentInput, defaultPaymentMethodId } = await prepareSetupIntent(
    method,
    stripe,
    paymentMethodData,
    user,
    responseId,
  );
  try {
    const { paymentMethodId, customerId, clientSecret } = await createSetupIntent(setupIntentInput, client);

    if (!paymentMethodId) {
      const setupIntentResult = await stripeConfirmCardSetup(stripe, clientSecret, defaultPaymentMethodId);
      return {
        setupIntentResult,
        paymentMethodId: setupIntentResult.setupIntent?.payment_method as string,
        customerId,
      };
    }

    return { paymentMethodId, customerId };
  } catch (error) {
    throw new Error(apolloErrorFormatter(error as ApolloError));
  }
};
export { processACHSetupIntent, processCardWalletSetupIntent, setupIntentDataVar };
