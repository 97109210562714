import email from 'assets/email.svg';
import longAnswer from 'assets/long_answer.svg';
import shortAnswer from 'assets/short_answer.svg';
import signature from 'assets/signature.svg';
import singleChoice from 'assets/single_choice.svg';
import multipleChoice from 'assets/multiple_choice.svg';
import upload from 'assets/upload.svg';

export type QuestionConfig = {
  [key: string]: {
    title: string;
    icon: string;
    name: string;
  };
};

const questionConfig: QuestionConfig = {
  SINGLE_CHOICE: {
    title: 'Single choice',
    icon: singleChoice,
    name: 'Question',
  },
  MULTIPLE_CHOICE: {
    title: 'Multiple choice',
    icon: multipleChoice,
    name: 'Question',
  },
  SHORT_ANSWER: {
    title: 'Short answer',
    icon: shortAnswer,
    name: 'Question',
  },
  LONG_ANSWER: {
    title: 'Long answer',
    icon: longAnswer,
    name: 'Question',
  },
  FILE_UPLOAD: {
    title: 'File upload',
    icon: upload,
    name: 'File upload',
  },
  SIGNATURE: {
    title: 'Signature',
    icon: signature,
    name: 'Signature',
  },
  EMAIL: {
    title: 'Email',
    icon: email,
    name: 'Email',
  },
};

export default questionConfig;
