import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import * as PageQuery from 'graphql/page.graphql';
import { showPlural } from 'utils';
import { mapPage, mapBlocks } from 'api/data/pages';
import Alert from 'components/Alert';
import DatePicker from 'components/Input/DatePicker';
import IconButton from 'components/Button/IconButton';
import Modal from 'components/Modal';
import { PageDataDraftBlock } from 'api/data/pages/types';
import './style.scss';

interface AddDatesModalProps {
  page: PageDataDraftBlock;
  setPage: Dispatch<SetStateAction<PageDataDraftBlock>>;
  label?: string;
  buttonClassName?: string;
}

type DateType = Date | null;
type SetDate = (date: DateType) => void;
interface DatesFieldsProps {
  startDate: DateType;
  endDate: DateType;
  setStartDate: SetDate;
  setEndDate: SetDate;
  showError: boolean;
}

export const DatesFields: FC<DatesFieldsProps> = ({ startDate, endDate, setStartDate, setEndDate, showError }) => {
  const [startDateTime, setStartDateTime] = useState<Date | null>();

  const handleStartDateChange = (date: Date) => {
    const currentHour = startDate ? dayjs(startDate).hour() : 0;
    const currentMinute = startDate ? dayjs(startDate).minute() : 0;
    const currentSecond = startDate ? dayjs(startDate).second() : 0;

    const newStartDate =
      date !== null ? dayjs(date).hour(currentHour).minute(currentMinute).second(currentSecond).toDate() : null;

    setStartDate(newStartDate);
  };

  const fillEndDate = (selectedDate?: Date) => {
    const isInitialEndDateEmpty = endDate === null;
    const newStartDate = selectedDate || startDate;

    let newEndDate = endDate;

    if (isInitialEndDateEmpty && newStartDate !== null) {
      newEndDate = dayjs(newStartDate).add(28, 'day').toDate();
    }

    setEndDate(newEndDate);
  };

  const handleStartTimeChange = (date?: Date | null) => {
    const newStartTime = date !== null ? dayjs(date).toDate() : null;

    if (startDate === null) {
      const newStartDateWithTime = dayjs(newStartTime)
        .hour(dayjs(newStartTime).hour())
        .minute(dayjs(newStartTime).minute())
        .second(0)
        .toDate();
      setEndDate(dayjs(newStartDateWithTime).add(28, 'day').toDate());
      setStartDate(newStartDateWithTime);
    } else {
      setStartDate(
        dayjs(startDate).hour(dayjs(newStartTime).hour()).minute(dayjs(newStartTime).minute()).second(0).toDate(),
      );
    }
  };

  const endDateIsGreaterOrEqual = startDate && endDate && startDate <= endDate;
  const diffBetweenDates = Math.floor(dayjs(endDate).diff(startDate, 'day', true));

  return (
    <>
      <div className="row align-end gap-ms">
        <DatePicker
          label="Starts"
          name="start-date"
          selected={startDate}
          onChange={(date: Date) => handleStartDateChange(date)}
          onCalendarClose={() => fillEndDate()}
          placeholderText="Select..."
          pickerClass="default"
          dateFormat="MMM dd, yy"
        />
        <DatePicker
          name="start-date-time"
          selected={startDate || startDateTime}
          onChange={(date: Date) => setStartDateTime(date)}
          onCalendarClose={() => startDateTime && handleStartTimeChange(startDateTime)}
          placeholderText="Time"
          pickerClass="default"
          showTimeSelect
          timeCaption=""
          timeIntervals={15}
          showTimeSelectOnly
          dateFormat="h:mm aa"
        />
        <IconButton
          icon="close"
          onClick={() => {
            setStartDate(null);
            setStartDateTime(null);
          }}
        />
      </div>
      <div className="row align-end gap-ms">
        <DatePicker
          label="Ends"
          name="end-date"
          selected={endDate}
          onChange={(date: Date) => setEndDate(date)}
          placeholderText="Select..."
          pickerClass="default"
          dateFormat="MMM dd, yy"
        />
        <DatePicker
          name="end-date-time"
          selected={endDate}
          onChange={(date: Date) => setEndDate(date)}
          placeholderText="Time"
          pickerClass="default"
          showTimeSelect
          timeCaption=""
          timeIntervals={15}
          showTimeSelectOnly
          dateFormat="h:mm aa"
        />
        <IconButton icon="close" onClick={() => setEndDate(null)} />
      </div>
      {showError && <span className="error-message">Please choose a date that ends after the start date</span>}
      {endDateIsGreaterOrEqual && (
        <Alert
          type="informative"
          icon="calendar_solid"
          content={`Runs for ${diffBetweenDates} day${showPlural(diffBetweenDates)}`}
        />
      )}
    </>
  );
};

export const validateDates = (startDate: DateType, endDate: DateType) => {
  const endDateIsGreaterOrEqual = startDate && endDate && startDate <= endDate;
  const validDates = !startDate || !endDate || endDateIsGreaterOrEqual;

  return Boolean(validDates);
};
const AddDatesModal: FC<AddDatesModalProps> = ({ page, setPage, label = 'Add', buttonClassName }) => {
  const [showAddDatesModal, setShowAddDatesModal] = useState(false);
  const [savePage] = useMutation(PageQuery.SavePage);
  const defaultStartDate = page.startDate ? new Date(page.startDate) : null;
  const defaultEndDate = page.endDate ? new Date(page.endDate) : null;
  const [startDate, setStartDate] = useState<DateType>(defaultStartDate);
  const [endDate, setEndDate] = useState<DateType>(defaultEndDate);
  const [showError, setShowError] = useState(false);
  const validDates = validateDates(startDate, endDate);

  const handleSave = () => {
    if (validDates) {
      setPage({
        ...page,
        startDate,
        endDate,
      });

      const pageData = {
        ...mapPage(page),
        id: page.id,
        blocks: page.blocks.map(mapBlocks),
        requestId: page.requestId,
        startDate,
        endDate,
      };

      void savePage({ variables: { page: pageData } });
      setShowAddDatesModal(false);
    }
    setShowError(!validDates);
  };

  const handleOnCancel = () => {
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setShowAddDatesModal(false);
  };

  return (
    <>
      <Modal
        visible={showAddDatesModal}
        handleOnCancel={handleOnCancel}
        handleOnConfirm={handleSave}
        header={`${label} Dates`}
        headerIcon="calendar"
        confirmlabel="Save changes"
        denyLabel="Not now"
        className="add-dates-modal"
        fullScreenMobile>
        <div>When would you like to accept submissions?</div>
        <DatesFields
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          showError={showError}
        />
      </Modal>
      <IconButton icon="calendar" className={buttonClassName} onClick={() => setShowAddDatesModal(true)}>
        {`${label} Dates`}
      </IconButton>
    </>
  );
};

export default AddDatesModal;
