import React, { FC, useEffect, useState, useContext } from 'react';
import { ApolloError } from '@apollo/client';
import dayjs from 'dayjs';
import { apolloErrorFormatter, currencyFormatter, getOrderItemUnitValue } from 'utils';
import { availableAmountCents } from 'api/data/transaction';
import UserRolePerspective from 'contexts/UserRolePerspective';
import useToggle from 'hooks/useToggle';
import useUserSession from 'hooks/useUserSession';
import useRefundValues from './useRefundValues';
import ICONS from 'components/Icons';
import Message from 'components/Message';
import RefundOrderItemDetail from 'components/RefundOrderItemDetail';
import SideModal from 'components/SideModal';
import SideModalHeader from 'components/SideModal/Header';
import Tooltip from 'components/Tooltip';
import { RefundedOrderItem, Transaction } from 'api/data/transaction/types';
import { OrderItem } from 'api/data/response/types';
import './style.scss';

interface RefundModalProps {
  showButton?: boolean;
  pageId?: string;
  pageSpaceSlug?: string;
  responseId?: string;
  userId?: string;
  transaction?: Transaction;
  refundTransactions?: Transaction[];
}

const mapAvailableQty = (orderItems: OrderItem[], refundedOrderItems: RefundedOrderItem[]) => {
  let totalAmountRefunded = 0,
    totalAmount = 0;
  const refundedItemsMap = refundedOrderItems.reduce(
    (acc, refundedOrderItem) => {
      const itemId = refundedOrderItem.orderItem.id || '';
      if (!acc[itemId]) {
        acc[itemId] = 0;
      }
      acc[itemId] += refundedOrderItem.quantity;
      totalAmountRefunded += (refundedOrderItem.orderItem.unitPriceCents || 0) * refundedOrderItem.quantity;
      return acc;
    },
    {} as Record<string, number>,
  );

  const mappedOrderItems = orderItems.map(orderItem => {
    const refundedQty = refundedItemsMap[orderItem.id || ''] || 0;
    totalAmount += orderItem.amount || 0;
    return {
      ...orderItem,
      variant: {
        ...orderItem.variant,
        quantity: orderItem.variant.quantity - refundedQty,
      },
    };
  });

  return {
    orderItems: mappedOrderItems,
    totalAmount: totalAmount - totalAmountRefunded,
  };
};

const RefundModal: FC<RefundModalProps> = ({
  pageId,
  pageSpaceSlug,
  responseId,
  showButton = true,
  userId,
  transaction,
  refundTransactions,
}) => {
  const isPayerView = useContext(UserRolePerspective) === 'payer';
  const { data } = useUserSession();
  const [refund, loading, labels] = useRefundValues(
    isPayerView ? 'PAYER' : 'ORGANIZATION',
    userId,
    pageId,
    pageSpaceSlug,
    responseId,
  );
  const amountCents = availableAmountCents(transaction);
  const [refundAllItems, setRefundAllItems] = useState(false);
  const [refundError, setRefundError] = useState('');
  const [refundQtyByOrderItem, setRefundQtyByOrderItem] = useState<{ [key: string]: number }>({});
  const [showMessage, setShowMessage] = useState(false);
  const [isSettingsOpen, setToggleSettings] = useToggle();

  const orderItems = transaction?.invoice?.orderItems || [];
  const refundedOrderItems = refundTransactions?.flatMap(tx => tx.refund?.refundedOrderItems || []) || [];
  const { orderItems: orderItemsMapped, totalAmount } = mapAvailableQty(orderItems, refundedOrderItems);

  const totalToRefund = Object.entries(refundQtyByOrderItem).reduce((acc, [orderItemId, qty]) => {
    const orderItem = orderItemsMapped.find(orderItem => orderItem.id === orderItemId);
    if (orderItem) {
      acc += getOrderItemUnitValue(orderItem) * qty;
    }
    return acc;
  }, 0);

  const handleConfirmRefund = async () => {
    setRefundError('');

    try {
      const attribute = isPayerView ? 'payerRequest' : 'refund';
      const commonVariables = {
        transactionId: transaction?.id,
        requestingUserId: isPayerView ? data?.session.currentUser?.id : undefined,
      };

      await refund({
        variables: {
          [attribute]: {
            ...commonVariables,
            [labels.variableLabelInput]: JSON.stringify(refundQtyByOrderItem),
          },
        },
      });

      setToggleSettings(false);
      setShowMessage(true);
    } catch (error) {
      setRefundError(apolloErrorFormatter(error as ApolloError));
    }
  };

  const handleRefundAllItemsChange = (checked: boolean) => {
    let newItems = {};
    orderItemsMapped.forEach(item => {
      newItems = { ...newItems, [item.id as string]: checked ? item.variant.quantity : 0 };
    });

    setRefundAllItems(checked);
    setRefundQtyByOrderItem(newItems);
  };

  useEffect(() => {
    if (!isSettingsOpen) {
      setRefundAllItems(false);
      setRefundQtyByOrderItem({});
      setRefundError('');
    }
  }, [isSettingsOpen, amountCents]);

  useEffect(() => {
    if (totalAmount === totalToRefund) {
      setRefundAllItems(true);
    } else {
      setRefundAllItems(false);
    }
  }, [totalToRefund, totalAmount]);

  const showRefundButton =
    showButton && showRefund(transaction?.status?.toLowerCase() as string, amountCents, transaction?.processedAt);

  return (
    <>
      {showRefundButton && (
        <button className="button-link secondary button-size-sm" onClick={() => setToggleSettings(true)}>
          {labels.buttonText}
        </button>
      )}
      <SideModal
        header={<SideModalHeader title={labels.header} onClose={() => setToggleSettings(false)} />}
        isOpen={isSettingsOpen}
        toggle={setToggleSettings}>
        <div className="common-review-refund-modal">
          <p>{labels.content}</p>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="refund-all-items"
              checked={refundAllItems}
              onChange={({ target }) => handleRefundAllItemsChange(target.checked)}
            />
            <label htmlFor="refund-all-items" className="paragraph-xxx-small">
              Refund all items
            </label>
          </div>
          {orderItemsMapped
            .filter(orderItem => getOrderItemUnitValue(orderItem) > 0 && orderItem.variant.quantity > 0)
            .map(orderItem => {
              const { id: orderItemId } = orderItem;
              const selectedQuantity = refundQtyByOrderItem[orderItemId as string];
              const selectedAmount = getOrderItemUnitValue(orderItem) * selectedQuantity;

              return (
                <RefundOrderItemDetail
                  key={orderItemId}
                  orderItem={{ ...orderItem, amount: selectedAmount }}
                  qtyMaxAllowed={orderItem.variant.quantity}
                  refundQtyByOrderItem={refundQtyByOrderItem}
                  setRefundQtyByOrderItem={setRefundQtyByOrderItem}
                />
              );
            })}
          <div className="horizontal-line" />
          <div className="row space-between total">
            <span className="subtitle-small">Total</span>
            <span className="subtitle-small">{currencyFormatter(totalToRefund)}</span>
          </div>
          {refundError && <span className="required-field size-xxxs">{refundError}</span>}
          <button
            className="primary full-screen"
            disabled={totalToRefund === 0 || loading}
            onClick={() => void handleConfirmRefund()}>
            {loading ? 'Processing...' : labels.buttonText}
          </button>
        </div>
      </SideModal>
      <Message type="succeeded" showMessage={showMessage} setShowMessage={setShowMessage}>
        {labels.successfulMessage}
      </Message>
    </>
  );
};

export default RefundModal;

export const RefundTooltip = () => (
  <Tooltip
    className="refund-tooltip"
    color="dark"
    title="Omella does not charge organizations for refunds. If your refund exceeds the amount that your organization was paid, Omella will cover the difference.">
    {ICONS['solid_help']}
  </Tooltip>
);

export const showRefund = (status: string, amountCents: number, processedAt?: string | null) => {
  const inRefundTimeFrame = !!processedAt && dayjs().diff(processedAt, 'months') <= 3;
  return status === 'succeeded' && amountCents > 0 && inRefundTimeFrame;
};
