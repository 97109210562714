import React, { FC } from 'react';
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import clsx from 'clsx';
import Tooltip from '../Tooltip';
import './style.scss';

interface PlusMinusCounterProps {
  quantity: number;
  maxAllowed?: number | null;
  handleAddMinus?: (quantity: number) => void;
  disabled?: boolean;
  className?: string;
  decimalScale?: number;
}

const MIN_QUANTITY = 0;

const PlusMinusCounter: FC<PlusMinusCounterProps> = ({
  quantity,
  handleAddMinus,
  disabled,
  maxAllowed,
  className,
  decimalScale = 0,
}) => {
  const maxAchieved = maxAllowed ? quantity >= maxAllowed : false;

  const typedValueLimited = (target: NumberFormatValues) => {
    return parseInt(target.value || '0');
  };

  const onAddMinusClick = (quantity: number) => {
    handleAddMinus?.(quantity);
  };

  const handleIsAllowed = (floatValue: number) => {
    const isGreaterThanMax = maxAllowed ? floatValue > maxAllowed : false;
    const isAllowed = floatValue >= MIN_QUANTITY && !isGreaterThanMax;

    if (!isAllowed && maxAllowed) onAddMinusClick(maxAllowed);

    return isAllowed;
  };

  return (
    <div className={clsx('plus-minus-counter', className)}>
      <button
        onClick={() => {
          if (quantity > MIN_QUANTITY) onAddMinusClick(quantity - 1);
        }}
        disabled={disabled}>
        -
      </button>
      <NumericFormat
        className="subtitle-x-small counter"
        value={quantity}
        onValueChange={target => onAddMinusClick(typedValueLimited(target))}
        isAllowed={inputObj => handleIsAllowed(inputObj.floatValue || 0)}
        aria-label="counter"
        disabled={disabled}
        decimalScale={decimalScale}
      />
      <Tooltip title={maxAchieved && maxAllowed ? `Max ${maxAllowed}` : ''} color="dark">
        <button onClick={() => onAddMinusClick(quantity + 1)} disabled={disabled || maxAchieved}>
          +
        </button>
      </Tooltip>
    </div>
  );
};

export default PlusMinusCounter;
