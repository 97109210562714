import React, { FC } from 'react';
import ICONS from 'components/Icons';
import './style.scss';

type TimelineStatus = 'current' | 'completed' | 'upcoming';

export type TimelineSteps = {
  title: string;
  subTitle: string;
  status: TimelineStatus;
};

interface TimelineProps {
  steps: TimelineSteps[];
}

const Timeline: FC<TimelineProps> = ({ steps }) => {
  return (
    <ul className="row direction-column timeline">
      {steps.map((step, index) => (
        <li key={index} className="row timeline-item" data-state={step.status} aria-label={step.status}>
          <div className="row align-center justify-center circle">{step.status === 'completed' && ICONS['check']}</div>
          <div className="content">
            <p className="subtitle-x-small">{step.title}</p>
            <p className="subtitle-xx-small neutral">{step.subTitle}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Timeline;
