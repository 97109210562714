import React, { FC, useEffect } from 'react';
import clsx from 'clsx';
import { getOrderItems, removeOrderItem, updateOrderItemPaymentOption } from 'api/data/response';
import { filterPaymentVariantDuplicated } from 'utils';
import { defaultBlocksTitle } from 'defaults/page';
import PayerViewImage from 'components/ImageInBlock/PayerView';
import Variant from './Variant';
import { PaymentBlock, Variant as VariantType } from './types';
import './style.scss';

interface PaymentBlockViewProps {
  data: PaymentBlock;
  readOnly?: boolean;
  isRequired?: boolean;
  parent?: VariantType;
  usedPasscodes?: string[];
  updateUsedPasscodes?: (blockId: string, passcode: string[]) => void;
  pageId?: string;
  image?: string;
  hasRequiredChildBlocks?: boolean;
}

const PaymentBlockPayerView: FC<PaymentBlockViewProps> = ({
  data,
  readOnly,
  isRequired,
  parent,
  usedPasscodes,
  updateUsedPasscodes,
  pageId,
  image,
  hasRequiredChildBlocks,
}: PaymentBlockViewProps) => {
  const orderItems = getOrderItems(data.id);

  useEffect(() => {
    const hasSingleOrderItem = orderItems.length === 1 && !orderItems[0].variant.id;
    const hasSinglePaymentOption =
      data.paymentBlock.variants.length === 1 && data.paymentBlock.variants[0].paymentOptions.length === 1;
    const hasAvailableStock =
      (data.paymentBlock.variants[0].inventory.availableQuantity &&
        data.paymentBlock.variants[0].inventory.availableQuantity > 0) ||
      data.paymentBlock.variants[0].inventory.unlimited;

    if (!readOnly && data.paymentBlock.required && hasSingleOrderItem && hasSinglePaymentOption && hasAvailableStock) {
      const variant = data.paymentBlock.variants[0];
      const paymentOption = variant.paymentOptions[0];

      updateOrderItemPaymentOption(
        { ...orderItems[0], image },
        variant,
        paymentOption,
        data.paymentBlock.description,
        paymentOption.amountCents,
        data.paymentBlock.multipleChoice,
        parent,
      );
    }
  }, [data, readOnly, orderItems, parent, image]);

  const hasNullAmount = orderItems.some(orderItem => orderItem.amount === null);
  return (
    <section
      id={`block-${data.id}`}
      className={clsx('block payer-view payment', {
        'required-block': isRequired && hasNullAmount,
        inner: parent,
        nested: parent,
      })}>
      <div className={clsx('payment-block-view', { 'read-only': readOnly })}>
        {data.paymentBlock.required && (
          <span className="required-label">{isRequired && hasNullAmount ? 'This is required*' : 'Required*'}</span>
        )}
        <div>
          <h4 className="title" id={`payment_${data.id}`}>
            {data.paymentBlock.description || defaultBlocksTitle[data.type]}
          </h4>
          <PayerViewImage blockType="payment" imageUrl={data.paymentBlock.imageUrl} />
          <div>
            {data.paymentBlock.variants.filter(filterPaymentVariantDuplicated).map(variant => {
              return (
                <Variant
                  isRequired={data.paymentBlock.required && orderItems.length === 1}
                  variant={variant}
                  readOnly={readOnly}
                  key={variant.id}
                  blockId={data.id}
                  blockDescription={data.paymentBlock.description}
                  showRadioButton={data.paymentBlock.variants.length > 1 || !data.paymentBlock.required}
                  multipleChoice={data.paymentBlock.multipleChoice}
                  image={data.paymentBlock.image}
                  parent={parent}
                  usedPasscodes={usedPasscodes}
                  updateUsedPasscodes={updateUsedPasscodes}
                  pageId={pageId}
                  hasRequiredChildBlocks={hasRequiredChildBlocks}
                />
              );
            })}
          </div>
          {!data.paymentBlock.required && orderItems.some(orderItem => orderItem.variant.id) && (
            <div className="clear-container">
              <button type="button" className="button-clear" onClick={() => removeOrderItem(data.id)}>
                Clear selection
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PaymentBlockPayerView;
