import React, { FC, useCallback, Dispatch, SetStateAction } from 'react';
import { currencyFormatter } from 'utils';
import Tooltip from 'components/Tooltip';
import Table from 'components/Table';
import { SpaceSummary } from 'api/data/payout/types';
import './style.scss';

interface SummaryProps {
  data: SpaceSummary[];
  enableRowSelection?: boolean;
  setSelectedItems?: Dispatch<SetStateAction<SelectedItems>>;
  selectedItems?: SelectedItems;
  selectedTotalAmountCents?: number;
}

export type SelectedItems = {
  [id: string]: boolean;
};

const Summary: FC<SummaryProps> = ({
  data,
  enableRowSelection,
  setSelectedItems,
  selectedItems = {},
  selectedTotalAmountCents = 0,
}) => {
  const calculateTotal = useCallback(() => {
    return data.reduce((sum, space) => {
      let spaceTotal = 0;
      space.pages.forEach(page => {
        spaceTotal += page.amount_cents || 0;
      });
      return sum + spaceTotal;
    }, 0);
  }, [data]);

  const columns = [
    {
      header: 'Spaces',
      accessor: (space: SpaceSummary) => space.name,
      ellipsis: true,
    },
    {
      header: 'Amount',
      accessor: (space: SpaceSummary) =>
        space.amount_cents < 0 ? (
          <Tooltip color="dark" title="Refunds have exceeded payment amounts">
            {currencyFormatter(space.amount_cents)}
          </Tooltip>
        ) : (
          currencyFormatter(space.amount_cents)
        ),
      align: 'right' as const,
      showSortIcon: true,
    },
  ];

  return (
    <>
      <p className="subtitle-medium">Summary</p>
      <Table
        data={data}
        columns={columns}
        getRowId={item => item.id || item.name}
        getChildren={space =>
          space.pages.map(page => ({
            name: page.name,
            amount_cents: page.amount_cents,
            id: page.id,
            pages: [],
          }))
        }
        isRowSelectable={item => item.amount_cents >= 0}
        enableRowSelection={enableRowSelection}
        selectedItems={selectedItems}
        onSelectionChange={items => setSelectedItems?.(items)}
      />
      <div className="row align-center justify-end">
        <p className="text-align-right subtitle-xx-small">
          <span className="subtitle-xx-small neutral">Payout total:</span>{' '}
          {currencyFormatter(enableRowSelection ? selectedTotalAmountCents || 0 : calculateTotal())}
        </p>
      </div>
    </>
  );
};

export default Summary;
