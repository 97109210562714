const APP_CONFIG = {
  APP_SIGNAL: import.meta.env.OML_APP_SIGNAL,
  ENDPOINT: import.meta.env.OML_ENDPOINT,
  GOOGLE_ANALYTICS: import.meta.env.OML_GOOGLE_ANALYTICS,
  IMAGE_UPLOAD_ENDPOINT: import.meta.env.OML_IMAGE_UPLOAD_ENDPOINT,
  STRIPE: import.meta.env.OML_STRIPE,
  FILE_UPLOAD_ENDPOINT: import.meta.env.OML_FILE_UPLOAD_ENDPOINT,
  OMELLA_WEBSITE: import.meta.env.OML_WEBSITE,
  INTERCOM_APP_ID: import.meta.env.OML_INTERCOM_APP_ID,
  APPLE_PAY_MERCHANT_ID: import.meta.env.OML_APPLE_PAY_MERCHANT_ID,
  APPLE_PAY_SESSION_ENDPOINT: import.meta.env.OML_APPLE_PAY_SESSION_ENDPOINT,
  CHECKOUT: import.meta.env.OML_CHECKOUT,
  CHECKOUT_ENDPOINT: import.meta.env.OML_CHECKOUT_ENDPOINT,
  GOOGLE_MERCHANT_ID: import.meta.env.OML_MERCHANT_ID,
  P2P_POLL_INTERVAL: import.meta.env.OML_P2P_POLL_INTERVAL,
  WEB_SOCKET: import.meta.env.OML_WEB_SOCKET,
  FINIX: import.meta.env.OML_FINIX_APP_ID,
  FINIX_MERCHANT_ID: import.meta.env.OML_FINIX_MERCHANT_ID,
};

export { APP_CONFIG };
