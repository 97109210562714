import React, { FC, useEffect, useState } from 'react';
import { useLocation, useRoute } from 'wouter';
import { useMutation, useLazyQuery } from '@apollo/client';
import * as OrganizationApi from 'graphql/organization.graphql';
import { isKYCd } from 'utils';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import Modal from 'components/Modal';
import { Organization, StripeKYCForOrganization, OrganizationStatus } from 'api/data/user/types';
import lockIcon from 'assets/lock_white.svg';
import './style.scss';

interface ShareButtonProps {
  organizationId: string;
  organizationStatus?: OrganizationStatus;
  buttonText?: string;
  showButton?: boolean;
  className?: string;
}

export interface OrganizationStatusQueryType {
  currentOrganizationStatus: Organization;
}

const ShareButton: FC<ShareButtonProps> = ({
  organizationId,
  organizationStatus,
  className,
  showButton = true,
  buttonText = 'Share',
}: ShareButtonProps) => {
  const [matchPages] = useRoute('/:currentSpace/pages/:id');
  const [location, setLocation] = useLocation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [updateUrl, { data, loading }] = useMutation<StripeKYCForOrganization>(OrganizationApi.LinkAccount);
  const [getOrganization, { data: organization, loading: loadingOrg }] = useLazyQuery<OrganizationStatusQueryType>(
    OrganizationApi.CurrentOrganizationStatus,
    { fetchPolicy: 'network-only' },
  );

  useEffect(() => {
    if (data) {
      const url = data.createAccountLink.url;
      window.location.assign(url);
    }
  }, [data]);

  useEffect(() => {
    if (window.location.hash === '#kyc') {
      setModalOpen(true);
      setLocation(location.replace('#kyc', ''));
    }
  }, [location, setLocation]);

  useEffect(() => {
    if (organization?.currentOrganizationStatus && !loadingOrg) {
      if (isKYCd(organization.currentOrganizationStatus.status)) {
        if (matchPages) setLocation(`${location}/share`);
      } else {
        setModalOpen(true);
      }
    }
  }, [matchPages, organization?.currentOrganizationStatus, loadingOrg, location, setLocation]);

  const handleShare = () => {
    if (isKYCd(organizationStatus)) setLocation(`${location}/share`);
    else getOrganization();
  };

  const handleSetupNow = () => {
    if (organizationId) {
      let success_path = location;
      let failure_path = `/${currentSpaceSlug() || ''}/pages#error`;

      if (matchPages) {
        success_path = `${location}/share`;
        failure_path = `${location}#error`;
      }

      void updateUrl({
        variables: {
          success_path,
          failure_path,
        },
      });
    }
  };

  return (
    <>
      <Modal visible={modalOpen} handleOnCancel={() => setModalOpen(false)} className="share-button" fullScreenMobile>
        <span className="headline">LAST STEP!</span>
        <h2>Set up payouts</h2>
        <p>
          Deposit your Omella balance to your bank account just like a paycheck. Click the button below to continue.
        </p>
        <span className="kyc-message">
          * If you already completed this step, contact <a href="mailto:contact@omella.com">contact@omella.com</a>
        </span>
        <button disabled={!!(loading || data)} onClick={handleSetupNow}>
          {loading || data ? (
            'Loading...'
          ) : (
            <>
              Secure setup with Stripe <img src={lockIcon} alt="lock icon" />
            </>
          )}
        </button>
      </Modal>
      {showButton && (
        <div className="share-button">
          <button onClick={handleShare} disabled={loadingOrg} className={className}>
            {loadingOrg ? 'Loading...' : buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default ShareButton;
