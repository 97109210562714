import React, { FC, useState } from 'react';
import { currencyFormatter } from 'utils';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import BlankState from 'components/BlankState';
import Pagination from 'components/Pagination';
import ResponseRow from './ResponseRow';
import { Page, PageResponse, PaginationType } from 'api/data/response/types';
import arrowDownIcon from 'assets/arrow_down_order.svg';
import './style.scss';

export type PageResponsesProps = {
  responses: PageResponse[];
  totalCollectedCents?: number;
  page?: Page;
  showPageTitle?: boolean;
  refetch?: (offset: number, limit: number) => Promise<void>;
  loading?: boolean;
  paginationInfo?: PaginationType;
};

const PageResponses: FC<PageResponsesProps> = ({
  responses,
  totalCollectedCents,
  page,
  showPageTitle,
  refetch,
  loading,
  paginationInfo,
}) => {
  const [responseId, setResponseId] = useState<string>();
  const [quantity, setQuantity] = useState<number>(100);

  const onPaginationChange = async (offset: number, limit: number) => {
    await refetch?.(offset, limit);
  };

  const loadMoreItems = () => {
    setQuantity(prevQuantity => prevQuantity + 100);
  };

  useInfiniteScroll(loadMoreItems, !paginationInfo && quantity < responses.length);

  const responsesList = paginationInfo ? responses : responses.slice(0, quantity);

  return (
    <div className="submission-list">
      {responses.length === 0 ? (
        <BlankState title="No submissions" message={'Data will appear here.'} />
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>Respondent</th>
                {showPageTitle && <th>Page</th>}
                <th className="short-column">
                  Last update <img src={arrowDownIcon} alt="arrow down" />
                </th>
                <th className="number-column">
                  Total <span>{currencyFormatter(totalCollectedCents || page?.totalCollectedCents)}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {responsesList.map(response => (
                <ResponseRow
                  key={response.id}
                  data={response}
                  page={page}
                  setResponseId={setResponseId}
                  showDetail={responseId === response.id}
                  showPageTitle={showPageTitle}
                />
              ))}
            </tbody>
          </table>
          {paginationInfo && (
            <Pagination paginationInfo={paginationInfo} onChange={onPaginationChange} loading={loading} showTotal />
          )}
        </>
      )}
    </div>
  );
};

export default PageResponses;
