import React, { FC } from 'react';
import clsx from 'clsx';
import './style.scss';

interface SpinningCircleProps {
  overlay?: boolean;
}

const SpinningCircle: FC<SpinningCircleProps> = ({ overlay }) => {
  return (
    <div className={clsx({ overlay })}>
      <div className="spinning-circle"></div>
      <span>Loading...</span>
    </div>
  );
};

export default SpinningCircle;
