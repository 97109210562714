import React, { CSSProperties, FC, ReactElement } from 'react';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface SortableItemProps {
  id: string;
  children: ReactElement;
  listeners?: SyntheticListenerMap;
  style?: CSSProperties;
  className?: string;
}

const SortableItem: FC<SortableItemProps> = ({ id, listeners, children, style, className }) => {
  const {
    attributes,
    listeners: defaultListeners,
    setNodeRef,
    transform,
    isDragging,
    transition,
  } = useSortable({ id });

  const defaultStyle: CSSProperties = {
    position: 'relative',
    opacity: isDragging ? 0.7 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 999 : undefined,
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...(listeners || defaultListeners)}
      style={style || defaultStyle}
      className={className}>
      {children}
    </div>
  );
};

export default SortableItem;
