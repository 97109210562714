/**
 * Create an editor state with some text in it already
 */
/* eslint-disable */
import { ContentState, EditorState } from 'draft-js';

export default function createEditorStateWithText(text: string): EditorState {
  // @ts-ignore
  if (EditorState.createWithText) {
    // @ts-ignore
    return EditorState.createWithText(text);
  }
  return EditorState.createWithContent(ContentState.createFromText(text));
}
