import React, { FC, FormEvent, useEffect, useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, useLocation } from 'wouter';
import * as PageQuery from 'graphql/page.graphql';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import uploadFile from 'api/uploadClient';
import { participantNameDefaultLength } from 'defaults/user';
import { apolloErrorFormatter, extractQueryStringFromParams, validateEmail } from 'utils';
import useParticipantLoggedIn from 'hooks/useParticipantLoggedIn';
import Header from 'pages/MyPage/Header';
import Email from 'components/Input/Email';
import EyeButton from 'components/Button/EyeButton';
import Input from 'components/Input';
import LoggedPageContainer from 'components/LoggedPageContainer';
import Modal from 'components/Modal';
import ProfilePicture from './profilePicture';
import { PageData, Participant as ParticipantType } from 'api/data/pages/types';
import './style.scss';

type PageParams = {
  id: string;
  participantSlug?: string;
};

export const hasParticipantJoined = (tier: string) => {
  return tier !== 'INVITED';
};

const INAPPROPRIATE_TEXT_API_ERROR = 'text not appropriate';

const Participant: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const { data: participantData, loading } = useQuery<{ participant: ParticipantType; page: PageData }>(
    PageQuery.GetParticipant,
    {
      variables: { pageId: params.id, participantId: params.participantSlug },
      fetchPolicy: 'network-only',
    },
  );

  const [saveParticipantMutation] = useMutation(PageQuery.SaveParticipant);
  const [removeParticipantMutation] = useMutation(PageQuery.RemoveParticipant);

  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    fullName: '',
    parentEmail: '',
    secondParentEmail: '',
    picture: '',
    pictureUrl: '',
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [apiCallError, setApiCallError] = useState('');

  const isProfane = apiCallError === INAPPROPRIATE_TEXT_API_ERROR;
  const profanityCheckerErrorMessage = isProfane ? 'Please enter an appropriate name' : '';

  const [, setLocation] = useLocation();
  const spaceSlug = currentSpaceSlug() || '';

  const handleOnInputChange = (label: string, value: string) => {
    if (label === 'fullName' && isProfane) {
      setApiCallError('');
    }

    setFormData({ ...formData, [label]: value });
  };

  const signup = extractQueryStringFromParams('signup');
  useEffect(() => {
    if (participantData?.participant) {
      setFormData({
        fullName: participantData.participant.fullName || '',
        parentEmail: participantData.participant.parentEmail || '',
        secondParentEmail: participantData.participant.secondParentEmail || '',
        pictureUrl: participantData.participant.pictureUrl || '',
        picture: participantData.participant.picture || '',
      });
      setMessage(
        participantData.participant.message ||
          `I'm doing an Omella fundraiser for ${participantData?.participant?.page?.p2pOrganizationName || participantData?.participant?.page?.organization?.name} and would love your support!`,
      );
    }
  }, [participantData]);

  const saveParticipant = async (picture = formData.picture) => {
    await saveParticipantMutation({
      variables: {
        participant: {
          id: participantData?.participant.id,
          fullName: formData.fullName,
          parentEmail: formData.parentEmail,
          secondParentEmail: formData.secondParentEmail,
          message: message,
          picture,
        },
        pageId: params.id,
      },
    });
  };

  let { parentEmail, secondParentEmail } = formData;

  parentEmail = parentEmail.toLowerCase();
  secondParentEmail = secondParentEmail.toLowerCase();

  const parentEmailsAreEqual = parentEmail !== '' && parentEmail === secondParentEmail;

  const parentEmailIsEqualToParticipantEmail =
    parentEmail === participantData?.participant.user.email ||
    secondParentEmail === participantData?.participant.user.email;

  const invalidEmail = (content: string) => !!content && !validateEmail(content);

  const parentEmailIsInvalid =
    invalidEmail(parentEmail) ||
    invalidEmail(secondParentEmail) ||
    parentEmailsAreEqual ||
    parentEmailIsEqualToParticipantEmail;

  const formIsInvalid = !formData.fullName.trim() || !message.trim() || parentEmailIsInvalid;

  const handleOnUploadImage = async (file: File, attr: string) => {
    const uploadResponse = await uploadFile(file, 'IMAGE', attr, undefined, undefined, 'true');
    setFormData({ ...formData, [attr]: uploadResponse.file_handle, [`${attr}Url`]: uploadResponse.url });

    if (!formIsInvalid) await saveParticipant(uploadResponse.file_handle);
  };

  const removeImage = async (attr: string) => {
    setFormData({ ...formData, [attr]: '', [`${attr}Url`]: '' });
    if (!formIsInvalid) await saveParticipant('');
  };

  const fieldRules = {
    required: true,
    errorMessage: profanityCheckerErrorMessage || 'This is required',
  };

  const { logged: loggedAsParticipant } = useParticipantLoggedIn(params);

  const teamUrl = `/${spaceSlug}/pages/${params.id}/team`;
  const participantUrl = `/${params.id}/p/${params.participantSlug}`;
  let link = teamUrl;

  if (loggedAsParticipant) {
    link = signup ? `/${params.id}/p/${params.participantSlug}/signup/invite-supporters` : participantUrl;
  }

  const handleSaveParticipant = async (event: FormEvent<HTMLFormElement>) => {
    try {
      setApiCallError('');
      event.preventDefault();
      await saveParticipant();
      setLocation(link);
    } catch (error) {
      setApiCallError(apolloErrorFormatter(error as ApolloError));
    }
  };

  const removeParticipant = async () => {
    try {
      await removeParticipantMutation({ variables: { id: participantData?.participant.id } });
      setLocation(`${teamUrl}?success=true`);
    } catch {
      setLocation(`${teamUrl}?error=true`);
    }
  };

  if (loading) return <div>Loading...</div>;

  const emailErrors = (val: string, fieldName: string) => {
    const possibleErrors = [
      {
        errorMessage: 'Please enter a valid email.',
        hasError: invalidEmail(val),
      },
      {
        errorMessage: 'Parent emails cannot be the same.',
        hasError: !!val && val.toLowerCase() === (fieldName === 'parentEmail' ? secondParentEmail : parentEmail),
      },
      {
        errorMessage: 'Parent email cannot match participant email.',
        hasError: !!val && val.toLowerCase() === participantData?.participant.user.email,
      },
    ];

    return possibleErrors.find(({ hasError }) => hasError);
  };

  return (
    <LoggedPageContainer
      className="edit-participant"
      header={
        <Header
          title="Edit Participant"
          linkBack={signup ? '' : link}
          showLogo={false}
          actions={
            !!participantData?.participant.inviteClaimedAt && <EyeButton url={participantUrl} desktopOnly={false} />
          }
        />
      }>
      <main className="participant">
        <form onSubmit={event => void handleSaveParticipant(event)}>
          <ProfilePicture
            name="picture"
            pictureUrl={formData.pictureUrl}
            handleOnUploadImage={handleOnUploadImage}
            removeImage={(attr: string) => void removeImage(attr)}
          />
          <Input
            label="Full name"
            placeholder="Name"
            onChange={({ target }) => handleOnInputChange('fullName', target.value)}
            value={formData.fullName}
            name="participant-name-input"
            rules={{
              ...fieldRules,
              hasError: !formData.fullName || isProfane,
            }}
            onBlur={({ target }) => setFormData({ ...formData, fullName: target.value.trim() })}
            hint="This name will be shown on your page"
            maxLength={participantNameDefaultLength}
          />
          <Email
            className="parent-email-container"
            label="Parent/Guardian email"
            onChange={({ target }) => handleOnInputChange('parentEmail', target.value)}
            onBlur={({ target }) => setFormData({ ...formData, parentEmail: target.value })}
            value={formData.parentEmail}
            name="parent-email-input"
            rules={emailErrors(formData.parentEmail, 'parentEmail')}
          />
          <Email
            className="parent-email-container"
            label="Second parent/guardian email"
            onChange={({ target }) => handleOnInputChange('secondParentEmail', target.value)}
            onBlur={({ target }) => setFormData({ ...formData, secondParentEmail: target.value })}
            value={formData.secondParentEmail}
            name="second-parent-email-input"
            rules={emailErrors(formData.secondParentEmail, 'secondParentEmail')}
          />
          <footer className="actions">
            {loggedAsParticipant ? (
              <button type="button" className="secondary" onClick={() => setLocation(link)}>
                Not now
              </button>
            ) : (
              <button type="button" className="secondary" onClick={() => setShowDeleteModal(true)}>
                Remove
              </button>
            )}
            <button type="submit" className="primary" disabled={formIsInvalid}>
              Save
            </button>
          </footer>
        </form>
      </main>
      <Modal
        handleOnCancel={() => setShowDeleteModal(false)}
        handleOnConfirm={() => void removeParticipant()}
        confirmlabel="Remove"
        denyLabel="Not now"
        visible={showDeleteModal}
        header=" Remove participant">
        Are you sure you want to remove this participant?
      </Modal>
    </LoggedPageContainer>
  );
};

export default Participant;
