const iframeCode = (slug: string): string => `
<div style="width: 100%;">
  <iframe
    src="${window.location.origin}/${slug}"
    allowpaymentrequest
    allow="payment"
    style="width: calc(100% - 10px); height: 100%; border: 0px"
    onload="handleOnload()"
  >
  </iframe>

  <script src="${window.location.origin}/iframe-code.js"></script>
  <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.6.2/iframeResizer.min.js"></script>
</div>
`;

export default iframeCode;
