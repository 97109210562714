import React, { FC, useRef } from 'react';
import { useQuery } from '@apollo/client';
import * as OrganizationQuery from 'graphql/organization.graphql';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import Wrapper from 'components/Input/Wrapper';
import { OrganizationMembership } from 'api/data/user/types';
import './style.scss';

type RecipientDropDownProps = {
  selectedRecipientEmail: string;
  onChange: (id: string) => void;
};

const RecipientDropDown: FC<RecipientDropDownProps> = ({ selectedRecipientEmail, onChange }) => {
  const { data } = useQuery<OrganizationMembership>(OrganizationQuery.GetMembershipsFromOrganization, {
    fetchPolicy: 'network-only',
  });
  const extRef = useRef<HTMLDivElement>(null);

  const recipients = data?.organization.memberships?.filter(member => member.role === 'OWNER') || [];
  const selectedRecipient = recipients.find(item => item.user.email === selectedRecipientEmail);

  return (
    <Wrapper
      name="recipient-dropdown"
      label="Recipient"
      rules={{ required: true }}
      labelTooltip="This person will receive an email that will allow them to download, print or mail a check.">
      <DropDown
        htmlId="recipient-dropdown"
        outsideRef={extRef}
        openedIcon="arrow_up"
        closedIcon="arrow_down"
        iconSide="RIGHT"
        buttonChildren={
          <span className="size-xxs ellipsis-text">
            {selectedRecipient
              ? `${selectedRecipient.user.fullName} (${selectedRecipient.user.email})`
              : 'Select Recipient'}
          </span>
        }>
        {recipients.map(recipient => (
          <DropDownItem key={recipient.id} onClick={() => void onChange(recipient.user.email)}>
            <span className="size-xxs">{recipient.user.fullName}</span> <br />
            <span className="size-xxxs">({recipient.user.email})</span>
          </DropDownItem>
        ))}
      </DropDown>
    </Wrapper>
  );
};

export default RecipientDropDown;
