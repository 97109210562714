import React, { Fragment, useEffect, useRef, useState, isValidElement } from 'react';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import useWindowSize from 'hooks/useWindowSize';
import EmptyColumns from './EmptyColumns';
import BlankState from 'components/BlankState';
import IconButton from 'components/Button/IconButton';
import ICONS from 'components/Icons';
import Pagination from 'components/Pagination';
import SpinningCircle from '../SpinningCircle';
import Tooltip, { TooltipEllipsis } from 'components/Tooltip';
import { ObjectConfig, ReportTableProps } from './types';
import './style.scss';

function ReportTable<T>({
  tableConfig,
  tableData,
  setSort,
  currentSort,
  loading,
  getItemId,
  blankState,
  paginationInfo,
  onPaginationChange,
}: ReportTableProps<T>) {
  const [allRowsAreExpanded, setAllRowsAreExpanded] = useState(false);
  const [expandedRows, setExpandedRows] = useState<{ [id: string]: boolean }>({});
  const [showEmptyColumn, setShowEmptyColumn] = useState<boolean>();
  const tableHeaderRef = useRef<HTMLTableSectionElement>(null);
  const { clientWidth } = useWindowSize();

  useEffect(() => {
    const tableWidth = tableHeaderRef?.current?.clientWidth;
    const updatedShowEmptyColumn = (tableWidth && tableWidth < clientWidth) || false;

    setShowEmptyColumn(updatedShowEmptyColumn);
  }, [clientWidth, tableConfig]);

  const columns =
    Object.keys(expandedRows).length > 0
      ? tableConfig.ungroupedColumns
      : tableConfig.ungroupedColumns.filter(column => !column.isSubData);

  const handleExpandRow = (datum: T) => {
    const itemId = getItemId(datum);

    const newRows = { ...expandedRows, [itemId]: !expandedRows[itemId] };
    const hasExpandedRow = Object.values(newRows).includes(true);
    setExpandedRows(hasExpandedRow ? newRows : {});
  };

  const handleExpandAllRows = () => {
    if (allRowsAreExpanded) {
      setExpandedRows({});
      setAllRowsAreExpanded(false);
      return;
    }

    const allRowIndexes = tableData?.reduce((acc, data) => ({ ...acc, [getItemId(data)]: true }), {}) || {};
    setExpandedRows(allRowIndexes);
    setAllRowsAreExpanded(true);
  };

  const columnsFiltered = columns.filter(column => column.show);
  const groupedColumnsFiltered = tableConfig.groupedColumns?.filter(item => item.show);

  const groupedColumnsLength =
    groupedColumnsFiltered?.reduce((total, group) => total + group.columns.filter(column => column.show).length, 0) ||
    0;

  const completeTableWithEmptyColumn = () => {
    if (showEmptyColumn) {
      return <td className="dynamic-empty-column"></td>;
    }
  };

  if (!loading && tableData?.length === 0) {
    if (isValidElement(blankState)) {
      return blankState;
    }

    return <BlankState title="Please select a page" message="Data will appear here." />;
  }

  return (
    <div className="report-container">
      {loading && (
        <div className="report-table-overlay">
          <SpinningCircle />
        </div>
      )}
      <table className="report-table">
        <thead className="report-header" ref={tableHeaderRef}>
          <tr>
            {columnsFiltered.map((column, index) => (
              <th key={`column-${column.id}`} rowSpan={2}>
                <div>
                  {index === 0 && (
                    <Tooltip color="dark" title={allRowsAreExpanded ? 'Collapse All' : 'Expand All'}>
                      <IconButton
                        icon={allRowsAreExpanded ? 'arrow_up' : 'arrow_right'}
                        onClick={handleExpandAllRows}
                      />
                    </Tooltip>
                  )}
                  {column.isSortable ? (
                    <button
                      className={clsx('icon-button column-title', { 'is-number': column.type === 'number' })}
                      onClick={() => {
                        setSort({
                          column: column.fieldName,
                          direction: currentSort.direction === 'desc' ? 'asc' : 'desc',
                        });
                      }}
                      disabled={loading}>
                      <Tooltip color="dark" title={loading ? 'loading...' : ''}>
                        <span className="label">{column.label}</span>
                        {column.fieldName === currentSort.column && (
                          <span data-testid="sorting-arrow">
                            {currentSort.direction === 'desc' ? ICONS['arrow_slim_down'] : ICONS['arrow_slim_up']}
                          </span>
                        )}
                      </Tooltip>
                    </button>
                  ) : (
                    <Tooltip color="dark" title={loading ? 'loading...' : ''}>
                      <span className="label">{column.label}</span>
                    </Tooltip>
                  )}
                </div>
              </th>
            ))}
            {groupedColumnsFiltered?.map(item => (
              <th
                key={item.id}
                colSpan={item.columns.filter(column => column.show).length}
                className="grouped-columns header">
                <span className="label">{item.label}</span>
              </th>
            ))}
            {showEmptyColumn && <th rowSpan={2} className="dynamic-empty-column"></th>}
          </tr>
          <tr>
            {groupedColumnsFiltered?.map(item =>
              item.columns
                .filter(column => column.show)
                .map((column, index) => (
                  <th key={column.id} className={`grouped-columns items-${index}`}>
                    {column.isSortable ? (
                      <button
                        className="icon-button column-title"
                        onClick={() => {
                          setSort({
                            column: column.fieldName,
                            groupBlockId: column.groupBlockId,
                            direction: currentSort.direction === 'desc' ? 'asc' : 'desc',
                          });
                        }}
                        disabled={loading}>
                        <Tooltip color="dark" title={loading ? 'loading...' : ''}>
                          <TooltipEllipsis title={column.label} className="label" />
                          {column.fieldName === currentSort.column && (
                            <span data-testid="sorting-arrow">
                              {currentSort.direction === 'desc' ? ICONS['arrow_slim_down'] : ICONS['arrow_slim_up']}
                            </span>
                          )}
                        </Tooltip>
                      </button>
                    ) : (
                      <Tooltip color="dark" title={loading ? 'loading...' : ''}>
                        <TooltipEllipsis title={column.label} className="label" />
                      </Tooltip>
                    )}
                  </th>
                )),
            )}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((datum: T, rowIndex: number) => {
            const rowIsExpanded = !!expandedRows[getItemId(datum)];
            const fixedData = tableConfig.getFixedSubData(datum);
            const rowSpan = fixedData.length === 0 ? 1 : fixedData.length;

            return (
              <Fragment key={`row-${rowIndex}`}>
                <tr className={clsx({ expanded: rowIsExpanded })}>
                  {columnsFiltered.map((column, index) => (
                    <td
                      key={`data-column-${column.id}`}
                      rowSpan={rowSpan}
                      onClick={() => tableConfig.onRowClicked && tableConfig.onRowClicked(datum)}
                      className={clsx({ 'is-number': column.type === 'number' })}>
                      {index === 0 ? (
                        <div>
                          <Tooltip color="dark" title={rowIsExpanded ? 'Collapse' : 'Expand'}>
                            <IconButton
                              icon={rowIsExpanded ? 'arrow_up' : 'arrow_right'}
                              onClick={e => {
                                e.stopPropagation();
                                handleExpandRow(datum);
                              }}
                            />
                          </Tooltip>
                          <span className="column-value">{column.accessor && column.accessor(datum)}</span>
                        </div>
                      ) : (
                        column.accessor && column.accessor(datum)
                      )}
                    </td>
                  ))}
                  {groupedColumnsFiltered?.map(item =>
                    item.columns
                      .filter(column => column.show)
                      .map((column, index) => {
                        return (
                          <td key={index} rowSpan={column.isGroupedRow ? 1 : rowSpan}>
                            <span className="label">
                              {column.isGroupedRow && column.groupedAccessor && fixedData[0]
                                ? column.groupedAccessor(fixedData[0])
                                : (column.componentAccessor && column.componentAccessor(datum)) ||
                                  (column.accessor && column.accessor(datum))}
                            </span>
                          </td>
                        );
                      }),
                  )}
                  {completeTableWithEmptyColumn()}
                </tr>
                {fixedData.map(
                  (item: ObjectConfig, itemIndex: number) =>
                    itemIndex > 0 && (
                      <tr key={`subdata-row-${itemIndex}`} className={clsx({ expanded: rowIsExpanded })}>
                        {groupedColumnsFiltered?.map(group =>
                          group.columns
                            .filter(column => column.show)
                            .map(column => {
                              return (
                                column.isGroupedRow &&
                                column.groupedAccessor && (
                                  <td key={`subdata-column-${column.id}`}>{column.groupedAccessor(item)}</td>
                                )
                              );
                            }),
                        )}
                      </tr>
                    ),
                )}
                {tableConfig.getSubData(datum).map((item: T, itemIndex: number) => (
                  <CSSTransition
                    timeout={200}
                    in={rowIsExpanded}
                    classNames="animation"
                    unmountOnExit
                    exit={false}
                    key={`subdata-row-${itemIndex}`}>
                    <tr key={`subdata-row-${itemIndex}`}>
                      {columnsFiltered.map(column => (
                        <td
                          key={`subdata-column-${column.id}`}
                          className={clsx({ 'is-number': column.type === 'number' })}>
                          {column.accessor && column.accessor(item)}
                        </td>
                      ))}
                      <EmptyColumns length={groupedColumnsLength} />
                      {completeTableWithEmptyColumn()}
                    </tr>
                  </CSSTransition>
                ))}
              </Fragment>
            );
          })}
        </tbody>
      </table>
      {paginationInfo && onPaginationChange && (
        <Pagination paginationInfo={paginationInfo} onChange={onPaginationChange} showTotal />
      )}
    </div>
  );
}

export default ReportTable;
