import React, { FC } from 'react';
import clsx from 'clsx';
import { useLocation } from 'wouter';
import { totalAmountCentsTooltip } from 'pages/Payouts';
import { PayoutsBalanceType } from 'api/data/payout/types';
import './style.scss';

type BalanceProps = {
  balance: PayoutsBalanceType | undefined;
  loading: boolean;
  availableToPayout: boolean;
};

const Balance: FC<BalanceProps> = ({ balance, loading, availableToPayout }) => {
  const [, setLocation] = useLocation();

  return (
    <>
      <p className="subtitle-small">Balance</p>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="table list balance">
          <tbody>
            <tr
              className={clsx({ 'available-to-payout': availableToPayout })}
              onClick={() => availableToPayout && setLocation('payouts/available-to-pay-out')}>
              <td className="subtitle-x-small">Available to payout</td>
              <td className="paragraph-x-small text-align-right">
                {totalAmountCentsTooltip(balance?.availableAmountCents || 0)}
              </td>
            </tr>
            <tr>
              <td className="subtitle-x-small">Available to payout soon</td>
              <td className="paragraph-x-small text-align-right">
                {totalAmountCentsTooltip(balance?.pendingAmountCents || 0)}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};

export default Balance;
