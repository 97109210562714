import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { downloadFile, isKYCd } from '~/utils';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import * as PageQuery from 'graphql/page.graphql';
import * as ReportsQuery from 'graphql/reports.graphql';
import { buildSubmissionsReportTableConfig } from './Columns/BuildSubmissionsReportTableConfig';
import { defaultPaginationParams } from 'defaults/pagination';
import Columns from './Columns';
import LoggedPageContainer from 'components/LoggedPageContainer';
import IconButton from '~/components/Button/IconButton';
import BlankState from 'components/BlankState';
import Modal from './Modal';
import ModalComponent from 'components/Modal';
import ReportTable from 'components/ReportTable';
import CheckboxFilter from './TableFilters/CheckboxFilter';
import SearchField from '~/components/SearchField';
import SubmissionDetailsTabs from '~/pages/SubmissionDetails/Tabs';
import View from 'components/ReportTable/Views/View';
import Views from 'components/ReportTable/Views';
import {
  PaginationType,
  ResponseReportRecord,
  SubmissionQuestion,
  SubmissionsReportDownload,
  SubmissionsReportQuestions,
  SubmissionsReportResponses,
} from '~/api/data/response/types';
import {
  DEFAULT_DROPDOWN_FILTERS,
  DEFAULT_VIEW,
  DropdownFiltersType,
  FiltersType,
  SaveViewReportType,
  TableConfigType,
  ViewReportType,
  VisibleColumnsType,
  SortType,
  ConfigurationType,
  ViewType,
} from './types';
import './style.scss';
import { OrganizationPagesData } from '~/api/data/pages/types';

const SubmissionsReport: FC = () => {
  const { currentOrg } = useCurrentOrganization();
  const [questions, setQuestions] = useState<SubmissionQuestion[]>();
  const [tableData, setTableData] = useState<ResponseReportRecord[]>();
  const [paginationInfo, setPaginationInfo] = useState<PaginationType>();
  const [submissionsRefetchLoading, setSubmissionsRefetchLoading] = useState(false);
  const [currentView, setCurrentView] = useState<ViewType | null>(null);
  const [dropdownFilters, setDropdownFilters] = useState<DropdownFiltersType | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<ResponseReportRecord>();

  const [searchTerm, setSearchTerm] = useState('');
  const currentQuery = (currentView || DEFAULT_VIEW).configuration.query;
  const currentFilter = { ...currentQuery.filters, search: searchTerm || undefined };

  const addFilterOption = (obj: { [id: string]: string }, id: string | undefined, label: string | undefined) => {
    obj[id || '__null__'] = label || '<empty>';
  };

  const initializeFilters = (data: OrganizationPagesData) => {
    const dropdown = DEFAULT_DROPDOWN_FILTERS();

    data?.organizationPagesQuery.forEach(page => {
      addFilterOption(dropdown.pageIds, page.id, page.title);
    });

    setDropdownFilters(dropdown);
  };

  useQuery<OrganizationPagesData>(PageQuery.OrganizationPagesQuery, {
    onCompleted: initializeFilters,
  });

  const { loading: questionsLoading } = useQuery<SubmissionsReportQuestions>(ReportsQuery.SubmissionsReportQuestions, {
    variables: {
      input: {
        query: {
          filters: currentFilter,
        },
      },
    },
    onCompleted: result => {
      setQuestions(result.submissionsReportQuestions);
    },
    skip: !currentView,
    fetchPolicy: 'network-only',
  });

  const { loading: responsesLoading, refetch: refetchSubmissionsResponse } = useQuery<SubmissionsReportResponses>(
    ReportsQuery.SubmissionsReportResponses,
    {
      variables: {
        input: {
          query: {
            filters: currentFilter,
            sort: currentQuery.sort,
            pagination: defaultPaginationParams,
          },
        },
      },
      onCompleted: result => {
        setTableData(result.paginatedSubmissionsReportResponses.data);
        setPaginationInfo(result.paginatedSubmissionsReportResponses.paginationInfo);
      },
      skip: !currentView,
      fetchPolicy: 'network-only',
    },
  );

  const { data: viewData, loading: loadingView } = useQuery<ViewReportType>(ReportsQuery.GetReportViews, {
    fetchPolicy: 'network-only',
  });
  const [downloadReportQuery, { loading: loadingDownload }] = useLazyQuery<SubmissionsReportDownload>(
    ReportsQuery.SubmissionsReportDownload,
    {
      onCompleted: data => {
        downloadFile('report.csv', data.submissionsReportDownload.content);
      },
      fetchPolicy: 'network-only',
    },
  );

  const [saveView, { loading: loadingSaveView }] = useMutation<SaveViewReportType>(ReportsQuery.SaveReportView, {
    refetchQueries: [{ query: ReportsQuery.GetReportViews }],
  });

  useEffect(() => {
    if (!currentView && viewData) {
      if (viewData.reportViews.length > 0) setCurrentView(viewData.reportViews[0]);
      else setCurrentView(DEFAULT_VIEW);
    }
  }, [currentView, setCurrentView, viewData]);

  if (loadingView || !currentView) return <span>Loading views...</span>;

  const viewConfig = currentView.configuration;

  const setVisibleColumns = (columns: VisibleColumnsType) => {
    void setCurrentViewQuery({
      ...currentView.configuration.query,
      columns: columns,
    });
  };

  const setFilters = (filters: FiltersType) => {
    void setCurrentViewQuery({
      ...currentView.configuration.query,
      filters: filters,
    });
  };

  const setSort = (sort: SortType) => {
    void setCurrentViewQuery({
      ...currentView.configuration.query,
      sort: sort,
    });
  };

  const setCurrentViewQuery = async (query: ConfigurationType['query']) => {
    const newCurrentView = {
      ...currentView,
      configuration: {
        ...currentView.configuration,
        query: query,
      },
    };
    setCurrentView(newCurrentView);

    await autoSaveView(newCurrentView);
  };

  const autoSaveView = async (view: ViewType) => {
    const input: { name: string; configuration: string; id?: string } = {
      name: view.name,
      configuration: JSON.stringify(view.configuration),
    };

    if (view.id !== DEFAULT_VIEW.id) input.id = view.id;

    return await saveView({ variables: { input } });
  };

  const tableConfig = buildSubmissionsReportTableConfig(viewConfig, questions);

  tableConfig.onRowClicked = data => {
    setSelectedRow(data as ResponseReportRecord);
  };

  const handleOnCreateView = async (viewName: string) => {
    await saveView({
      variables: {
        input: {
          name: viewName || 'Saved view',
          configuration: JSON.stringify(DEFAULT_VIEW.configuration),
        },
      },
    }).then(result => {
      if (result.data?.saveReportView) setCurrentView(result.data.saveReportView);
      setShowModal(false);
    });
  };

  // this is eventually becoming a state, since we'll be able to add/remove filters soon
  const dropdowns = [
    ['pageIds', 'Page'],
    // ['orderItemBlockTitles', 'Items'],
    // ['orderItemVariantTitles', 'Choice'],
  ];

  const downloadReport = async () => {
    let id: string | undefined = currentView?.id;
    if (id === DEFAULT_VIEW.id) {
      const result = await autoSaveView(currentView);
      id = result.data?.saveReportView.id;
    }
    void downloadReportQuery({ variables: { viewId: id } });
  };

  const onPaginationChange = async (offset: number, limit: number) => {
    setSubmissionsRefetchLoading(true);
    const { data } = await refetchSubmissionsResponse({
      input: {
        query: {
          filters: currentFilter,
          sort: currentQuery.sort,
          pagination: { offset, limit },
        },
      },
    });

    setTableData(data.paginatedSubmissionsReportResponses.data);
    setPaginationInfo(data.paginatedSubmissionsReportResponses.paginationInfo);
    setSubmissionsRefetchLoading(false);
  };

  const refetchCallback = async () => {
    const { data } = await refetchSubmissionsResponse();
    setTableData(data.paginatedSubmissionsReportResponses.data);
  };

  const blankState = searchTerm ? (
    <BlankState title="No results found" message="" />
  ) : (
    <BlankState title="Please select a page " message="Data will appear here." />
  );

  return (
    <LoggedPageContainer
      className="reports"
      organizationStatus={currentOrg?.status}
      additionalHeaderComponents={
        !loadingView && (
          <div className={clsx('header-container', { 'banner-space': !isKYCd(currentOrg?.status) })}>
            <div>
              <Views activeKey={currentView.id} icon="reports" addNewView={() => setShowModal(true)}>
                {viewData?.reportViews.length === 0 && (
                  <View
                    key={DEFAULT_VIEW.id}
                    label={DEFAULT_VIEW.name}
                    viewKey={DEFAULT_VIEW.id}
                    viewActive={currentView.id}
                  />
                )}
                {viewData?.reportViews.map(view => (
                  <View
                    key={view.id}
                    label={view.name}
                    viewKey={view.id}
                    onClick={() => setCurrentView(view)}
                    viewActive={currentView.id}
                  />
                ))}
              </Views>
              <Columns
                questions={questions || []}
                fixedColumns={tableConfig.ungroupedColumns}
                visibleColumns={viewConfig.query.columns}
                setVisibleColumns={setVisibleColumns}
              />
            </div>
            <div>
              <div className="filters">
                <SearchField setSearchTerm={setSearchTerm} />
                {dropdowns.map(([filterName, label]) => (
                  <CheckboxFilter
                    key={filterName}
                    filterName={filterName}
                    filterLabel={label}
                    dropdownOptions={(dropdownFilters || {})[filterName]}
                    filters={viewConfig.query.filters}
                    setFilters={setFilters}
                  />
                ))}
              </div>
              <div>
                <IconButton
                  icon={loadingDownload ? 'spinner' : 'download'}
                  className={loadingDownload ? 'loading' : ''}
                  disabled={loadingDownload}
                  onClick={() => void downloadReport()}
                />
              </div>
            </div>
            <Modal
              handleOnSaveView={async (viewName: string) => await handleOnCreateView(viewName)}
              loadingSaveView={loadingSaveView}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </div>
        )
      }>
      <ReportTable
        tableConfig={tableConfig}
        tableData={tableData}
        setSort={setSort}
        blankState={blankState}
        currentSort={viewConfig.query.sort}
        loading={responsesLoading || questionsLoading || submissionsRefetchLoading}
        getItemId={(data: TableConfigType) => data.id || ''}
        paginationInfo={paginationInfo}
        onPaginationChange={onPaginationChange}
      />
      <ModalComponent
        className="submission-details-modal"
        handleOnCancel={() => setSelectedRow(undefined)}
        handleOnConfirm={() => setSelectedRow(undefined)}
        confirmlabel="Ok"
        visible={!!selectedRow}
        header="Details"
        fullScreenMobile>
        {selectedRow && (
          <SubmissionDetailsTabs
            responseId={selectedRow.id}
            refetchSubmissionsResponse={() => void refetchCallback()}
          />
        )}
      </ModalComponent>
    </LoggedPageContainer>
  );
};

export default SubmissionsReport;
