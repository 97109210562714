import { useLazyQuery, useMutation, ApolloError } from '@apollo/client';
import { useLocation } from 'wouter';
import * as PageQuery from 'graphql/page.graphql';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import { Session } from 'api/data/user/types';
import { CreatePageFromTemplate } from 'components/Templates/types';
import { PageDataDraftBlock, PagesData, SavePage } from '~/api/data/pages/types';

export default function useNewPage(
  userData?: Session,
): [
  (templateId?: string, redirect?: boolean) => Promise<PageDataDraftBlock | undefined | null>,
  boolean,
  ApolloError | undefined,
] {
  const [getPagesQuery] = useLazyQuery<PagesData>(PageQuery.PagesQuery);
  const [savePage, { loading: savePageLoading, error: savePageError }] = useMutation<SavePage>(PageQuery.SavePage);
  const [CreatePageFromTemplate, { loading: createPageLoading, error: createPageError }] =
    useMutation<CreatePageFromTemplate>(PageQuery.CreatePageFromTemplate);
  const [, setLocation] = useLocation();

  const redirectToEditor = (page?: PageDataDraftBlock) => {
    if (page?.slug) {
      void getPagesQuery();
      setLocation(`/${currentSpaceSlug() || ''}/pages/${page?.slug}/edit`);
    }
  };
  const createPage = async (templateId?: string, redirect = true) => {
    if (userData?.currentUser?.id) {
      if (templateId) {
        try {
          const { data } = await CreatePageFromTemplate({ variables: { templateId: templateId } });
          if (redirect) redirectToEditor(data?.createPageFromTemplate);
          return data?.createPageFromTemplate;
        } catch {
          return null;
        }
      } else {
        try {
          const { data } = await savePage({ variables: { page: { title: 'Untitled', blocks: [] } } });
          if (redirect) redirectToEditor(data?.savePage);
          return data?.savePage;
        } catch {
          return null;
        }
      }
    }
  };

  return [createPage, createPageLoading || savePageLoading, savePageError || createPageError];
}
