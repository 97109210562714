import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useRoute, Link, useLocation } from 'wouter';
import { useQuery, useMutation } from '@apollo/client';
import * as PayoutsQuery from 'graphql/payout.graphql';
import { currencyFormatter } from 'utils';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import ICONS from 'components/Icons';
import LoggedPageContainer from 'components/LoggedPageContainer';
import { PayoutButton } from '..';
import RecipientDropDown from '~/pages/Payouts/AvailableToPayOut/RecipientDropDown';
import Summary from '../Details/Summary';
import Tag from 'components/Tag';
import Text from 'components/Input';
import { SelectedItems } from 'components/Table';
import { PayoutsBalanceByOrganization, PayoutDetails, SpaceSummary } from 'api/data/payout/types';
import './style.scss';

interface PayoutForm {
  recipient: string;
  name: string;
  description: string;
  amountCents?: number;
}

const getTotalAmount = (spaceSummary: SpaceSummary[], selectedItems: SelectedItems) => {
  return Object.values(spaceSummary).reduce((total, summary) => {
    return (
      total +
      summary.pages.reduce((sum, page) => {
        return selectedItems[page.id] ? sum + page.amount_cents : sum;
      }, 0)
    );
  }, 0);
};

const AvailableToPayOut = () => {
  const isFirstRender = useRef(true);
  const [, params] = useRoute('/:currentSpace/payouts/available-to-pay-out');
  const [, setLocation] = useLocation();
  const { currentOrg } = useCurrentOrganization();

  const [payout, setPayout] = useState<PayoutForm>({
    recipient: '',
    name: currentOrg?.name || '',
    description: '',
  });

  const { data: pendingTx } = useQuery<{ pendingPayoutTransactions: PayoutDetails }>(
    PayoutsQuery.PendingPayoutTransactions,
    {
      fetchPolicy: 'network-only',
    },
  );

  const spaces = useMemo(
    () => pendingTx?.pendingPayoutTransactions.spaces || [],
    [pendingTx?.pendingPayoutTransactions.spaces],
  );
  const [selectedItems, setSelectedItems] = useState<SelectedItems>({});

  const selectAll = useCallback(() => {
    const newSelection: SelectedItems = {};
    Object.values(spaces).forEach(space => {
      newSelection[space.name] = true;

      return space.pages.forEach(({ id }) => {
        newSelection[id] = true;
      });
    });
    setSelectedItems(newSelection);
  }, [spaces, setSelectedItems]);

  useEffect(() => {
    if (isFirstRender.current && spaces.length > 0) {
      isFirstRender.current = false;
      selectAll();
    }
  }, [spaces, selectAll, isFirstRender]);

  const { loading: loadingBalance, data: balanceData } = useQuery<PayoutsBalanceByOrganization>(
    PayoutsQuery.PayoutsBalanceByOrganization,
    {
      variables: {
        organizationId: currentOrg?.id,
      },
      skip: !currentOrg?.id,
    },
  );

  const [initiatePayout, { loading }] = useMutation<{ initiatePayout: PayoutDetails }>(PayoutsQuery.InitiatePayout);

  const handleOnChange = useCallback((field: keyof PayoutForm, value: string | number) => {
    setPayout(prev => ({
      ...prev,
      [field]: value,
    }));
  }, []);

  const balance = balanceData?.payoutsBalanceByOrganization;
  const totalAmountCents = getTotalAmount(spaces, selectedItems);

  const accountRestricted = currentOrg?.status === 'RESTRICTED' && currentOrg.provider === 'stripe';
  const hasAvailableAmountCents = !!balance?.availableAmountCents && balance?.availableAmountCents > 0;
  const availableToPayOut =
    !accountRestricted &&
    !!hasAvailableAmountCents &&
    !!payout.recipient &&
    !!payout.name &&
    !!payout.description &&
    totalAmountCents > 0 &&
    !loading;

  const handlePayout = () => {
    void initiatePayout({
      variables: {
        payout: {
          ...payout,
          notAfter: pendingTx?.pendingPayoutTransactions.notAfter,
          amountCents: totalAmountCents,
          pageIds: Object.entries(selectedItems)
            .filter(([id, value]) => {
              return spaces.some(space => space.pages.some(page => page.id === id) && value);
            })
            .map(([key]) => key),
        },
      },
    }).then(({ data }) => {
      setLocation(`/${params?.currentSpace}/payouts/${data?.initiatePayout.slug}?initiated=true`);
    });
  };

  return (
    <LoggedPageContainer
      className="payouts available-pay-out"
      organizationStatus={currentOrg?.status}
      headerChildren={
        <div className="row align-center second-header">
          <Link className="row align-center subtitle-x-small" to={`/${params?.currentSpace}/payouts`}>
            {ICONS['wallet']} Payouts
          </Link>
          <span className="row align-center subtitle-x-small arrow-right">
            {ICONS['arrow_right']} Available to payout
          </span>
        </div>
      }>
      <div className="max-width-desktop">
        <div className="row space-between align-center payout-header">
          <div>
            <p className="subtitle-xx-small">Available to payout</p>
            {loadingBalance ? (
              <>Loading...</>
            ) : (
              <span className="row align-center heading-small">
                {currencyFormatter(balance?.availableAmountCents)}
                {currentOrg?.payoutSchedule && currentOrg?.payoutSchedule !== 'MANUAL' && (
                  <Tag
                    label={`Scheduled ${currentOrg?.payoutSchedule.toLocaleLowerCase()}`}
                    type="neutral"
                    capitalize
                  />
                )}
              </span>
            )}
          </div>
          {currentOrg?.provider !== 'stripe' && (
            <PayoutButton
              accountRestricted={accountRestricted}
              hasAvailableAmountCents={hasAvailableAmountCents}
              availableToPayOut={availableToPayOut}
              handleOnClick={handlePayout}
              label={loading ? 'Loading...' : `Pay out ${currencyFormatter(totalAmountCents)}`}
            />
          )}
        </div>
        {currentOrg?.provider !== 'stripe' && (
          <div className="row space-between payout-detail">
            <div>
              <RecipientDropDown
                selectedRecipientEmail={payout.recipient}
                onChange={id => handleOnChange('recipient', id)}
              />
            </div>
            <div>
              <Text
                name="business-name"
                label="To the order of"
                value={payout.name}
                onChange={({ target }) => handleOnChange('name', target.value)}
                rules={{ required: true }}
              />
            </div>
            <div>
              <Text
                name="memo"
                label="Memo"
                value={payout.description}
                onChange={({ target }) => handleOnChange('description', target.value)}
                rules={{ required: true }}
              />
            </div>
          </div>
        )}
        <div className="summary">
          {spaces.length > 0 && (
            <Summary
              data={spaces}
              enableRowSelection={currentOrg?.provider !== 'stripe'}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
              selectedTotalAmountCents={totalAmountCents}
            />
          )}
        </div>
      </div>
    </LoggedPageContainer>
  );
};

export default AvailableToPayOut;
