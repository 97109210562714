import React, { FC, useState, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { showPlural } from 'utils';
import * as PageQuery from 'graphql/page.graphql';
import Alert from '~/components/Alert';
import IconButton from 'components/Button/IconButton';
import ICONS from 'components/Icons';
import Input from 'components/Input';
import Modal from 'components/Modal';
import Table, { SelectedItems } from 'components/Table';
import { Passcode, PasscodeList as PasscodeListType } from 'api/data/pages/types';

interface PasscodeListProps {
  pageId: string | undefined;
  data: PasscodeListType[] | undefined;
  refetchLists: () => void;
  savePasscodeCallback: (message: string) => void;
  blockId: string;
  handleAdd: (passcodeListId: string, passcodes: Passcode[]) => void;
  setHasValidationList: (hasValidationList: boolean) => void;
}

type ModalType = 'reset' | 'remove' | 'removeList' | null;

const PasscodeList: FC<PasscodeListProps> = ({
  pageId,
  data,
  refetchLists,
  savePasscodeCallback,
  blockId,
  handleAdd,
  setHasValidationList,
}) => {
  const [savePassCodeListMutation, { loading }] = useMutation(PageQuery.SavePasscodeList);
  const [deletePasscodeListsFromQuestionBlock, { loading: loadingDelete }] = useMutation(
    PageQuery.DeletePasscodeListsFromQuestionBlock,
    {
      refetchQueries: [
        {
          query: PageQuery.GetPasscodeLists,
          variables: { questionBlockId: blockId, pageId: pageId },
        },
      ],
    },
  );
  const [selectedItems, setSelectedItems] = useState<SelectedItems>({});
  const [isRemoving, setIsRemoving] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [searchPasscode, setSearchPasscode] = useState('');
  const [modalType, setModalType] = useState<ModalType>(null);
  const arrSelectedItems = Object.keys(selectedItems);
  const selectedCount = arrSelectedItems.length;

  const tableColumns = [
    {
      header: 'Valid answers',
      accessor: (item: Passcode) => item.passcode,
      showSortIcon: true,
    },
    {
      header: 'Used',
      accessor: (item: Passcode) => `${item.uses}/${item.maxUses}`,
      align: 'right' as const,
      showSortIcon: true,
    },
  ];

  const passcodeLists = data || [];

  const tableData = passcodeLists.flatMap(
    passcodeList =>
      passcodeList.passcodes?.map(({ id, passcode, uses, maxUses }) => ({
        id,
        passcode,
        uses,
        maxUses,
      })) || [],
  );

  const filteredTableData = useMemo(() => {
    if (!searchPasscode) {
      return tableData;
    }
    const searchTerm = searchPasscode.toLowerCase().trim();
    return tableData.filter(item => item.passcode.toLowerCase().includes(searchTerm));
  }, [tableData, searchPasscode]);

  const handleSelectionChange = (newSelection: SelectedItems) => {
    setSelectedItems(newSelection);
  };

  // for now, we only support one passcode list in the UI
  const firstPasscodeList = passcodeLists[0];

  const otherAssociatedBlocks = firstPasscodeList?.questionBlocks?.filter(block => block.blockId !== blockId) || [];

  const savePasscodeList = async (
    passcodes: Passcode[],
    action: string,
    setLoadingState: (loading: boolean) => void,
  ) => {
    setLoadingState(true);

    try {
      await savePassCodeListMutation({
        variables: {
          passcodeList: {
            id: firstPasscodeList?.id,
            passcodes,
            questionBlockId: blockId,
          },
          pageId: pageId,
        },
      });

      const selectedCount = Object.keys(selectedItems).length;
      savePasscodeCallback(`${selectedCount} valid answer${showPlural(selectedCount)} ${action}!`);
      void refetchLists();
      setSelectedItems({});
    } finally {
      setLoadingState(false);
    }
  };

  const closeModal = () => {
    setModalType(null);
  };

  const handleOnReset = async () => {
    const selectedPasscodes = tableData.map(item => ({
      ...item,
      uses: selectedItems[item.id] ? 0 : item.uses,
    }));

    await savePasscodeList(selectedPasscodes, 'reset', setIsResetting);
    closeModal();
  };

  const handleOnRemove = async () => {
    const filteredPasscodes = tableData.filter(item => !selectedItems[item.id]);
    await savePasscodeList(filteredPasscodes, 'removed', setIsRemoving);
    setHasValidationList(filteredPasscodes.length > 0);
    closeModal();
  };

  const removeList = async () => {
    await deletePasscodeListsFromQuestionBlock({ variables: { questionBlockId: blockId, pageId: pageId } });
    setHasValidationList(false);
    closeModal();
  };

  const modalConfig = {
    reset: {
      handleOnConfirm: () => void handleOnReset(),
      confirmLabel: isResetting ? 'Resetting...' : 'Reset',
      header: 'Reset',
      headerIcon: 'reset',
      message: `Resetting the selected answers means they will appear as unused and can therefore be used again.
      
      Are you sure you want to reset ${selectedCount} answer${showPlural(selectedCount)}?`,
    },
    remove: {
      handleOnConfirm: () => void handleOnRemove(),
      confirmLabel: isRemoving ? 'Removing...' : 'Remove',
      header: 'Remove',
      headerIcon: 'trash_can',
      message:
        selectedCount === tableData.length
          ? 'Are you sure you want to remove answer validation from this question?'
          : `Are you sure you want to remove ${selectedCount} answer${showPlural(selectedCount)}?`,
    },
    removeList: {
      handleOnConfirm: () => void removeList(),
      confirmLabel: 'Remove List',
      header: 'Remove',
      headerIcon: 'trash_can',
      denyButtonProps: { disabled: loadingDelete },
      message: 'Are you sure you want to unlink this answer validation list from this question?',
    },
  };

  const currentConfig = modalType ? modalConfig[modalType] : null;

  return (
    <>
      {modalType && (
        <Modal
          handleOnCancel={closeModal}
          handleOnClose={closeModal}
          handleOnConfirm={currentConfig?.handleOnConfirm}
          confirmlabel={currentConfig?.confirmLabel}
          denyLabel="Close"
          visible={!!modalType}
          header={currentConfig?.header}
          className="reset-remove-modal"
          headerIcon={currentConfig?.headerIcon}>
          <p>{currentConfig?.message}</p>
        </Modal>
      )}
      {arrSelectedItems.length > 0 ? (
        <div className="row space-between align-center answer-action-container">
          <span className="subtitle-xx-small highlight">{arrSelectedItems.length} Selected</span>
          <div className="row align-center">
            <IconButton
              icon="trash_can"
              onClick={() => setModalType('remove')}
              disabled={arrSelectedItems.length === 0 || loading}>
              Remove
            </IconButton>
            <span className="vertical-line"></span>
            <IconButton
              icon="reset"
              onClick={() => setModalType('reset')}
              disabled={arrSelectedItems.length === 0 || loading}>
              Reset
            </IconButton>
          </div>
        </div>
      ) : (
        <>
          <div className="row space-between search-add-container">
            <div className="search-filter">
              {ICONS['search']}
              <Input
                type="text"
                aria-label="search"
                name="search"
                placeholder="Search"
                value={searchPasscode}
                onChange={({ target }) => {
                  void setSearchPasscode(target.value);
                }}
              />
            </div>
            <button
              className="button-size-ml font-size-xxxs"
              onClick={() => handleAdd(firstPasscodeList?.id, tableData)}>
              Add
            </button>
          </div>
          {otherAssociatedBlocks.length > 0 && (
            <Alert
              type="warning"
              content={
                <div className="row direction-row subtitle-xx-small shared-list-message">
                  <div className="icon">{ICONS['warning']}</div>
                  <div>
                    <p>This list is also used in other blocks:</p>
                    <ul>
                      {otherAssociatedBlocks.map(({ blockId, pageTitle, questionPrompt }) => (
                        <li key={blockId}>
                          {pageTitle} - {questionPrompt}
                        </li>
                      ))}
                    </ul>
                    <p>Any changes made here will also affect validation in those blocks.</p>
                  </div>
                </div>
              }
            />
          )}
          <div className="row justify-end">
            <button className="button-link xxx-small" onClick={() => setModalType('removeList')}>
              Remove List
            </button>
          </div>
        </>
      )}
      <Table
        data={filteredTableData}
        columns={tableColumns}
        getRowId={item => item.id}
        enableRowSelection
        selectedItems={selectedItems}
        onSelectionChange={handleSelectionChange}
      />
    </>
  );
};

export default PasscodeList;
