import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import QRCodeStyling from 'qr-code-styling';
import { useMutation, useQuery } from '@apollo/client';
import * as OrganizationQuery from 'graphql/organization.graphql';
import * as TransactionQuery from 'graphql/transaction.graphql';
import * as ResponseQuery from 'graphql/response.graphql';
import qrCodeDefault from 'defaults/qrCode';
import { participantAsSubmission } from 'utils';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useSessionPreferences from 'hooks/useSessionPreferences';
import { Action, checkUserRole } from 'components/ProtectedComponent';
import Alert, { AlertType } from 'components/Alert';
import IconButton from 'components/Button/IconButton';
import ICONS from 'components/Icons';
import Modal from 'components/Modal';
import ResponseActivityTypeModal from 'components/ResponseActivityTypeModal';
import SafeHtmlRenderer from 'components/SafeHtmlRenderer';
import SubmissionDetails from 'pages/PageResponses/ResponseDetail/SubmissionDetails';
import SuccessCard, { SubmissionType } from './SuccessCard';
import TransactionSummary from 'components/Footer/TransactionSummary';
import { Page, SubmissionDetail } from 'api/data/response/types';
import { Transaction } from 'api/data/transaction/types';
import { PaymentMethods, Response, ResponseActivityType } from 'api/data/response/types';
import { GetOrganization } from 'api/data/user/types';
import './style.scss';

interface DetailsProps {
  page?: Page;
  paramId: string;
  response?: Response;
  items?: SubmissionDetail[];
  submissionType?: SubmissionType;
  participantSlug?: string;
  autoTrackActivities?: boolean;
  refetchSubmissionsResponse?: () => void;
  refetchPage?: () => void;
  offlineTransaction?: Transaction;
}

const qrCode = new QRCodeStyling({
  ...qrCodeDefault,
  width: 92,
  height: 92,
  backgroundOptions: { color: '#FAFAFA' },
  type: 'svg',
  imageOptions: { ...qrCodeDefault.imageOptions, margin: 2 },
});

type TrackingMessageProps = {
  type: AlertType;
  icon: string;
  content?: string;
  activityTypeId?: string;
};

const useValidateUserAccess = (action: Action, spaceSlug?: string) => {
  const { data } = useQuery<GetOrganization>(OrganizationQuery.GetOrganization, {
    variables: { currentSpace: spaceSlug },
    skip: !spaceSlug,
  });

  const currentSpace = data?.organization?.spaces.find(space => space.slug === spaceSlug);

  return { hasAccess: checkUserRole(action, data?.organization?.currentUserRole, currentSpace?.currentUserRole) };
};

const Details: FC<DetailsProps> = ({
  page,
  paramId,
  response,
  items = [],
  submissionType,
  participantSlug,
  autoTrackActivities,
  refetchSubmissionsResponse,
  refetchPage,
  offlineTransaction,
}) => {
  const { currentOrg } = useCurrentOrganization();
  const { hasAccess } = useValidateUserAccess('AUTO_CHECKIN', page?.space?.slug);
  const firstRender = useRef(true);
  const { acceptInPersonPayments } = useSessionPreferences();

  const [registerActivity] = useMutation(ResponseQuery.RegisterResponseActivity, {
    refetchQueries: [
      {
        query: ResponseQuery.GetResponseForReceipt,
        variables: { id: paramId },
      },
    ],
  });

  const [processOfflinePayment] = useMutation(TransactionQuery.ProcessOfflinePayment);

  const newActivityType = { name: '', public: false };
  const [editedActivityType, setEditedActivityType] = useState<Partial<ResponseActivityType>>();

  const [showModal, setShowModal] = useState(false);
  const [currentActivity, setCurrentActivity] = useState<Partial<ResponseActivityType>>();
  const [showTrackingMessage, setShowTrackingMessage] = useState(false);
  const [trackingMessages, setTrackingMessages] = useState<TrackingMessageProps[]>([]);
  const [showPaymentSucceeded, setShowPaymentSucceeded] = useState(false);

  const confirmRegisterActivity = useCallback(
    async (responseActivityId: string) => {
      await registerActivity({
        variables: {
          responseId: paramId,
          responseActivityTypeId: responseActivityId,
        },
      });

      refetchSubmissionsResponse?.();

      setCurrentActivity(undefined);
      setShowModal(false);
    },
    [paramId, registerActivity, refetchSubmissionsResponse, setCurrentActivity, setShowModal],
  );

  const markAsPaid = useCallback(
    async (offlineTransaction: Transaction) => {
      await processOfflinePayment({
        variables: {
          transactionId: offlineTransaction.id,
          currentSpace: page?.space?.slug,
        },
      });

      setShowPaymentSucceeded(true);
    },
    [processOfflinePayment, page],
  );

  const handleResponseActivityClick = useCallback(
    (responseActivityType: Partial<ResponseActivityType>) => {
      setCurrentActivity(responseActivityType);

      if (response?.paymentStatus !== 'PAID') {
        setShowModal(true);
      } else {
        void confirmRegisterActivity(responseActivityType.id || '');
        setShowTrackingMessage(true);
        setTrackingMessages(prevMessages => [
          ...prevMessages,
          {
            content: `${responseActivityType.name} succeeded!`,
            type: 'success',
            icon: 'check_circle',
            activityTypeId: responseActivityType.id,
          },
        ]);
      }
    },
    [response, confirmRegisterActivity, setCurrentActivity, setShowModal],
  );

  useEffect(() => {
    if (firstRender.current && hasAccess && autoTrackActivities && page?.responseActivityTypes) {
      firstRender.current = false;

      const autoTrackingActivities = page?.responseActivityTypes?.filter(activityType => activityType.autoTracking);

      autoTrackingActivities?.forEach(activityType => {
        const activity = response?.responseActivities?.find(
          activity => activity.responseActivityType.id === activityType.id,
        );

        if (activity) {
          setShowTrackingMessage(true);
          setTrackingMessages(prevMessages => [
            ...prevMessages,
            {
              content: `${activityType.name} already occurred at ${dayjs(activity.insertedAt).format('M/D/YY h:mma')}`,
              type: 'danger',
              icon: 'warning',
              activityTypeId: activityType.id,
            },
          ]);
        } else {
          void handleResponseActivityClick(activityType);
        }
      });
    }
  }, [autoTrackActivities, page, response, handleResponseActivityClick, setTrackingMessages, hasAccess]);

  const activityComponents = page?.responseActivityTypes?.reduce(
    (
      accumulator: {
        activityTypes: React.ReactNode[];
        activities: React.ReactNode[];
      },
      currentValue: ResponseActivityType,
    ) => {
      const activity = response?.responseActivities?.find(
        activity => activity.responseActivityType.id === currentValue.id,
      );
      if (activity) {
        accumulator.activities.push(
          <div className="action-performed" key={currentValue.id}>
            <div className="title">
              <div>
                {ICONS['clock']}
                <span>{currentValue.name}</span>
              </div>

              {currentOrg && (
                <IconButton
                  className="activity-edit-button"
                  icon="edit"
                  onClick={() => setEditedActivityType(currentValue)}></IconButton>
              )}
            </div>
            <span>{dayjs(activity.insertedAt).format('M/D/YY h:mma')}</span>
          </div>,
        );
      } else {
        accumulator.activityTypes.push(
          <div className="response-activity-type-item" key={currentValue.id}>
            <button
              className={clsx('activity-main-button', { 'with-options': !!currentOrg })}
              key={currentValue.id}
              onClick={() => void handleResponseActivityClick(currentValue)}>
              {currentValue.name}
            </button>
            {currentOrg && (
              <IconButton
                className="activity-edit-button"
                icon="menu_open"
                onClick={() => setEditedActivityType(currentValue)}></IconButton>
            )}
          </div>,
        );
      }

      return accumulator;
    },
    { activityTypes: [], activities: [] },
  );

  const activityTypes = activityComponents?.activityTypes || [];
  const activities = activityComponents?.activities || [];

  const showActions = !!currentOrg || (page?.responseActivityTypes && page?.responseActivityTypes?.length > 0);

  const showMarkAsPaidButton = hasAccess && offlineTransaction && offlineTransaction.succeededAt == null;

  const qrCodeRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (response?.id && node !== null) {
        qrCode.update({ data: `${window.location.hostname}/submissions/${response.id}?source=qrcode` });
        qrCode.append(node);
      }
    },
    [response?.id],
  );

  const participantAnswer: SubmissionDetail[] = response?.participantMembership
    ? [participantAsSubmission(response?.participantMembership)]
    : [];

  const allItems = [...participantAnswer, ...items];

  const order = response?.order;
  const paymentMethodType = order?.paymentMethodType?.toLowerCase() as PaymentMethods;
  const pricing = order?.metadata?.pricing && order?.metadata?.pricing[paymentMethodType];

  return (
    <div className="submission-details-container">
      {showTrackingMessage &&
        trackingMessages.map(({ type, content, icon }, index) => (
          <Alert key={index} type={type} icon={icon} content={content || ''} />
        ))}
      {showPaymentSucceeded && <Alert type="success" icon="check_circle" content="Payment marked as paid" />}
      {submissionType && page && (
        <SuccessCard
          response={response}
          submissionType={submissionType}
          participantSlug={participantSlug}
          page={page}
        />
      )}
      <div>
        {!currentOrg && !acceptInPersonPayments && (
          <>
            <div className="payer-header">
              <div>
                <p className="payer-name">{response?.user?.fullName}</p>
                <SafeHtmlRenderer className="receipt-message" content={page?.customBodyReceiptContent} />
              </div>
              <div className="qr-code">
                <div ref={qrCodeRef} />
                <p>Scan me</p>
              </div>
            </div>
            <div className="horizontal-line" />
          </>
        )}
        {showActions && (
          <>
            <h2 className="row space-between">
              {currentOrg && 'Actions'}
              {currentOrg && (
                <button className="button-link" onClick={() => setEditedActivityType(newActivityType)}>
                  Add
                </button>
              )}
            </h2>
            {activityTypes.length > 0 && (
              <div className="action-btn-container">{activityTypes.map(activityType => activityType)}</div>
            )}
            {activities.length > 0 && <div className="action-container">{activities.map(activity => activity)}</div>}
          </>
        )}
        {showMarkAsPaidButton && (
          <div className="action-btn-container">
            <button onClick={() => void markAsPaid(offlineTransaction)}>Mark as Paid</button>
          </div>
        )}
        {offlineTransaction && offlineTransaction.succeededAt && (
          <div className="action-container">
            <div className="action-performed">
              <div className="title">
                <div>
                  {ICONS['clock']}
                  <span>Payment confirmed</span>
                </div>
              </div>
              <span>{dayjs(offlineTransaction?.succeededAt).format('M/D/YY h:mma')}</span>
            </div>
          </div>
        )}
        {currentOrg && !!response?.user && (
          <>
            <h2 className="row">
              Details
              <a
                aria-label="details-link"
                href={`/submissions/${response?.id}?type=payment`}
                target="_blank"
                rel="noreferrer"
                className="row align-center">
                <span className="row">{ICONS['expand_link']}</span>
              </a>
            </h2>
            <a
              href={`/${response?.page?.space?.slug}/member/${response?.user?.id}`}
              className="row align-center profile-container"
              target="_blank"
              rel="noreferrer">
              <div className="row align-center justify-center profile-icon">{ICONS['profile_outline']}</div>
              <div className="row direction-column flex-1 user-info">
                <span className="subtitle-xx-small payer-name">{response?.user?.fullName}</span>
                <span className="paragraph-xxx-small payer-email">{response?.user?.email}</span>
              </div>
              <div className="profile-arrow">{ICONS['arrow_right_complete']}</div>
            </a>
          </>
        )}
        {allItems && allItems?.length > 0 && (
          <>
            <div className="horizontal-line" />
            <SubmissionDetails respondentFullName={response?.fullName || ''} items={allItems || []} />
          </>
        )}
        <TransactionSummary
          subtotalAmountCents={order?.subtotalCents}
          tipAmountCents={order?.tip.amountCents}
          totalAmountCents={order?.totalCents}
          isPayerView
          processingFee={pricing?.processingFeeCents}
          showPaymentMethod={false}
        />
      </div>
      {!!editedActivityType && (
        <ResponseActivityTypeModal
          pageId={response?.pageId || ''}
          activityType={editedActivityType}
          visible={!!editedActivityType}
          onClose={() => {
            setEditedActivityType(undefined);
            refetchPage?.();
          }}
          spaceSlug={page?.space?.slug}
        />
      )}
      <Modal
        header={currentActivity?.name}
        headerIcon="check_circle"
        handleOnCancel={() => setShowModal(false)}
        handleOnConfirm={() => void confirmRegisterActivity(currentActivity?.id || '')}
        confirmlabel="Continue"
        denyLabel="Cancel"
        visible={showModal}
        className="confirmation-modal">
        <span>This submission has a {response?.paymentStatus?.toLocaleLowerCase()} payment.</span>
        <span>Are you sure you want to proceed?</span>
      </Modal>
    </div>
  );
};

export default Details;
