import React, { FC, useState } from 'react';
import { findAnswer } from 'api/data/response';
import ValidationWrapper from './ValidationAnswerWrapper';
import { Answer } from 'api/data/response/types';
import { QuestionBlock } from './types';

export interface ShortAnswerProps {
  data: QuestionBlock;
  placeholder: string;
  readOnly?: boolean;
  groupedAnswer?: Answer;
  handleAnswerChange?: (matchedPasscodeIds: string[], value: string) => void;
  usedPasscodes?: string[];
  isEditing?: boolean;
  handleOpenAnswerValidation?: () => void;
}

const ShortAnswer: FC<ShortAnswerProps> = ({
  data,
  readOnly,
  placeholder,
  groupedAnswer,
  handleAnswerChange,
  ...props
}: ShortAnswerProps) => {
  const answer = groupedAnswer ? groupedAnswer : findAnswer(data.id, 'SHORT_ANSWER');
  const [typedAnswer, setTypedAnswer] = useState('');

  const answerChanged = (value: string) => {
    if (data.hasValidationList) {
      setTypedAnswer(value);
    } else {
      handleAnswerChange?.([], value);
    }
  };

  return (
    <ValidationWrapper data={data} handleAnswerChange={handleAnswerChange} typedAnswer={typedAnswer} {...props}>
      <input
        className="input-answer"
        type="text"
        disabled={readOnly}
        placeholder={placeholder}
        maxLength={1000}
        aria-label="short answer"
        value={typedAnswer || answer.value}
        onChange={({ target }) => answerChanged(target.value.trimStart())}
      />
    </ValidationWrapper>
  );
};

export default ShortAnswer;
